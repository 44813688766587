import * as Survey from "survey-react";
import React, { ChangeEvent } from "react";

const radioGroup = {
  name: "radioGroup",
  isFit: (question: Survey.QuestionRadiogroupModel) => question.getType() === "radiogroup",
  render: function renderRadioGroup(question: Survey.QuestionRadiogroupModel) {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      question.value = e.target.value;
    };
    return (
      <div>
        {question.choices.map((data: Survey.ItemValue, index) => {
          let checked = false;
          if (data.value === question.value) {
            checked = true;
          }
          const id = `${question.title} ${index}`;
          return (
            <div key={index}>
              <input
                type="radio"
                name={question.title}
                id={id}
                value={data.value as string}
                onChange={onInputChange}
                checked={checked}
                disabled={question.readOnly}
              />
              <label
                htmlFor={id}
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
            </div>
          );
        })}
      </div>
    );
  },
};

export default radioGroup;
