import "./index.scss";
import React from "react";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import TeamLink from "../../../../../common/components/team-link";
import PageTitle from "../../../../../common/components/page-title";
import { useDeveloperToolDetailsPageTitleControllerProvider } from "./controller";
import { DownloadDeveloperToolModal } from "../download-developer-tool-modal";
import ImpactXButton from "../../../../../common/components/impactx-button";

const DeveloperToolDetailsPageTitle = createFunctionalComponentFromView(
  useDeveloperToolDetailsPageTitleControllerProvider,
  (controller) => {
    const renderTeamLink = () => (
      <TeamLink
        id={controller.developerTool.team.id}
        name={controller.developerTool.team.name}
      />
    );

    const renderDeveloperToolName = () => (
      <div className="DeveloperToolName">
        <div className="name">
          <h3>{controller.developerTool.name}</h3>
        </div>
      </div>
    );

    function renderDownloadButton() {
      return (
        <div className="download-source-code">
          <ImpactXButton
            className="download-source-code-button"
            ix-styles="m"
            onClickHandler={controller.onClickDownload}
          >
            <i className="ri-download-2-line" />
            &nbsp; Download Source Code
          </ImpactXButton>
        </div>
      );
    }

    return (
      <div className="DeveloperToolDetailsPageTitle">
        <DownloadDeveloperToolModal developerTool={controller.developerTool} />
        <div className="left-content">
          <PageTitle
            title={controller.getTeamTypeLabelMap()}
            subText={renderTeamLink()}
            displayTitleLighter={true}
            subtitle={renderDeveloperToolName()}
          />
        </div>
        <div className="right-content">
          {renderDownloadButton()}
        </div>
      </div>
    );
  },
);

export default DeveloperToolDetailsPageTitle;
