import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import TeamLink from "../../../../common/components/team-link";
import { useApplicationPageTitleControllerProvider } from "./controller";
import "./index.scss";
import React from "react";
import PageTitle from "../../../../common/components/page-title";
import ApplicationName from "../application-name";
import ApplicationStatus from "../application-status";
import AuthZMicroFrontend from "../../../../common/components/authz-mff";
import { teamTypeLabelMap } from "../../../../common/data/team-types";
import ImpactXButton from "../../../../common/components/impactx-button";
import JoinTeamModal from "../../../../common/components/join-team-modal";
import { ExitTeamButton } from "../../../../common/components/exit-team-button-with-modal";

const ApplicationDetailsPageTitle = createFunctionalComponentFromView(
  useApplicationPageTitleControllerProvider,
  (controller) => {
    const renderTeamLink = () => (
      <TeamLink
        id={controller.applicationBasicInfo.team.id}
        name={controller.applicationBasicInfo.team.name}
      />
    );

    function renderJoinTeamButton() {
      if (controller.shouldRenderJoinTeamButton()) {
        return (
          <>
            <ImpactXButton
              className="join-team"
              label="Join Team"
              outline={true}
              onClickHandler={controller.onClickJoinButton}
            />
            <JoinTeamModal
              teamName={controller.applicationBasicInfo.team.name}
              isJoinTeamInProgress={controller.isJoinTeamInProgress}
              show={controller.showJoinTeamModal}
              onCloseModal={controller.onCloseJoinTeamModel}
              onClickJoinButton={controller.onClickJoinTeamButtonInModel}
              pageTitle="Application Details"
            />
          </>
        );
      }
    }

    const renderAuthZMff = () => {
      if (controller.shouldRenderAuthZRequestAccessButton()) {
        return (
          <div
            id="authz-details"
            className="authz-details"
          >
            <AuthZMicroFrontend
              containerId="authz-details"
              applicationName={controller.applicationBasicInfo.name}
              applicationId={controller.applicationBasicInfo.id}
            />
          </div>
        );
      }
      return null;
    };

    function renderExitTeamButton() {
      if (controller.shouldRenderExitTeamButton()) {
        return (
          <>
            <ExitTeamButton
              team={controller.applicationBasicInfo.team}
              onClickConfirmExitButton={controller.onClickConfirmExitTeam}
              exitTeamPE={controller.exitTeamPE}
              loggedInUserEmail={controller.loggedInUserEmail}
              buttonType="secondary"
              pageTitle="Application Details"
            />
          </>
        );
      }
      return null;
    }

    return (
      <div className="ApplicationDetailsPageTitle">
        <div className="left-content">
          <PageTitle

            title={teamTypeLabelMap[controller.applicationBasicInfo.team.teamType]}
            subText={renderTeamLink()}
            displayTitleLighter={true}
            subtitle={<ApplicationName name={controller.applicationBasicInfo.name} />}
          />
        </div>
        <div className="right-content">
          <ApplicationStatus />
          <div className="action-buttons">
            {renderAuthZMff()}
            {renderJoinTeamButton()}
            {renderExitTeamButton()}
          </div>
        </div>
      </div>
    );
  },
);

export default ApplicationDetailsPageTitle;
