import React from "react";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import { IEditApplicationNameController, useEditApplicationNameController } from "./controller";
import "./index.scss";
import ImpactXIconButton from "../../../../../common/components/impactx-icon-button";

export const EditApplicationName = createFunctionalComponentFromView<IEditApplicationNameController>(
  useEditApplicationNameController,
  (controller) => {
    const renderApplicationNameError = () => {
      if (!controller.isApplicationNameValid) {
        return (
          <div className="application-name-validation-error">
            Please enter only lower/upper case alphabets, numbers, hyphen(-), space with 4 - 40 characters.
          </div>
        );
      }
      else {
        return null;
      }
    };

    const renderApplicationNameInputBox = () => (
      <div>
        <span ix-styles="input">
          <input
            type="text"
            className="input-application-name"
            ix-styles="m"
            value={controller.applicationName}
            onChange={controller.onInputValueChange}
            disabled={controller.isUpdateApplicationNameInProgress()}
          />
        </span>
      </div>
    );

    const renderCancelButton = () => (
      <ImpactXIconButton
        className="cancel-application-name-button"
        onClick={controller.onClickCancelButton}
        disabled={controller.isUpdateApplicationNameInProgress()}
        ariaLabel="Cancel"
      >
        <i className="ri-close-line" />
      </ImpactXIconButton>
    );

    const renderSaveButton = () => (
      <ImpactXIconButton
        className="save-application-name-button"
        onClick={controller.onClickConfirmButton}
        disabled={controller.isUpdateApplicationNameInProgress()}
        ariaLabel="Save"
      >
        <i className="ri-check-fill" />
      </ImpactXIconButton>
    );

    return (
      <div className="EditApplicationName">
        <div className="edit-application-input-box-container">
          {renderApplicationNameInputBox()}
          {renderCancelButton()}
          {renderSaveButton()}
        </div>
        {renderApplicationNameError()}
      </div>
    );
  });

export default EditApplicationName;
