import { addTeamMembersValidatorConfigForRoles } from "../../../../utils/teams/validator";
import { TeamType } from "../../../create-team/redux";
import { Choice, paginatedDropdownControl } from "../../../../common/components/custom-form-controls/paginated-dropdown-control";

export const getAddMemberQuestions = (potentialMembers: Choice[], teamType: TeamType) => {
  function getManagerDescriptionConfig() {
    let description = "";
    if (teamType === TeamType.OTHERS || teamType === TeamType.GITS) {
      description = "Add and remove members, change team roles and access to all team's infrastructure resources.";
    }
    else {
      description = "Team point of contact. Access to all team's infrastructure resources.";
    }
    return {
      description,
      descriptionLocation: "underTitle",
    };
  }

  return ([
    {
      type: "tagbox",
      name: "managers",
      title: "Managers",
      ...getManagerDescriptionConfig(),
      select2Config: paginatedDropdownControl(potentialMembers, 3),
    },
    {
      type: "tagbox",
      name: "developers",
      title: "Developers",
      description: "Access to all team's infrastructure resources.",
      descriptionLocation: "underTitle",
      select2Config: paginatedDropdownControl(potentialMembers, 3),
    }, {
      type: "tagbox",
      name: "non-developers",
      title: "Non-developers",
      description: "Access to all team's infrastructure resources, except codebase.",
      descriptionLocation: "underTitle",
      select2Config: paginatedDropdownControl(potentialMembers, 3),
      validators: addTeamMembersValidatorConfigForRoles(),
    },
  ]);
};
