import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useRequestDetailsController, { RequestDetailsProps } from "./controller";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import RequestDetailsHeader from "./header";
import Tasks from "./tasks";
import { RequestActivitiesTable } from "../request-activities";
import { Tabs } from "antd";

const RequestAndTasks = createFunctionalComponentFromView<ReturnType<typeof useRequestDetailsController>, RequestDetailsProps>(
  useRequestDetailsController,
  (controller) => {
    function renderContent() {
      if (controller.shouldRenderProgressMessage()) {
        return (<Progress text={controller.getProgressMessage()} />);
      }
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error
            {...mapApplicationErrorInfoToErrorComponentProps(

              controller.getErrorMessage(),
            )}
          />
        );
      }
      if (controller.shouldRenderRequestDetails()) {
        return (
          <div className="RequestAndTasks">
            <RequestDetailsHeader requestDetails={controller.selectedRequestDetails} />
            <Tabs
              animated={{ inkBar: true, tabPane: false }}
              tabBarStyle={{ color: "#000000" }}
              items={[{ label: controller.taskDetailTitle, key: "request-details",
                        children: (
                          <Tasks
                            requestDetails={controller.selectedRequestDetails}
                            showActions={controller.showActions}
                            title={controller.taskDetailTitle}
                          />
                        ),
              },
                      {
                        label: "Activity history",
                        key: "activity-history",
                        children: <RequestActivitiesTable requestDetails={controller.selectedRequestDetails} />,
                      },
              ]}
            />
          </div>
        );
      }
    }

    return (
      <>{renderContent()}</>
    );
  },
);

export default RequestAndTasks;
