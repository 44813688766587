import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import ProjectClassificationInfo from "./project-classification-info";
import "./index.scss";
import ExperimentAndInnovationInfo from "../../../common/components/project-classification-contents/experiment-innovation-info";
import OthersInfo from "../../../common/components/project-classification-contents/others-info";
import GITSInfo from "../../../common/components/project-classification-contents/gits-info";
import { ProjectClassification } from "../redux";
import ApplicationsLink from "./applications-link";
import PageTitle from "../../../common/components/page-title";

const ProjectClassificationButtons: FunctionComponent = () => (
  <Row>
    <Col>
      <ProjectClassificationInfo
        heading="Experiment and Innovation"
        content={<ExperimentAndInnovationInfo />}
        projectClassification={ProjectClassification.EXPERIMENT_AND_INNOVATION}
        id="experiment-and-innovation-btn"
      />
    </Col>
    <Col>
      <ProjectClassificationInfo
        heading="Others"
        content={<OthersInfo />}
        projectClassification={ProjectClassification.OTHERS}
        id="others-btn"
      />
    </Col>
    <Col>
      <ProjectClassificationInfo
        heading="GITS"
        content={<GITSInfo />}
        projectClassification={ProjectClassification.GITS}
        id="gits-info-btn"
      />
    </Col>
  </Row>
);

const ProjectClassifications: FunctionComponent = () => (
  <div className="ProjectClassification">
    <PageTitle
      title="Get Started"
      subtitle="How do you classify your project/initiative?"
      displayTitleLighter={true}
    />
    <ApplicationsLink />
    <ProjectClassificationButtons />
  </div>
);

export default ProjectClassifications;
