import React, { FunctionComponent } from "react";
import "../review-and-submit.scss";
import useSectionHeaderController from "./controller";
import { CurrentStep } from "../../redux";

type Props = {
  title: string;
  step: CurrentStep;
};

const SectionHeader: FunctionComponent<Props> = (props: Props) => {
  const controller = useSectionHeaderController();
  return (
    <>
      <div className="review-title d-flex flex-row justify-content-between">
        <h5>{props.title}</h5>
        <button
          className="edit-button align-self-end"
          onClick={() => controller.onEditButtonClick(props.step)}
          type="button"
        >
          <span>Edit</span>
          <i className="ri-edit-line ri-xs icon" />
        </button>
      </div>
      <hr />
    </>
  );
};

export default SectionHeader;
