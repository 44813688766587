import { Nullable } from "./nullable";
import YesNoChoices from "../pages/get-started/data/yes-no-choice";
import React from "react";
import ReactDOMServer from "react-dom/server";
import NeoLink from "../common/components/neo-link";

export const getYesNoChoiceFromBoolean = (value: Nullable<boolean> | undefined) => {
  if (value === true) {
    return YesNoChoices.YES;
  }
  else if (value === false) {
    return YesNoChoices.NO;
  }
  return null;
};

export const renderLink = (link: string, label: string) => ReactDOMServer.renderToString(
  <NeoLink
    link={link}
    label={label}
    external
  />,
);
