import React from "react";
import ImpactXLinkButton from "../impactx-link-button";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useFeedbackButtonController from "./controller";
import Analytics from "../../services/analytics";

const FeedbackButton = createFunctionalComponentFromView(
  useFeedbackButtonController,
  (controller) => (
    <div className="FeedbackButton">
      <ImpactXLinkButton
        href={controller.surveyLink}
        onClick={() => Analytics.trackEventClickOnFeedbackButton()}
        ix-styles="l outline"
      >
        <i className="ri-message-2-line" />
        &nbsp; Feedback
      </ImpactXLinkButton>
    </div>
  ),
);

export default FeedbackButton;
