import React from "react";
import "./index.scss";
import useDeveloperToolDetailsController, { DeveloperToolDetailsProps } from "./controller";
import ContainerBox from "../../../../common/components/container-box";
import BackButton from "../../../../common/components/back-button";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import DeveloperToolDetailsPageTitle from "./developer-tool-details-page-title";
import DeveloperToolInformation from "./developer-tool-information";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";

const DeveloperToolDetails = createFunctionalComponentFromView<ReturnType<typeof useDeveloperToolDetailsController>, DeveloperToolDetailsProps>(
  useDeveloperToolDetailsController,
  (controller) => {
    const renderContent = () => {
      if (controller.shouldRenderProgressMessage()) {
        return <Progress text={controller.fetchDeveloperToolDetailsProgressText} />;
      }
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMessage())}
            onClick={controller.handleOnClick}
          />
        );
      }
      return (
        <>
          { }
          <DeveloperToolDetailsPageTitle developerTool={controller.selectedDeveloperTool!} />
          <hr />
          { }
          <DeveloperToolInformation developerTool={controller.selectedDeveloperTool!} />
        </>
      );
    };

    return (
      <div className="DeveloperToolDetails">
        <ContainerBox
          componentToRenderInEmptySpace={
            <BackButton />
          }
          isDisplayingBackButton={true}
        >
          {renderContent()}
        </ContainerBox>
      </div>
    );
  },
);

export default DeveloperToolDetails;
