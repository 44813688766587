import { useHistory } from "react-router-dom";
import { ErrorAction, NeoErrorType } from "../../../utils/errors/neo-errors";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";

export interface IErrorComponentProps {
  errorType: NeoErrorType;
  title: string;
  subTitle: string;
  buttonLabel: string;
  onClick?: () => void;
  height?: number;
  renderAsFullPage?: boolean;
  isFromErrorBoundary?: boolean;
  shouldDisplayErrorImage?: boolean;
}

export function useErrorController(props: IErrorComponentProps) {
  const history = useHistory();
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");

  if (!props.onClick) {
    props.buttonLabel = ErrorAction.GO_BACK;
  }

  if (props.buttonLabel === ErrorAction.GO_BACK) {
    props.onClick = navigateBack;
  }

  function navigateBack() {
    history.go(-1);
  }

  const minHeight = 250;

  return ({
    ...props,
    minHeight,
    isApiUnificationToggleEnabled,
  });
}
