import React, { FunctionComponent } from "react";
import { Nullable } from "../../../utils/nullable";
import { ICategory, INamespace } from "./redux";
import { ProgressErrorValue } from "../../../utils/progress-error-value";
import Error from "../../../common/components/error";

type Props = {
  itemMetadata: ICategory[] | INamespace[];
  itemMetadataPEV: ProgressErrorValue<Nullable<ICategory[]>> | ProgressErrorValue<Nullable<INamespace[]>>;
  highlightedItem: string;
  handleItemClick: (category: string) => void;
  idPrefix: string;
};

const SideBarItems: FunctionComponent<Props> = (props: Props) => {
  if (props.itemMetadataPEV.progress === null && props.itemMetadataPEV.value === null && props.itemMetadataPEV.error) {
    return (
      <Error
        text={
          props.idPrefix === "cat" ? "Fetching Categories Failed" : "Fetching Namespaces Failed"
        }
        showRetry={false}
      />
    );
  }

  if (props.itemMetadataPEV.progress === null && props.itemMetadata.length === 0 && props.idPrefix === "ns") {
    return (
      <div className="no-items-found">
        No namespaces found
      </div>
    );
  }

  return (
    <div id="sidebar-items-container">
      {
        props.itemMetadata?.map((item) => (
          // eslint-disable-next-line react/jsx-key
          <div
            id={`${props.idPrefix}-${item.name}`}
            className={props.highlightedItem === item.name
              ? "sidebar-item-selected"
              : "sidebar-item"}

            onClick={() => props.handleItemClick(item.name)}
          >
            {item.name}
          </div>
        ))
      }
    </div>
  );
};

export default SideBarItems;
