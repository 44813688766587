import { DeveloperTool } from "../../redux";
import { Hash } from "../../../../../utils/hash";
import { useSelectorTyped } from "../../../../../common/redux/store";

export type Props = {
  developerTool: DeveloperTool;
};

export default function useDeveloperToolInformationController(props: Props) {
  const { labelMaps } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const developerToolFields: Array<keyof Partial<DeveloperTool>>
    = ["description", "purpose", "recommendedFor", "taxonomyCategory", "techStack", "owners", "version"];
  const headingsMap: Hash<string> = {
    description: "Description",
    purpose: "Purpose",
    recommendedFor: "Recommended for",
    taxonomyCategory: "Category",
    techStack: "Tech Stack",
    owners: "Owner(s)",
    version: "Version",
  };

  function isTechStackField(fieldName: string) {
    return fieldName === "techStack";
  }

  function isOwnerField(fieldName: string) {
    return fieldName === "owners";
  }

  function renderAsList(fieldName: string) {
    return isTechStackField(fieldName) || isOwnerField(fieldName);
  }

  function isCategoryField(fieldName: keyof DeveloperTool) {
    return fieldName === "taxonomyCategory";
  }

  function getTaxonomyCategoryLabel() {
    return (props.developerTool.taxonomyCategory && labelMaps)
      ? labelMaps.taxonomyCategoryLabelMap[props.developerTool.taxonomyCategory]
      : "Others";
  }

  function getFieldValue(fieldName: keyof DeveloperTool) {
    return isCategoryField(fieldName) ? getTaxonomyCategoryLabel() : props.developerTool[fieldName];
  }

  return ({
    developerTool: props.developerTool,
    getDeveloperToolFields: () => developerToolFields,
    headingsMap,
    getFieldValue,
    renderAsList,
  });
}
