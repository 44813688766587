import castTo, { identity } from "../../utils/cast-to";
import {
  ADR_MFF_ID,
  API_GW_MFF_ID,
  DATA_CATALOGUE_GW_MFF_ID,
  FOUR_KEY_METRICS_MFF_ID,
  IMicroFrontendData,
  IMicroFrontendHeaderMenus,
  MASTER_DATA_MFF_ID,
  MffId,
} from "../data/microfrontend-types";
import { environmentConfigForUnification } from "./environment-config";

// todo extract into helper file
function getMffHeaderMenusFromWindowKeyName(headerMenusObjectName: string) {
  return {
    headerMenusObjectName,
    getHeaderMenus: () => castTo<IMicroFrontendHeaderMenus | null>(window[headerMenusObjectName]),
  };
}

// todo extract into helper file
export function getMicroFrontendDataFromId(id: MffId) {
  const { microFrontends } = getEnvVars();
  return microFrontends.find((microFrontend) => microFrontend.id === id);
}

export function getEnvVars() {
  const { hostname } = window.location;
  const host = window.location.host;
  const callbackPath = "/login/callback";
  const defaultOktaAuth = {
    clientId: "",
    issuer: "https://thoughtworks.oktapreview.com/oauth2/ausjs00lctzrNgRMj0h7",
    scopes: "openid profile email",
    redirectUrl: `https://${host}${callbackPath}`,
    callbackPath,
    oktaTokenKey: process.env.REACT_APP_OKTA_TOKEN_KEY,
  };
  const defaultEnv = {
    env: "",
    bffHostUrl: "/bff",
    bffEventsHostURL: "/bff-events",
    featureToggleHostUrl: "/feature-toggles",
    thoughtworksAPIPlatformBaseURL: "https://sandbox.neo.thoughtworks.net/api-gateway/my-apis",
    feedbackEmailId: "engineering-platform-support@thoughtworks.com",
    disableOktaAuth: false,
    oktaAuth: defaultOktaAuth,
    diSupportMailId: "engineering-platform-support@thoughtworks.com",
    neoSupportMailId: "engineering-platform-support@thoughtworks.com",
    microFrontends: [],
    authZMffJsUrl: "",
    unificationConfig: environmentConfigForUnification.local,
    surveyNudgeUrl: "https://cl.qualaroo.com/ki.js/80068/iFR.js",
    matomo: {
      siteId: "82",
      baseUrl: "https://thoughtworks.innocraft.cloud/",
    },
  };

  if (hostname === "localhost") {
    const backendBaseUrl = "http://localhost:5000";
    return {
      ...defaultEnv,
      env: "local",
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      bffHostUrl: `${backendBaseUrl}/bff`,
      diSupportMailId: "events-platform-devs@thoughtworks.com",
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      disableOktaAuth: true,
      microFrontends: [
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: localStorage.getItem("apiGwJsUrl") || "",
        }),
        identity<IMicroFrontendData>({
          id: DATA_CATALOGUE_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("dataCatalogueMenus"),
          renderRootElementFunctionName: "renderDataCatalogueRootElement",
          jsUrl: localStorage.getItem("dataCatalogueJsUrl") || "",
          cssUrl: localStorage.getItem("dataCatalogueCssUrl") || "",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: localStorage.getItem("masterDataJsUrl") || "",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: localStorage.getItem("fourKeyMetricsJSUrl") || "",
        }),
      ],
      unificationConfig: environmentConfigForUnification.local,
      authZMffJsUrl: "",
    };
  }
  if (hostname === "ui") { // for running e2e tests in circleCI
    const backendBaseUrl = "http://server:5000";
    return {
      ...defaultEnv,
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      bffHostUrl: `${backendBaseUrl}/bff`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      env: "test",
      disableOktaAuth: true,
      microFrontends: [
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: DATA_CATALOGUE_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("dataCatalogueMenus"),
          renderRootElementFunctionName: "renderDataCatalogueRootElement",
          jsUrl: "",
          cssUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: "",
        }),
      ],
      unificationConfig: environmentConfigForUnification.local,
      authZMffJsUrl: "",
    };
  }
  if (hostname === "host.docker.internal") { // for running e2e tests on local in headless mode
    const backendBaseUrl = "http://host.docker.internal:5000";
    return {
      ...defaultEnv,
      env: "test",
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      bffHostUrl: `${backendBaseUrl}/bff`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      disableOktaAuth: true,
      microFrontends: [
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: DATA_CATALOGUE_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("dataCatalogueMenus"),
          renderRootElementFunctionName: "renderDataCatalogueRootElement",
          jsUrl: "",
          cssUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: "",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: "",
        }),
      ],
      unificationConfig: environmentConfigForUnification.local,
      authZMffJsUrl: "",
    };
  }
  if (hostname.includes("dev-dot-platform-developer-portal")
    || hostname.includes("dev.neo.thoughtworks.net")
    || hostname.includes("dev-platform-developer-portal.web.app")) {
    const backendBaseUrl = "https://dev.bff.neo.thoughtworks.net";
    return {
      ...defaultEnv,
      env: "dev",
      bffHostUrl: `${backendBaseUrl}/bff`,
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      oktaAuth: {
        ...defaultOktaAuth,
        clientId: "0oavzy3zi4YEexJgO0h7",
      },
      diSupportMailId: "events-platform-devs@thoughtworks.com",
      microFrontends: [
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "https://adr-service-98523741603.asia-south1.run.app/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: localStorage.getItem("apiGwJsUrl")
            || "https://neo-api-ui-dev.web.app/api-micro-frontend/api-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: DATA_CATALOGUE_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("dataCatalogueMenus"),
          renderRootElementFunctionName: "renderDataCatalogueRootElement",
          jsUrl: localStorage.getItem("dataCatalogueJsUrl")
            || "https://storage.googleapis.com/dev_data_catalogue_resources/ui-bundle/data-catalogue-micro-frontend.js",
          cssUrl: localStorage.getItem("dataCatalogueCssUrl") || "",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: localStorage.getItem("masterDataJsUrl")
            || "https://master-data-ui-dev.web.app/masterdata-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: localStorage.getItem("fourKeyMetricsJSUrl")
            || "https://neo-4km-frontend-dev.web.app/4km-micro-frontend.js",
        }),
      ],
      unificationConfig: environmentConfigForUnification.dev,
      authZMffJsUrl: "https://dev-ar-authz-techops-tw.web.app/main.js",
    };
  }
  if (hostname.includes("qa-dot-platform-developer-portal")
    || hostname.includes("qa.neo.thoughtworks.net")) {
    const backendBaseUrl = "https://qa.bff.neo.thoughtworks.net";
    return {
      ...defaultEnv,
      env: "qa",
      bffHostUrl: `${backendBaseUrl}/bff`,
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      oktaAuth: {
        ...defaultOktaAuth,
        clientId: "0oawqgmh8g7tIwTZJ0h7",
      },
      unificationConfig: environmentConfigForUnification.qa,
      diSupportMailId: "events-platform-devs@thoughtworks.com",
      microFrontends: [
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: "https://neo-api-ui-qa.web.app/api-micro-frontend/api-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: "https://master-data-ui-qa.web.app/masterdata-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: "https://neo-4km-frontend-qa.web.app/4km-micro-frontend.js",
        }),
      ],
      authZMffJsUrl: "https://test-ar-authz-techops-tw.web.app/main.js",
    };
  }
  if (hostname.includes("sandbox.neo.thoughtworks.net")) {
    const backendBaseUrl = "https://sandbox.bff.neo.thoughtworks.net";
    return {
      ...defaultEnv,
      env: "sandbox",
      bffHostUrl: `${backendBaseUrl}/bff`,
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      matomo: {
        baseUrl: defaultEnv.matomo.baseUrl,
        siteId: "141",
      },
      oktaAuth: {
        ...defaultOktaAuth,
        issuer: "https://thoughtworks.okta.com/oauth2/default",
        clientId: "0oa1w3aqrkdkeXGlA0h8",
      },
      diSupportMailId: "events-platform-devs@thoughtworks.com",
      microFrontends: [
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: "https://neo-api-ui-stg.web.app/api-micro-frontend/api-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: "https://master-data-ui-stg.web.app/masterdata-micro-frontend.js",
        }),
      ],
      unificationConfig: environmentConfigForUnification.sandbox,
      authZMffJsUrl: "",
    };
  }
  if (hostname === "neo.thoughtworks.net") {
    const backendBaseUrl = "https://bff.neo.thoughtworks.net";
    return {
      ...defaultEnv,
      env: "production",
      bffHostUrl: `${backendBaseUrl}/bff`,
      bffEventsHostURL: `${backendBaseUrl}/bff-events`,
      featureToggleHostUrl: `${backendBaseUrl}/feature-toggles`,
      matomo: {
        baseUrl: defaultEnv.matomo.baseUrl,
        siteId: "66",
      },
      unificationConfig: environmentConfigForUnification.production,
      thoughtworksAPIPlatformBaseURL: "https://neo.thoughtworks.net/api-gateway/my-apis",
      oktaAuth: {
        ...defaultOktaAuth,
        issuer: "https://thoughtworks.okta.com/oauth2/default",
        clientId: "0oa1k5om5blzBjxaB0h8",
      },
      microFrontends: [
        identity<IMicroFrontendData>({
          id: API_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("apiGwMenus"),
          renderRootElementFunctionName: "renderApiGatewayRootElement",
          jsUrl: "https://neo-api-ui.web.app/api-micro-frontend/api-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: ADR_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("adrMenus"),
          renderRootElementFunctionName: "renderAdrRootElement",
          jsUrl: localStorage.getItem("adrJsUrl")
            || "http://localhost:8080/static/adr-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: MASTER_DATA_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("masterDataMenus"),
          renderRootElementFunctionName: "renderMasterDataRootElement",
          jsUrl: "https://master-data-ui-prod.web.app/masterdata-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: DATA_CATALOGUE_GW_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("dataCatalogueMenus"),
          renderRootElementFunctionName: "renderDataCatalogueRootElement",
          jsUrl: "https://storage.googleapis.com/prod_data_catalogue_resources/ui-bundle/data-catalogue-micro-frontend.js",
        }),
        identity<IMicroFrontendData>({
          id: FOUR_KEY_METRICS_MFF_ID,
          ...getMffHeaderMenusFromWindowKeyName("4KMMenus"),
          renderRootElementFunctionName: "render4KMRootElement",
          jsUrl: "https://neo-4km-frontend-prod.web.app/4km-micro-frontend.js",
        }),
      ],
      authZMffJsUrl: "https://ar-authz-techops-tw.web.app/main.js",
    };
  }
  return defaultEnv;
}
