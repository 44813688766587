import googleWSProgramImage from "./assets/google_workspace_preview_program.png";
import devToolsBanner from "./assets/developer-tools-banner.png";
import secretsRotatorImage from "./assets/secrets_rotator_banner_bg.png";
import CIOBg from "./assets/CIO_bg.png";

export type CarouselConfig = {
  interval: number;
  items: Array<{
    heading: string;
    description: string;
    links?: Array<{
      url: string;
      label: string;
      isExternalLink?: boolean;
    }>;
    imageSource?: string;
    color?: string;
    backgroundColor?: string;
  }>;
};

export const HomePageCarousel: CarouselConfig
  = {
    interval: 5000,
    items:
      [
        {
          backgroundColor: "#156C86",
          description: "Try Google Workspace APIs or extend your Workspace applications with early access to new APIs available in "
            + "the Google Workspace Developer Preview Program.",
          heading: "Google Workspace Developer",
          imageSource: googleWSProgramImage,
          links:
            [
              {
                isExternalLink: true,
                label: "Try existing APIs >",
                url: "https://developers.google.com/workspace",
              },
              {
                isExternalLink: true,
                label: "Explore new APIs >",
                url: "https://developers.google.com/workspace/preview",
              },
            ],
        },
        {
          description: "Infuse your project with proven, reusable and customizable code that powers you to go to market faster!",
          heading: "Developer Tools",
          imageSource: devToolsBanner,
          links:
            [
              {
                label: "Discover >",
                url: "/catalogue/developer-tools",
              },
            ],
        },
        {
          backgroundColor: "#517B5C",
          description: "Secure your team's secrets automatically, periodically and without system downtime.",
          heading: "Automated Secrets Rotator",
          imageSource: secretsRotatorImage,
          links:
            [
              {
                label: "Discover >",
                url: "/catalogue/developer-tools/UtxWTI2WoQ/Automated_Secrets_Rotator",
              },
            ],
        },
        {
          backgroundColor: "#003D4F",
          description: "Find here capabilities to build and deliver software rapidly, frequently and reliably at scale.",
          heading: "Explore. Build. Deploy.",
        },
        {
          backgroundColor: "#A06908",
          description: "Thoughtworks Developer Experience Portal, NEO has been named a CIO 100 Award winner for 2022.",
          heading: "NEO wins CIO Award",
          imageSource: CIOBg,
          links:
            [
              {
                isExternalLink: true,
                label: "Read more >",
                url: "https://www.thoughtworks.com/about-us/news/2022/thoughtworks-announced-as-2022-cio-100-award-winner?"
                  + "utm_source=linkedin&utm_medium=social-organic&utm_campaign=cio100_2022-03",
              },
            ],
        },
      ],
  };
