import React, { FunctionComponent } from "react";
import "./index.scss";
import RightArrowIcon from "../../../common/components/icons/arrow-icon";
import NeoLink from "../../../common/components/neo-link";

export type TextNavigationCardProps = {
  text: string;
  link: string;
  isExternalLink?: boolean;
  onTrackAnalytics?: () => void;
};

const TextNavigation: FunctionComponent<TextNavigationCardProps> = (props: TextNavigationCardProps) => {
  const renderContentWithExternalLink = () => (
    <NeoLink
      link={props.link}
      onClick={props.onTrackAnalytics}
      external
    >
      {renderContent()}
    </NeoLink>
  );

  const renderContentWithInternalLink = () => (
    <NeoLink
      link={props.link}
      onClick={props.onTrackAnalytics}
    >
      {renderContent()}
    </NeoLink>
  );

  const renderContent = () => (
    <div className="text-navigation">
      <span>{props.text}</span>
      <div>
        <RightArrowIcon />
      </div>
    </div>
  );

  return (
    <div className="TextNavigation">
      {props.isExternalLink ? renderContentWithExternalLink() : renderContentWithInternalLink()}
    </div>
  );
};

export default TextNavigation;
