import React from "react";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useQualarooController from "./controller";

const Qualaroo = createFunctionalComponentFromView(
  useQualarooController,
  (controller) =>
    controller.isViewQualarooNudgeToggleEnabled ? <div id="qualaroo-nudge" /> : null,
);

export default Qualaroo;
