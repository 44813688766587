import ReplayEventsImage from "../assets/images/replay_events.png";
import ComingNextImage from "../assets/images/coming_next.png";
export const publisherArticles = [{
  title: "Request auth to be a publisher",
  description: "The events platform will review and authorise if you are allowed to be a event publisher",
  link: "/events/user-guide#section/Authorization-Matrix",
}, {
  title: "Setup event registrations",
  description: "This step follows once you have setup namespaces under which the event registrations can be made.",
  link: "/events/user-guide#section/Publish-Events/Set-up-and-manage-namespaces",
}, {
  title: "Use Events CLI for CI/CD",
  description: "Events platform provides a Command Line interface that allows you to deploy your publishing capabilities",
  link: "/events/user-guide#section/Events-Platform-CLI",
}];
export const subscriberArticles = [{
  title: "Discover events",
  description: "A rich experience in NEO to enable you to look for what you are looking for.",
  link: "/events/discovery",
}, {
  title: "Request for auth and seek approval",
  description: "Ask the publisher the authorisation that allows you to listen to their events for any business reason",
  link: "/events/user-guide#section/Subscribe-to-Events/Request-for-Authorization",
}, {
  title: "Set up your subscriptions",
  description: "When authorised you can set up subscriptions that allows you to receive events as and when published",
  link: "/events/user-guide#section/Subscribe-to-Events/Set-up-and-manage-subscriptions",
}];
export const whatsNew = [{
  title: "Replay Events for your subscriptions",
  description: "Now you can replay events of past in your subscriptions even after it was successfully delivered once.",
  link: "/events/user-guide",
  section: "#section/Subscribe-to-Events/Replay-events-in-a-subscription",
  image: ReplayEventsImage,
  target: "_self",
}, {
  title: "Events Platform Evolve",
  description: "Read the central article on how events platform have evolved as a successful internal product.",
  link: "https://central.thoughtworks.net/home/ls/community/techops-internal/post/6653148551577600",
  section: "",
  image: ComingNextImage,
  target: "_blank",
}];
