import React, { FunctionComponent } from "react";
import { Props } from "./experiment-innovation-info";

const GITSInfo: FunctionComponent<Props> = (props: Props) => {
  function renderInfraSupport() {
    if (!props.hideApplicationSpecificInfo) {
      return (
        <div className="infra-support">
          <div className="title">
            Infrastructure support
          </div>
          <div className="infra-details">
            <div className="detail">
              <div className="detail-field">Github</div>
              <div className="detail-value">Private Github repo</div>
            </div>
            <div className="detail">
              <div className="detail-field">GCP</div>
              <div className="detail-value">Billed to the teams</div>
            </div>
            <div className="detail">
              <div className="detail-field">Circle CI</div>
              <div className="detail-value">Private Pipeline</div>
            </div>
            <div className="detail">
              <div className="detail-field">OKTA</div>
              <div className="detail-value">Single sign on</div>
            </div>
            <div className="detail">
              <div className="detail-field">Matomo</div>
              <div className="detail-value">Analytics tool</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  function renderAdditionalNotes() {
    if (!props.hideApplicationSpecificInfo) {
      return (
        <>
          <br />
          <br />
          <strong>*Requires project billing code.</strong>
        </>
      );
    }
    return null;
  }

  return (
    <div className="project-classification-content">
      <div className="team-type-details">
        <div>
          Global IT Services engineering teams that build and deliver applications or products for Global Business Functions.
          {renderAdditionalNotes()}
        </div>
      </div>
      {renderInfraSupport()}
    </div>
  );
};

export default GITSInfo;
