import React from "react";
import "./infra-details.scss";
import DetailsList from "../details-list";
import { iif } from "../../../../utils/functions";
import RequestedInfraDetail from "./requested-infra-detail";
import { InfrastructureDetails } from "../redux";

type Props = {
  title: string;
  provisionedInfra: string[];
  requestedInfra: InfrastructureDetails["requestedInfra"]["gcpProjects"] | InfrastructureDetails["requestedInfra"]["repositories"];
  isApplicationCreatedAfterProductionGCPDisablingDate?: boolean;
  isDisplayGCPBudgetAlertToggleEnabled?: boolean;
  shouldDisplayBudgetAlert?: boolean;
  showTaskDetailsPageUrl?: boolean;
};

const InfraDetails = (props: Props) => {
  const {
    isApplicationCreatedAfterProductionGCPDisablingDate,
    shouldDisplayBudgetAlert,
    isDisplayGCPBudgetAlertToggleEnabled,
    title,
    provisionedInfra,
    requestedInfra,
    showTaskDetailsPageUrl,
  } = props;
  const renderTitle = () => (
    <div className="info-title">
      {title}
    </div>
  );

  const renderProvisionedInfra = () => {
    if (provisionedInfra.length > 0) {
      return (
        <DetailsList
          detailsList={provisionedInfra}
          renderUrlsAsHyperLink={true}
        />
      );
    }
  };

  const renderGcpCostAlert = () => (
    <div className="cost-alert">
      <i className="ri-information-fill" />
      <div className="alert-message">
        {
          isApplicationCreatedAfterProductionGCPDisablingDate || (provisionedInfra.length === 0 && requestedInfra.length === 0)
            ? "All GCP projects (Production & Non-production) associated to this application will be disabled when the sum of "
            + "all GCP projects exceeds the $100 monthly budget."
            : "Non-production GCP projects associated to this application will be disabled when the sum of all "
              + "non-production GCP projects exceeds the $100 monthly budget."
        }
      </div>
    </div>
  );

  const renderRequestedInfra = () => {
    if (requestedInfra.length > 0) {
      return (
        <div className="requested-infra">
          {
            requestedInfra.map((detail, idx) => (
              <RequestedInfraDetail
                requestId={detail.requestId}
                key={idx}
                label={detail.name}
                showTaskDetailsPageUrl={showTaskDetailsPageUrl}
              />
            ))
          }
        </div>
      );
    }
  };

  const renderDetails = () => {
    if (iif(function isNoInfraExists() {
      return provisionedInfra.length === 0 && requestedInfra.length === 0;
    })) {
      return (
        <DetailsList
          detailsList={[]}
        />
      );
    }
    else {
      return (
        <>
          {renderProvisionedInfra()}
          {renderRequestedInfra()}
        </>
      );
    }
  };

  return (
    <div className="InfraDetails">
      {renderTitle()}
      { }
      {isDisplayGCPBudgetAlertToggleEnabled && shouldDisplayBudgetAlert && renderGcpCostAlert()}
      {renderDetails()}
    </div>
  );
};

export default InfraDetails;
