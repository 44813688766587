import React, { FunctionComponent } from "react";
import ContainerBox from "../../common/components/container-box";
import ImpactXButton from "../../common/components/impactx-button";
import useTeamsController from "./controller";
import "./index.scss";
import TeamTypesSelection from "./team-types";
import { Nav, Tab } from "react-bootstrap";
import MyTeams from "./my-teams";
import AllTeams from "./all-teams";
import { PageTitleWithRightElement } from "../../common/components/page-title-with-right-column-element";
import { TEAMS_TAB_EVENT_KEY } from "./redux";

export const Teams: FunctionComponent = () => {
  const controller = useTeamsController();

  const renderChooseTeamTypeForTeamCreationModal = () => (
    <TeamTypesSelection />
  );

  const renderManageTeamsInfoForSandbox = () => (
    <div className="team-manage-info">
      To create and manage teams switch to NEO portal using the toggle above. These actions cannot be performed in NEO sandbox.
    </div>
  );

  const renderTabLabel = (tabLabel: string, eventKey: TEAMS_TAB_EVENT_KEY) => (
    <Nav.Item>
      <Nav.Link
        as="span"
        eventKey={eventKey}
      >
        <div className="nav-text">
          {tabLabel}
        </div>
        <div className="nav-text hidden-tab-text">
          {tabLabel}
        </div>
      </Nav.Link>
    </Nav.Item>
  );

  // TODO: Can be extracted out into common component
  const renderTabs = () => (
    <Tab.Container
      onSelect={(tab) => tab && controller.setActiveTab(tab as TEAMS_TAB_EVENT_KEY)}
      activeKey={controller.activeTab}
    >
      <Nav>
        {renderTabLabel("My Teams", TEAMS_TAB_EVENT_KEY.MY_TEAMS)}
        {renderTabLabel("All Teams", TEAMS_TAB_EVENT_KEY.ALL_TEAMS)}
      </Nav>
      <Tab.Content>
        <Tab.Pane
          eventKey={TEAMS_TAB_EVENT_KEY.MY_TEAMS}
          onEnter={controller.onClickMyTeamsTab}
        >
          <MyTeams onClickCreateTeamButton={controller.onClickCreateButton} />
        </Tab.Pane>
        <Tab.Pane
          eventKey={TEAMS_TAB_EVENT_KEY.ALL_TEAMS}
          onEnter={controller.onClickAllTeamsTab}
        >
          <AllTeams />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );

  return (
    <div className="Teams">
      <ContainerBox>
        <PageTitleWithRightElement
          title="Teams"
          rightColumnElement={
            !(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox)
              ? (
                <ImpactXButton
                  label="Create Team"
                  onClickHandler={controller.onClickCreateButton}
                />
              )
              : <></>
          }
        />
        {(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderManageTeamsInfoForSandbox()}
        <div className="teams-container">
          {renderChooseTeamTypeForTeamCreationModal()}
          {renderTabs()}
        </div>
      </ContainerBox>
    </div>
  );
};
