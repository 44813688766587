import React from "react";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import { IEditApplicationStatusController, useEditApplicationStatusController } from "./controller";
import "./index.scss";
import ImpactXIconButton from "../../../../../common/components/impactx-icon-button";

export const EditApplicationStatus = createFunctionalComponentFromView<IEditApplicationStatusController, unknown>(
  useEditApplicationStatusController,
  (controller) => {
    const renderApplicationStatusDropdownList = () => (
      controller?.applicationStatusList?.map((status) => (
        <option
          key={status}
          value={status}
        >
          {status}
        </option>
      ))
    );

    const renderApplicationStatusDropdown = () => (
      <div className="select-application-status">
        <select
          onChange={controller.onApplicationStatusChange}
          defaultValue={controller.applicationInfo.status}
          disabled={controller.isUpdateApplicationStatusInProgress()}
        >
          {renderApplicationStatusDropdownList()}
        </select>
      </div>
    );

    const renderCancelButton = () => (
      <ImpactXIconButton
        className="cancel-application-status-button"
        onClick={controller.onClickCancelButton}
        disabled={controller.isUpdateApplicationStatusInProgress()}
        ariaLabel="Cancel"
      >
        <i className="ri-close-line" />
      </ImpactXIconButton>
    );

    const renderSaveButton = () => (
      <ImpactXIconButton
        className="save-application-status-button"
        onClick={controller.onClickConfirmButton}
        disabled={controller.isUpdateApplicationStatusInProgress()}
        ariaLabel="Save"
      >
        <i className="ri-check-fill" />
      </ImpactXIconButton>
    );

    return (
      <div className="EditApplicationStatus">
        <div className="title">Status:&nbsp;</div>
        {renderApplicationStatusDropdown()}
        {renderCancelButton()}
        {renderSaveButton()}
      </div>
    );
  });

export default EditApplicationStatus;
