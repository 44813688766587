import React, { useRef } from "react";
import { IMicroFrontedProps, useMicroFrontendControllerProvider } from "./controller";
import { iif } from "../../utils/functions";
import {
  ADR_MFF_ID,
  API_GW_MFF_ID,
  DATA_CATALOGUE_GW_MFF_ID,
  FOUR_KEY_METRICS_MFF_ID,
  IMicroFrontendData,
  MASTER_DATA_MFF_ID,
  Window,
} from "../../common/data/microfrontend-types";

const renderedMffRootElements: Record<IMicroFrontendData["id"], HTMLElement | undefined> = {
  [API_GW_MFF_ID]: undefined,
  [MASTER_DATA_MFF_ID]: undefined,
  [DATA_CATALOGUE_GW_MFF_ID]: undefined,
  [FOUR_KEY_METRICS_MFF_ID]: undefined,
  [ADR_MFF_ID]: undefined,
};

export const MicroFrontend = (props: IMicroFrontedProps) => {
  const controller = useMicroFrontendControllerProvider(props);
  const rootElementRef = useRef<HTMLElement | null>(null);

  return (
    <div
      ref={(htmlInstance) => {
        rootElementRef.current = htmlInstance;
        if (!htmlInstance) {
          return;
        }

        const previouslyRenderedMffElement = renderedMffRootElements[props.microFrontedData.id];
        if (previouslyRenderedMffElement) {
          iif(function reRenderMff() {
            try {
              htmlInstance.append(previouslyRenderedMffElement);
            }
            catch {}
          });
        }
        else {
          iif(function renderMff() {
            if (window[controller.microFrontendData.renderRootElementFunctionName]) {
              (window as Window)[controller.microFrontendData.renderRootElementFunctionName]?.(htmlInstance, controller.history);
              renderedMffRootElements[props.microFrontedData.id] = htmlInstance;
            }
          });
        }
      }}
    />
  );
};
