import * as Survey from "survey-react";
import React, { ChangeEvent } from "react";

const checkBox = {
  name: "checkBox",
  isFit: (question: Survey.QuestionCheckboxModel) => question.getType() === "checkbox",
  render: function renderCheckBox(question: Survey.QuestionCheckboxModel) {
    question.otherErrorText = "Required";
    const getQuestionValues = () => question.value as string[];

    const onCheckBoxSelectionChange = () => {
      // todo dont use query selector.
      const values = document.querySelectorAll(`[name="${question.name}"] input:checked`);
      const selectedValues: Array<string | null> = [];
      values.forEach((value) => {
        if (value) {
          selectedValues.push(value.getAttribute("value"));
        }
      });
      question.value = selectedValues;
    };

    const onCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      question.comment = value;
    };

    function renderCheckboxes() {
      return question.choices.map((data: Survey.ItemValue, index) => {
        let checked = false;

        if (getQuestionValues().includes(data.value)) {
          checked = true;
        }
        const id = `${question.title} ${index}`;
        return (
          <div key={index}>
            <input
              type="checkbox"
              name={data.value as string}
              className="surveyCheckbox"
              id={id}
              value={data.value as string}
              onChange={onCheckBoxSelectionChange}
              checked={checked}
            />
            <label
              htmlFor={id}
            >
              {data.text}
            </label>
          </div>
        );
      });
    }

    const renderOthersComment = () => {
      const textAreaId = `${question.title} textArea`;
      if (question.hasOther && getQuestionValues().includes("other")) {
        return (
          <textarea
            id={textAreaId}
            ix-styles="m"
            onChange={onCommentChange}
            defaultValue={question.comment}
            placeholder={question.otherPlaceHolder}
          />
        );
      }
    };

    const renderOtherCheckBox = () => {
      const id = "other";
      let checked = false;
      if (getQuestionValues().includes("other")) {
        checked = true;
      }
      if (question.hasOther) {
        return (
          <div key={id}>
            <input
              type="checkbox"
              name={question.title}
              className="surveyCheckbox"
              id={id}
              value="other"
              onChange={onCheckBoxSelectionChange}
              checked={checked}
            />
            <label
              htmlFor={id}
            >
              {question.otherText}
            </label>
          </div>
        );
      }
    };

    return (
      <div>
        {renderCheckboxes()}
        {renderOtherCheckBox()}
        {renderOthersComment()}
      </div>
    );
  },
};

export default checkBox;
