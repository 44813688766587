import React, { FunctionComponent } from "react";
import PageTitle from "../../../../common/components/page-title";
import TeamListTable from "../../../../common/components/team-list-table";
import useTeamInformationController from "./controller";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import ImpactXButton from "../../../../common/components/impactx-button";
import "./index.scss";
import StepBar from "../../../../common/components/step-bar";
import UserNotAssociatedToTeam from "../../user-not-associated-to-team-error";
import { TeamType } from "../../../create-team/redux";

export const SelectTeam: FunctionComponent = () => {
  const controller = useTeamInformationController();

  const renderButton = (label: string, isSecondaryButton: boolean = true) => (
    <ImpactXButton
      className="previous-button"
      label={label}
      outline={isSecondaryButton}
      onClickHandler={controller.onPrevious}
    />
  );

  const renderContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return (<Progress text={controller.teamListProgress} />);
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error

          {...mapApplicationErrorInfoToErrorComponentProps(controller.teamListError!)}
        />
      );
    }
    return (
      <TeamListTable
        teams={controller.getTeamList()}
        onRowClickedHandler={controller.rowClickHandler}
        hideStatus={true}
      />
    );
  };

  const renderSelectTeamPage = () => (
    <>
      <StepBar
        stepLabels={["Select Team", "Application Information", "Infrastructure Configuration", "Review and Submit"]}
        currentCompletedStep={1}
      />
      <PageTitle
        title="Global IT Services"
        displayTitleLighter={true}
        subtitle="Select Team"
      />
      <div className="content">
        {renderContent()}
      </div>
      {renderButton("PREVIOUS")}
    </>
  );

  const renderPage = () => {
    if (controller.shouldRenderUserNotAssociatedToTeamMessage()) {
      return (
        <UserNotAssociatedToTeam
          showProgress={controller.shouldRenderProgressMessage()}
          showError={controller.shouldRenderErrorMessage()}
          progressMsg={controller.teamListProgress}
          // @ts-ignore
          errorMsg={controller.teamListError}
          teamType={TeamType.GITS}
          onClickGoBackBtn={controller.onPrevious}
        />
      );
    }
    else {
      return renderSelectTeamPage();
    }
  };

  return (
    <div className="SelectTeam">
      {renderPage()}
    </div>
  );
};
