import React, { FunctionComponent } from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Pages } from "../app/pages";
import { GetStartedPageActions } from "../../../pages/get-started/redux";
import ImpactXButton from "../impactx-button";

type Props = {
  label?: string;
  onTrackAnalytics?: () => void;
};

const GetStartedBtn: FunctionComponent<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onClickGetStartedBtn = () => {
    if (location.pathname !== Pages.PROJECT_CATEGORIES.path) {
      history.push(Pages.PROJECT_CATEGORIES.path);
    }
    dispatch(GetStartedPageActions.updateCurrentStep("PROJECT_CLASSIFICATION"));
    if (props.onTrackAnalytics) {
      props.onTrackAnalytics();
    }
  };

  return (
    <ImpactXButton
      className="get-started"
      label={props.label || "Get Started"}
      onClickHandler={onClickGetStartedBtn}
    />
  );
};

export default GetStartedBtn;
