import { ITask, Resource, TaskStatus, TaskStatusLabel } from "../../../types/requests";
import { useState } from "react";
import { RequestsPageActions } from "../../../redux";
import { Nullable } from "../../../../../utils/nullable";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../../common/redux/store";
import Analytics from "../../../../../common/services/analytics";
import { useGetFeatureToggleState } from "../../../../../common/hooks/feature-toggles";

export type ResourceTableProps = {
  tasks: ITask[];
  showActions?: boolean;
};

type ActionButton = {
  name: string;
  onClickHandler?: () => void;
  disabled?: boolean;
};

export const useResourceTableController = ({ tasks, showActions }: ResourceTableProps) => {
  const [selectedTask, setSelectedTask] = useState<Nullable<ITask>>(null);
  const approveTaskPE = useSelectorTyped((state) => state.requestsPage.approveTaskPE);
  const completeTaskPE = useSelectorTyped((state) => state.requestsPage.completeTaskPE);
  const reTriggerTaskPE = useSelectorTyped((state) => state.requestsPage.reTriggerTaskPE);
  const { labelMaps } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const isTaskReTriggerFeatureEnabled = useGetFeatureToggleState("Neo_Tasks_ReTrigger");
  const { taskTypeLabelMap } = labelMaps;

  const dispatch = useDispatch();

  const isStatusPendingApproval = (task: ITask) => task.status === TaskStatus.PENDING_APPROVAL;

  const isStatusApproved = (task: ITask) => task.status === TaskStatus.APPROVED;

  const isStatusDisapproved = (task: ITask) => task.status === TaskStatus.DISAPPROVED;

  const isStatusFailed = (task: ITask) => task.status === TaskStatus.FAILED;

  const isManualTask = (task: ITask) => !task.hasAutomation;

  const getDisabledStatus = () => (!!approveTaskPE.progress || !!completeTaskPE.progress || !!reTriggerTaskPE.progress);

  const getActionButtons = (task: ITask): ActionButton[] => {
    if (isStatusPendingApproval(task)) {
      return [
        {
          name: "Approve",
          onClickHandler: () => {
            dispatch(RequestsPageActions.approveTask({
              requestId: task.requestId,
              taskId: task.id,
            }));
          },
          disabled: getDisabledStatus(),
        },
        {
          name: "Disapprove",
          onClickHandler: () => {
            setSelectedTask(task);
            dispatch(RequestsPageActions.updateRequestDetailsPageState({
              showDisapproveTaskModal: true,
            }));
          },
          disabled: getDisabledStatus(),
        },
      ];
    }
    if (isStatusApproved(task) && isManualTask(task)) {
      return [{
        name: "Complete",
        onClickHandler: () => {
          dispatch(RequestsPageActions.completeTask({
            requestId: task.requestId,
            taskId: task.id,
          }));
        },
        disabled: getDisabledStatus(),
      }];
    }
    if (isStatusFailed(task) && !isManualTask(task) && isTaskReTriggerFeatureEnabled) {
      return [{
        name: "Retrigger",
        onClickHandler: () => {
          Analytics.trackEventRetriggerTask();
          dispatch(RequestsPageActions.reTriggerTask({
            requestId: task.requestId,
            taskId: task.id,
          }));
        },
        disabled: getDisabledStatus(),
      }];
    }
    return [];
  };

  return {

    selectedTask: selectedTask!,
    showActions,
    tasks,
    getTaskTypeLabel: (taskType: ITask["taskType"]) => taskTypeLabelMap[taskType],
    getActionButtons,
    getResourceAttributeAndValueAsTuple: (resource: Resource) => Object.entries(resource),
    getTaskStatusLabel: (taskStatus: ITask["status"]) => TaskStatusLabel[taskStatus],
    shouldRenderTaskDisapprovalReason: (task: ITask) => isStatusDisapproved(task),
    getTaskDisapprovalReason: (task: ITask) => task.disapprovalReason,
  };
};
