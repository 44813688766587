import React from "react";
import SectionHeader from "../../../review-and-submit/section-header";
import ReviewDetailsList from "../../../review-and-submit/review-details-list";
import ReviewDetails from "../../../review-and-submit/review-details";
import useInfraConfigReviewController from "./controller";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";

const InfrastructureConfigurationReview = createFunctionalComponentFromView(
  useInfraConfigReviewController,
  (controller) => {
    const renderComments = () => {
      if (controller.shouldRenderComments()) {
        return (
          <ReviewDetails
            title="Comments"
            details={controller.infraConfig.comment}
          />
        );
      }
    };

    const renderGCPProjectNames = () => {
      if (controller.isGcpProjectRequested()) {
        return (
          <ReviewDetailsList
            title="GCP project names"
            detailsList={controller.getGcpProjectNames()}
          />
        );
      }
    };

    function renderGithubRepositoryDetails() {
      if (controller.isGithubRepoRequested()) {
        return (
          <>
            <ReviewDetailsList
              title="Repository names"
              detailsList={controller.getGithubRepoNames()}
            />
            <ReviewDetails
              title="CircleCI"
              details={controller.getCircleCiRequestedStatus()}
            />
          </>
        );
      }
    }

    return (
      <div>
        <SectionHeader
          title="Infrastructure Configuration"
          step="INFRA_CONFIG"
        />
        <ReviewDetails
          title="GitHub repositories"
          details={controller.getGithubRepoRequestedStatus()}
        />
        {renderGithubRepositoryDetails()}
        <ReviewDetails
          title="GCP project"
          details={controller.getGcpProjectRequestedStatus()}
        />
        {renderGCPProjectNames()}
        {renderComments()}
      </div>
    );
  },
);

export default InfrastructureConfigurationReview;
