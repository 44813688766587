import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { TeamsPageActions } from "../redux";
import { ITeam } from "../../team-details/redux";
import { convertSpaceToUnderscore } from "../../../utils/string";
import { useHistory } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import Analytics from "../../../common/services/analytics";
import { teamTypeDropDownOptions } from "../../../common/data/team-types";

export default function useAllTeamsController() {
  const dispatch = useDispatch();
  const history = useHistory();
  const allTeamListPEV = useSelectorTyped((state) => state.teamsPage.teamsListPEV);
  const { value: allTeams, progress: allTeamsProgressValue, error: allTeamsErrorValue } = allTeamListPEV;
  const { teamType, searchText, internalOpenSource, status } = useSelectorTyped((state) => state.teamsPage.allTeamsFilters);
  const [filteredTeams, setFilteredTeams] = useState(allTeams);

  useEffect(() => {
    setFilteredTeams(allTeams);
    applyFilter();
  }, [allTeams, teamType, searchText, internalOpenSource, status]);

  const shouldRenderNoAssociatedTeamsMessage = () => allTeams?.length === 0;

  const shouldRenderProgressMessage = () => allTeamsProgressValue !== null && allTeams.length === 0;

  const shouldRenderErrorMessage = () => !!allTeamsErrorValue;

  const getTeamList = () => filteredTeams;

  const getProgressMsg = () => allTeamsProgressValue;

  const getErrorMsg = () => allTeamsErrorValue;

  const shouldRenderFiltersAndCount = () =>
    !shouldRenderNoAssociatedTeamsMessage()
    && !getErrorMsg();

  const shouldRenderExportAllTeamsLink = () =>
    !shouldRenderNoAssociatedTeamsMessage()
    && filteredTeams.length > 0
    && !getErrorMsg();

  const onClickRetryFetchAllTeams = () => {
    dispatch(TeamsPageActions.fetchAllTeams());
  };

  const onTableRowClickedHandler = (row: ITeam, event: MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`/teams/${row.id}/${convertSpaceToUnderscore(row.name)}`, "_blank");
    }
    else {
      history.push(`/teams/${row.id}/${convertSpaceToUnderscore(row.name)}`);
    }
  };

  function applyFilter() {
    let filteredValue = allTeams;
    if (teamType && teamType?.length > 0) {
      filteredValue = allTeams.filter((team) => teamType.includes(team.teamType));
    }
    if (internalOpenSource && internalOpenSource.length === 1) {
      filteredValue = filteredValue.filter((team) => team.isOpen === (internalOpenSource[0] === "Yes"));
    }
    if (status && status.length > 0) {
      filteredValue = filteredValue.filter((team) => team.status && status.includes(team.status));
    }
    const searchTextInUpperCase = searchText.toUpperCase().trim();

    filteredValue = filteredValue.filter(
      (team) => (team.name.toUpperCase().includes(searchTextInUpperCase)
        || team.description.toUpperCase().includes(searchTextInUpperCase)
        || team.contactEmail.toUpperCase().includes(searchTextInUpperCase)
        || team.supportEmail.toUpperCase().includes(searchTextInUpperCase)
      ));
    setFilteredTeams(filteredValue);
  }

  const getTeamsCountMessage = () => `Showing ${filteredTeams.length} of ${allTeams.length} teams`;

  function onChangeTeamTypeFilter(selectedTeamTypes: string[]) {
    Analytics.trackEventFilterTeamsFromAllTeamsTab();
    dispatch(TeamsPageActions.setAllTeamsFilter({ teamType: selectedTeamTypes }));
  }

  function onChangeInternalOpenSourceFilter(selectedValue: string[]) {
    dispatch(TeamsPageActions.setAllTeamsFilter({ internalOpenSource: selectedValue }));
  }

  function onChangeTeamStatusFilter(selectedValue: string[]) {
    dispatch(TeamsPageActions.setAllTeamsFilter({ status: selectedValue }));
  }

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    Analytics.trackEventSearchTeamsFromAllTeamsTab();
    dispatch(TeamsPageActions.setAllTeamsFilter({ searchText: event.target.value }));
  }

  function onClickClearSearchText() {
    dispatch(TeamsPageActions.setAllTeamsFilter({ searchText: "" }));
  }

  function onClickResetButton() {
    dispatch(TeamsPageActions.clearAllTeamsFilter());
  }

  return {
    shouldRenderNoAssociatedTeamsMessage,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    getTeamList,
    getProgressMsg,
    getErrorMsg,
    onClickRetryFetchAllTeams,
    onTableRowClickedHandler,
    getTeamTypeDropdownOptions: () => teamTypeDropDownOptions,
    getInternalOpenSourceDropdownOptions: () => [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    getTeamStatusDropdownOptions: () => [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
    onChangeTeamTypeFilter,
    getSelectedTeamType: () => teamType,
    getTeamsCountMessage,
    searchText,
    onClickClearSearchText,
    onChangeSearchText,
    shouldRenderFiltersAndCount,
    shouldRenderExportAllTeamsLink,
    onChangeInternalOpenSourceFilter,
    getInternalOpenSourceSelectedValue: () => internalOpenSource,
    getTeamStatusSelectedValue: () => status,
    onChangeTeamStatusFilter,
    onClickResetButton,
  };
}
