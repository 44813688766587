import { useSelectorTyped } from "../../redux/store";

type OthersTeamUnAuthorizedMessageProps = {
  errorMessage: string;
};

export default function useOthersTeamUnAuthorizedMessageController(props: OthersTeamUnAuthorizedMessageProps) {
  const uiConfig = useSelectorTyped((state) => state.header.uiConfigsPEV.value);

  return ({
    errorMessage: props.errorMessage,
    neoSupportEmail: uiConfig.emails.neoSupportEmail,
    regionalItLeadsMasterDataLink: uiConfig.internalLinks.regionalItLeadsMasterDataLink,
  });
}
