import { IBasicInfo } from "../experiments-and-innovations/redux";

export const processOtherTextForProjectCategory = (formData: Partial<IBasicInfo>): string[] => {
  let projectCategories: string[] = [];
  if (Array.isArray(formData.projectCategory)) {
    projectCategories = [...formData.projectCategory];
    const otherIndex = formData.projectCategory.indexOf("other");
    if (otherIndex >= 0) {
      projectCategories[otherIndex] = formData["projectCategory-Comment"] as string;
    }
  }
  return projectCategories;
};

export const processOtherTextForTechStack = (formData: Partial<IBasicInfo>): string[] => {
  let techStack: string[] = [];
  if (Array.isArray(formData.techStack)) {
    techStack = [...formData.techStack];
    const otherIndex = formData.techStack.indexOf("other");
    if (otherIndex >= 0) {
      techStack[otherIndex] = formData["techStack-Comment"] as string;
    }
  }
  return techStack;
};
