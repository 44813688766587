import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import ResourceTable from "./resource-table";
import useTasksController, { TasksProps } from "./controller";
import { RequestDetailField } from "../fields";

const Tasks = createFunctionalComponentFromView<ReturnType<typeof useTasksController>, TasksProps>(
  useTasksController,
  (controller) => {
    function renderTasksHeader() {
      return (
        <div className="resource-details">
          <div>
            <RequestDetailField
              fieldName="Last Updated"
              value={controller.getLastUpdated()}
            />
          </div>
          <div>
            <RequestDetailField
              fieldName="ETA"
              value={controller.getETA()}
            />
          </div>
        </div>
      );
    }

    function renderTasks() {
      if (controller.shouldRenderTaskDetails()) {
        return (
          <div>
            {renderTasksHeader()}
            <ResourceTable
              tasks={controller.requestDetails.tasks}
              showActions={controller.showActions}
            />
          </div>
        );
      }
      else {
        return (
          <div>
            We are processing your request. Please check after sometime.
          </div>
        );
      }
    }

    return (
      <div className="Tasks">
        {renderTasks()}
      </div>
    );
  },
);

export default Tasks;
