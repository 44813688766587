import { ApplicationDetailsPageActions } from "../redux";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";

export type Props = {
  name: string;
};

export type IApplicationNameController = ReturnType<typeof useApplicationNameController>;

export function useApplicationNameController(props: Props) {
  const dispatch = useDispatch();
  const isApplicationNameInEditMode = useSelectorTyped((state) => state.applicationDetailsPage.isApplicationNameInEditMode);

  const applicationBasicInfo = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const isUserAuthorizedToEditApplicationName = applicationBasicInfo.isEditable;

  const onClickEditButton = () => {
    dispatch(ApplicationDetailsPageActions.updateApplicationNameEditState(true));
  };

  return {
    ...props,
    onClickEditButton,
    isApplicationNameInEditMode,
    isUserAuthorizedToEditApplicationName,
  };
}
