import { toast } from "react-toastify";
import { ICreateTeam } from "./redux";
import { callBffApi } from "../../common/services/axios";
import { ITeam, ITeamProject } from "../team-details/redux";

export const createTeam = async (teamInformation: ICreateTeam): Promise<ITeam> => {
  const teamNameAlreadyExistError = "Team name already exists";

  function getErrorMessageForToast(e: Error) {
    return e.message === teamNameAlreadyExistError
      ? teamNameAlreadyExistError
      : `Error occurred while creating team! Please try again. ${(e).message}`;
  }

  try {
    const result = await callBffApi<ITeam>("post", "/teams", teamInformation);
    toast.success("Team created successfully");
    return result;
  }
  catch (error) {
    toast.error(getErrorMessageForToast(error as Error));
    throw error;
  }
};

export const getProjects = async (query?: {
  isEmployeeIdsNotRequired: boolean;
}): Promise<ITeamProject[]> => {
  const result = await callBffApi<{ data: ITeamProject[] }>(
    "get",
    "/teams/projects",
    undefined,
    query,
  );
  return result.data;
};
