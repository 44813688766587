import React, { FunctionComponent } from "react";
import "./review-and-submit.scss";

type Props = {
  title: string;
  detailsList: string[];
};

const isDetailsAvailable = (details: string[]): boolean => (!!details && details.length > 0);

const renderDetailsList = (detailsList: string[]) => (
  isDetailsAvailable(detailsList)
    ? detailsList.map((detail) => (
      <div
        className="content-item"
        key={detail}
      >
        {detail}
      </div>
    ))
    : "---"
);

const ReviewDetailsList: FunctionComponent<Props> = (props: Props) => (
  <div>
    <div className="details-title">{props.title}</div>
    <div className="details">
      {renderDetailsList(props.detailsList)}
    </div>
  </div>
);

export default ReviewDetailsList;
