import React from "react";
import { Nav, Tab } from "react-bootstrap";
import "./index.scss";
import useApplicationDetailsController, { ApplicationDetailsProps, ApplicationTabKey, TabInfo } from "./controller";
import ApplicationInformation from "./application-information";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import ContainerBox from "../../../common/components/container-box";
import ApplicationDetailsPageTitle from "./application-details-page-title";
import { EditApplicationInformation } from "./edit-application-information";
import BackButton from "../../../common/components/back-button";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import ManageInfrastructure from "./manage-infrastructure";
import { ApplicationActivitiesTable } from "./application-activities";
import AdditionalSupport from "./additional-support";

const ApplicationDetails = createFunctionalComponentFromView<ReturnType<typeof useApplicationDetailsController>, ApplicationDetailsProps>(
  useApplicationDetailsController,
  (controller) => {
    function renderTabLabel(tabInfo: TabInfo) {
      return (
        <Nav.Item>
          <Nav.Link
            as="span"
            eventKey={tabInfo.key}
          >
            <div className="nav-text">
              {tabInfo.text}
            </div>
            <div className="nav-text hidden-tab-text">
              {tabInfo.text}
            </div>
          </Nav.Link>
        </Nav.Item>
      );
    }

    const renderTabs = () => controller.tabs.map((tab, index) => {
      if (tab.isVisible) {
        return (
          <>
            {renderTabLabel(tab)}
            {index + 1 < controller.tabs.length
            && (
              <hr
                ix-styles="vertical"
                style={{ width: "2px" }}
              />
            )}
          </>
        );
      }
    });

    const renderApplicationInformationTabContent = () => {
      if (controller.shouldRenderApplicationEditPage()) {
        return (<EditApplicationInformation />);
      }
      return (
        <ApplicationInformation
          isUserWithEditPrivilege={controller.canUserEditApplicationDetail()}

          application={controller.applicationDetails!}
        />
      );
    };

    const renderManageInfraTabContent = () => {
      if (controller.shouldRenderManageInfraTab()) {
        return <ManageInfrastructure />;
      }
    };

    const renderActivityContent = () => <ApplicationActivitiesTable />;

    function renderAdditionalSupport() {
      if (controller.shouldRenderAdditionalSupportTab()) {
        return <AdditionalSupport />;
      }
    }

    const renderTabsAndContent = () => (
      <Tab.Container
        onSelect={(tab) => tab ? controller.setActiveTab(tab as ApplicationTabKey) : {}}
        activeKey={controller.activeTab}
      >
        <Nav>
          {renderTabs()}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey={ApplicationTabKey.APPLICATION_INFO}>
            {renderApplicationInformationTabContent()}
          </Tab.Pane>
          <Tab.Pane eventKey={ApplicationTabKey.MANAGE_INFRASTRUCTURE}>
            {renderManageInfraTabContent()}
          </Tab.Pane>
          <Tab.Pane eventKey={ApplicationTabKey.ACTIVITY_HISTORY}>
            {renderActivityContent()}
          </Tab.Pane>
          <Tab.Pane eventKey={ApplicationTabKey.ADDITIONAL_SUPPORT}>
            {renderAdditionalSupport()}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );

    const renderLifeSpanProgressBar = () => {
      if (controller.shouldRenderApplicationLifespan()) {
        return (
          <>
            <div className="life-span-progress">
              <progress
                id="lifespan"
                className={controller.hasApplicationLifeSpanReachedThreshold() ? "alert-progress" : ""}
                value={controller.getRemainingLifeSpanDays()}
                max={controller.getTotalLifeSpanDays()}
              >
                {controller.getRemainingLifeSpanDays()}
              </progress>
              <label htmlFor="lifespan">{controller.getLifespanLabelText()}</label>
            </div>
          </>
        );
      }
    };

    const renderContent = () => {
      if (controller.shouldRenderApplicationInfo()) {
        return (
          <>
            <ApplicationDetailsPageTitle />
            {renderLifeSpanProgressBar()}
            <hr />
            {renderTabsAndContent()}
          </>
        );
      }
      return null;
    };

    const renderProgress = () => <Progress text={controller.getProgressText()} />;

    const renderError = () => {
      if (controller.shouldRenderError()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorInfo()!)}
            onClick={controller.onRetryButtonClick}
          />
        );
      }
    };

    return (
      <div className="ApplicationDetails">
        <ContainerBox
          componentToRenderInEmptySpace={
            <BackButton onClickHandler={controller.onClickBackButton} />
          }
          isDisplayingBackButton={true}
        >
          {renderContent()}
          {renderProgress()}
          {renderError()}
        </ContainerBox>
      </div>
    );
  },
);

export default ApplicationDetails;

// TODO move into Catalog folder. Delete the rest
