import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { CurrentStep, GetStartedPageActions } from "../redux";
import { IProps } from ".";

export default function useNavigationButtonsController(props: IProps) {
  const dispatch = useDispatch();
  const currentStep = useSelectorTyped((state) => state.getStartedPage.currentStep);

  function getNextStep(): CurrentStep {
    switch (currentStep) {
    case "PROJECT_CLASSIFICATION":
      return "TEAM_SELECTION";
    case "TEAM_SELECTION":
      return "BASIC_INFO";
    case "BASIC_INFO":
      return "INFRA_CONFIG";
    case "INFRA_CONFIG":
      return "REVIEW_SUBMIT";
    case "REVIEW_SUBMIT":
      return "REVIEW_SUBMIT";
    default:
      return currentStep;
    }
  }

  function getPreviousStep(): CurrentStep {
    switch (currentStep) {
    case "TEAM_SELECTION":
      return "PROJECT_CLASSIFICATION";
    case "BASIC_INFO":
      return "TEAM_SELECTION";
    case "INFRA_CONFIG":
      return "BASIC_INFO";
    case "REVIEW_SUBMIT":
      return "INFRA_CONFIG";
    default:
      return currentStep;
    }
  }

  function onClickNext() {
    if (props.shouldGoToNextStep(props.getFormData())) {
      const nextStep = getNextStep();
      dispatch(GetStartedPageActions.updateCurrentStep(nextStep));
    }
  }

  function onClickPrevious() {
    if (props.shouldGoToPreviousStep(props.getFormData())) {
      const previousStep = getPreviousStep();
      dispatch(GetStartedPageActions.updateCurrentStep(previousStep));
    }
  }

  return {
    onClickNext,
    onClickPrevious,
    doesFormHasErrors: props.doesFormHasErrors,
    getFormData: props.getFormData,
  };
}
