import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITeam } from "../team-details/redux";
import * as service from "./service";
import { PEV, ProgressErrorValue } from "../../utils/progress-error-value";
import { Dispatch } from "react";
import { INeoErrorInfo, NeoError } from "../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../../common/redux/createAsyncThunkWithRejectValue";
import { Nullable } from "../../utils/nullable";

export enum TEAMS_TAB_EVENT_KEY {
  MY_TEAMS = "my-teams-tab",
  ALL_TEAMS = "all-teams-tab",
}

export interface IPotentialMember {
  employeeId: string;
  emailId: string;
  firstName: string;
  lastName: string;
  thoughtworksRole?: string;
  departmentName: string;
  isGITSMember: boolean;
  isOthersMember: boolean;
}

export interface ITeamFilters {
  searchText: string;
  teamType: string[];
  internalOpenSource: string[];
  status: string[];
}

interface ITeamsPage {
  myTeamListPEV: ProgressErrorValue<ITeam[], Nullable<string>, Nullable<INeoErrorInfo>>;
  potentialMembersPEV: ProgressErrorValue<IPotentialMember[], Nullable<string>, Nullable<INeoErrorInfo>>;
  showChooseTeamTypeForTeamCreationModal: boolean;
  teamsListPEV: ProgressErrorValue<ITeam[], Nullable<string>, Nullable<INeoErrorInfo>>;
  myTeamsFilters: ITeamFilters;
  allTeamsFilters: ITeamFilters;
  activeTab: TEAMS_TAB_EVENT_KEY;
}

const initialState: ITeamsPage = {
  myTeamListPEV: PEV([], null, null),
  potentialMembersPEV: PEV([]),
  showChooseTeamTypeForTeamCreationModal: false,
  teamsListPEV: PEV([], null, null),
  myTeamsFilters: {
    searchText: "",
    teamType: [],
    internalOpenSource: [],
    status: ["ACTIVE"],
  },
  allTeamsFilters: {
    searchText: "",
    teamType: [],
    internalOpenSource: [],
    status: ["ACTIVE"],
  },
  activeTab: TEAMS_TAB_EVENT_KEY.MY_TEAMS,
};

const fetchMyTeams = createAsyncThunkWithRejectValue<ITeam[]>(
  "/my-teams",
  async () => service.fetchMyTeams(),
);

const fetchAllPotentialMembers = createAsyncThunkWithRejectValue(
  "/teams/potential-members",
  async (_arg) => service.fetchAllPotentialMembers(),
);

const fetchAllTeams = createAsyncThunkWithRejectValue<ITeam[]>(
  "/teams",
  async () => service.fetchAllTeams(),
);

export const TeamsPageSlice = createSlice({
  name: "myTeamsPage",
  initialState,
  reducers: {
    updateTeamsPageState: (state: ITeamsPage, action: PayloadAction<Partial<ITeamsPage>>) => ({
      ...state,
      ...action.payload,
    }),
    applyMyTeamsFilters: (state: ITeamsPage, action: PayloadAction<Partial<ITeamFilters>>) => {
      state.myTeamsFilters = {
        ...state.myTeamsFilters,
        ...action.payload,
      };
    },
    applyAllTeamsFilters: (state: ITeamsPage, action: PayloadAction<Partial<ITeamFilters>>) => {
      state.allTeamsFilters = {
        ...state.allTeamsFilters,
        ...action.payload,
      };
    },
    setActiveTab: (state: ITeamsPage, action: PayloadAction<TEAMS_TAB_EVENT_KEY>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: {
    [fetchMyTeams.pending.type]: (state) => {
      state.myTeamListPEV = PEV(state.myTeamListPEV.value, "Fetching your teams");
    },
    [fetchMyTeams.fulfilled.type]: (state, action: PayloadAction<ITeam[]>) => {
      state.myTeamListPEV = PEV(action.payload);
    },
    [fetchMyTeams.rejected.type]: (state: ITeamsPage, action: PayloadAction<NeoError>) => {
      state.myTeamListPEV = PEV([], null, action.payload.getErrorInfo());
    },
    [fetchAllPotentialMembers.pending.type]: (state) => {
      state.potentialMembersPEV = PEV([], "Fetching existing users");
    },
    [fetchAllPotentialMembers.fulfilled.type]: (state: ITeamsPage, action: PayloadAction<IPotentialMember[]>) => {
      state.potentialMembersPEV = PEV(action.payload);
    },
    [fetchAllPotentialMembers.rejected.type]: (state: ITeamsPage, action: PayloadAction<NeoError>) => {
      state.potentialMembersPEV = PEV([], null, action.payload.getErrorInfo());
    },
    [fetchAllTeams.pending.type]: (state) => {
      state.teamsListPEV = PEV(state.teamsListPEV.value, "Fetching all teams");
    },
    [fetchAllTeams.fulfilled.type]: (state: ITeamsPage, action: PayloadAction<ITeam[]>) => {
      state.teamsListPEV = PEV(action.payload);
    },
    [fetchAllTeams.rejected.type]: (state: ITeamsPage, action: PayloadAction<NeoError>) => {
      state.teamsListPEV = PEV([], null, action.payload.getErrorInfo());
    },
  },
});

export const teamsPageReducer = TeamsPageSlice.reducer;

export const dispatchFetchAllPotentialMembersIfEmpty = (
  dispatch: Dispatch<unknown>,
  potentialMembersPEV: ProgressErrorValue<IPotentialMember[]>,
) => {
  if (potentialMembersPEV.value.length === 0 && !potentialMembersPEV.progress && !potentialMembersPEV.error) {
    dispatch(fetchAllPotentialMembers());
  }
};

const updateTeamPageState: ActionCreatorWithPayload<Partial<ITeamsPage>>
    = TeamsPageSlice.actions.updateTeamsPageState;

const updateShowChooseTeamTypeForTeamCreationModal = (showModal: boolean) => updateTeamPageState({
  showChooseTeamTypeForTeamCreationModal: showModal,
});

function setMyTeamsFilter(filter: Partial<ITeamFilters>) {
  return TeamsPageSlice.actions.applyMyTeamsFilters(filter);
}

function setAllTeamsFilter(filter: Partial<ITeamFilters>) {
  return TeamsPageSlice.actions.applyAllTeamsFilters(filter);
}

function setActiveTab(tab: TEAMS_TAB_EVENT_KEY) {
  return TeamsPageSlice.actions.setActiveTab(tab);
}

function clearMyTeamsFilter() {
  const { searchText: _, ...initialFilterValues } = initialState.myTeamsFilters;
  return TeamsPageSlice.actions.applyMyTeamsFilters(initialFilterValues);
}

function clearAllTeamsFilter() {
  const { searchText: _, ...initialFilterValues } = initialState.allTeamsFilters;
  return TeamsPageSlice.actions.applyAllTeamsFilters(initialFilterValues);
}

export const TeamsPageActions = {
  updateShowChooseTeamTypeForTeamCreationModal,
  fetchMyTeams,
  fetchAllPotentialMembers,
  fetchAllTeams,
  setMyTeamsFilter,
  setAllTeamsFilter,
  setActiveTab,
  clearMyTeamsFilter,
  clearAllTeamsFilter,
};
