import React, { FunctionComponent, ReactNode } from "react";
import "./details.scss";

type Props = {
  fieldName: string;
  value?: string;
  children?: ReactNode;
  className?: string;
};

const getDetailsValue = (props: Props) => {
  if (props.value) {
    return props.value;
  }
  else if (props.children) {
    return props.children;
  }
  else {
    return "---";
  }
};

const Details: FunctionComponent<Props> = (props: Props) => (
  <div
    className={`Details ${props.className || ""}`}
    key={props.fieldName}
  >
    <div className="info-title">
      {props.fieldName}
    </div>
    <div className="info-value">{getDetailsValue(props)}</div>
  </div>
);

export default Details;
