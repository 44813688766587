import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { useGetFeatureToggleState } from "../../../../common/hooks/feature-toggles";
import { ITeamProject, TeamDetailsPageActions } from "../../redux";
import castTo from "../../../../utils/cast-to";
import { getAssociateProjectQuestions } from "./data/questions";
import Analytics from "../../../../common/services/analytics";

export default function useProjectSelectionController() {
  const teamDetails = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV.value);
  const showProjectSelection = useSelectorTyped((state) => state.teamDetailsPage.showProjectSelectionForm);
  const projectsPEV = useSelectorTyped((state) => state.teamCreationPage.projectsPEV);
  const associateProjectsPES = useSelectorTyped((state) => state.teamDetailsPage.associateProjectsPES);
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const [showAssociateProjectsModal, setShowAssociateProjectsModal] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState(castTo<ITeamProject[]>([]));
  const dispatch = useDispatch();

  useEffect(() => {
    if (associateProjectsPES.success) {
      setShowAssociateProjectsModal(false);
    }
  }, [associateProjectsPES]);

  const onClickCancel = () => {
    dispatch(TeamDetailsPageActions.displayProjectSelection(false));
  };

  const isAssociateProjectsInProgress = !!associateProjectsPES.progress;

  const onCancel = () => setShowAssociateProjectsModal(false);

  const onClickModalAssociateButton = () => {
    Analytics.trackEventAssociateProject();
    dispatch(TeamDetailsPageActions.associateProjectsToTeam({

      teamId: teamDetails!.id,
      projectIds: selectedProjects.map((project) => project.id),
    }));
  };

  const onClickAssociateButton = (formData: Record<string, string[]>) => {
    setSelectedProjects(projectsPEV.value.filter((project) => formData.projectName.includes(project.name)));
    setShowAssociateProjectsModal(true);
  };

  return ({
    title: "Select project",
    showAssociateProjectsModal,
    teamDetails,
    projectAssociationToggleEnabled,
    showProjectSelection,
    isAssociateProjectsInProgress,
    questions: getAssociateProjectQuestions(projectsPEV.value.filter((project) =>
      !teamDetails?.projects?.some((associatedProject) => associatedProject.id === project.id)),
    ),
    onClickCancel,
    onClickAssociateButton,
    onCancel,
    onClickModalAssociateButton,
    getSelectedProjects: () => selectedProjects.map((project) => project.name).join(", "),
    getPrimaryButtonLabel: isAssociateProjectsInProgress ? "Associating" : "Associate",
  });
}
