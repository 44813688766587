import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FeatureToggleActions } from "../../redux/slices/feature-toggles";
import { useSelectorTyped } from "../../redux/store";
import { getEnvVars } from "../../config/env";
import { dispatchFetchPurposesIfEmpty } from "../../redux/slices/application-purpose";
import { useAuthUserInfo } from "../authentication/hooks";
import { headerActions } from "../header/redux";
import Analytics from "../../services/analytics";
import { useLocation } from "react-router-dom";
import { hasOktaTokenExpired } from "../authentication/data";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";

export default function useAppController() {
  const dispatch = useDispatch();
  const { microFrontends } = getEnvVars();
  const { getUser } = useAuthUserInfo();
  const { pathname } = useLocation();
  const userNamePEV = useSelectorTyped((state) => state.header.userInfo.name);
  const userEmailPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const additionalUserInfoPEV = useSelectorTyped((state) => state.header.userInfo.additionalInfo);
  const applicationPurposesPEV = useSelectorTyped((state) => state.applicationPurposesPEV);
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const isDisableRoutesToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification_DisableRoutes");
  const { unificationConfig, env } = getEnvVars();

  useEffect(() => {
    if (additionalUserInfoPEV.value) {
      if (additionalUserInfoPEV.value.shouldTrackAnalytics) {
        Analytics.enableTracking(additionalUserInfoPEV.value);
      }
      else {
        Analytics.disableTracking();
      }
    }
  }, [additionalUserInfoPEV.value]);

  useEffect(function scrollWindowToTop() {
    if (!pathname.startsWith("/events")) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(function onComponentMount() {
    window.addEventListener("focus", onWindowFocus);
    dispatch(FeatureToggleActions.fetchFeatureToggles());
    dispatchFetchPurposesIfEmpty(dispatch, applicationPurposesPEV);
    dispatch(headerActions.fetchUserInfo(getUser));
    dispatch(headerActions.fetchAdditionalUserInfo());
    dispatch(headerActions.fetchUiConfigs());

    return function onComponentUnmount() {
      window.removeEventListener("focus", onWindowFocus);
    };
  }, []);

  const onWindowFocus = () => {
    if (!hasOktaTokenExpired()) {
      dispatch(FeatureToggleActions.fetchFeatureToggles());
    }
  };

  const getProgressMsg = () => userEmailPEV.progress || userNamePEV.progress;
  const getErrorMsg = () => userEmailPEV.error || userNamePEV.error;

  const shouldRenderProgress = () => !!userEmailPEV.progress || !!userNamePEV.progress;
  const shouldRenderError = () => !!userEmailPEV.error || !!userNamePEV.error;

  return {
    microFrontends,
    isApiUnificationToggleEnabled,
    env,
    isDisableRoutesToggleEnabled,
    getProgressMsg,
    getErrorMsg,
    shouldRenderProgress,
    shouldRenderError,
    environmentConfig: { ...unificationConfig.environmentSpecificConfig },
  };
}
