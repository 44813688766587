import React, { FunctionComponent } from "react";
import ContainerBox from "../../common/components/container-box";
import useCreateTeamController from "./controller";
import PageTitle from "../../common/components/page-title";
import { CreateTeamForm } from "../../common/components/create-team";

export const CreateTeam: FunctionComponent = () => {
  const controller = useCreateTeamController();

  const renderForm = () => {
    if (controller.shouldRenderCreateTeamForm()) {
      return (
        <CreateTeamForm
          onCancel={controller.onCreateTeamCancel}
          teamType={controller.selectedTeamType}
        />
      );
    }
  };

  return (
    <div className="CreateTeam">
      <ContainerBox>
        <PageTitle title={controller.getPageTitle()} />
        {renderForm()}
      </ContainerBox>
    </div>
  );
};
