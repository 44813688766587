import React from "react";
import { Col, Row } from "react-bootstrap";
import useMyAppsAndServicesController from "./controller";
import "./index.scss";
import ContainerBox from "../../../common/components/container-box";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import ApplicationCard from "../../../common/components/application-card";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import Analytics from "../../../common/services/analytics";
import MyApplicationSearch from "./search";
import { ExportCSVLink } from "../../../common/components/export-csv";
import MyApplicationFilters from "./filters";

const MyAppsAndServices = createFunctionalComponentFromView(useMyAppsAndServicesController, (controller) => {
  const renderMyFilteredApplicationsDetails = () => {
    if (controller.shouldRenderNoRecordsToDisplayMessage()) {
      return (
        <span>No records to display.</span>
      );
    }
    return (
      <Row className="filtered-my-applications">
        {
          controller.filteredMyApplications.map((application, index) => (
            <Col
              className="application-column"
              key={index}
              xs={4}
              md={4}
            >
              <ApplicationCard
                application={application}
                onClick={() => Analytics.trackEventClickOnApplicationCardFromMyAppsAndServicesPage(application.name)}
              />
            </Col>
          ))
        }
      </Row>
    );
  };

  const renderExportCsvLink = () => {
    if (controller.shouldRenderExportCsvLink()) {
      return (
        <div className="export-my-applications">
          <ExportCSVLink
            data={controller.getCSVDataForDownload()}
            filename={controller.getCSVFileName()}
            onClick={() => Analytics.trackEventExportMyApplicationsToCSVFromMyAppsAndServicesPage()}
          />
        </div>
      );
    }
  };

  const renderMyApplicationsList = () => {
    if (controller.shouldRenderProgressMessage()) {
      return <Progress text={controller.fetchMyApplicationsProgress} />;
    }
    if (controller.shouldRenderError()) {
      return (
        <Error

          {...mapApplicationErrorInfoToErrorComponentProps(controller.fetchMyApplicationsError!)}
          onClick={controller.onClickRetryButton}
        />
      );
    }
    if (controller.shouldRenderNoApplicationsMessage()) {
      return (
        <span>
          No applications associated to your teams. Click
          {" "}
          <b>Get Started</b>
          {" "}
          option to create a new application and request for infrastructure.
        </span>
      );
    }
    return (
      <>
        <div className="application-count-and-export-csv">
          {renderFilteredAppsAndServicesCount()}
          {renderExportCsvLink()}
        </div>
        {renderMyFilteredApplicationsDetails()}
      </>
    );
  };

  const renderFilteredAppsAndServicesCount = () => (
    <div className="my-apps-and-services-count">
      Showing
      {" "}
      {controller.filteredAppsAndServicesCount}
      {" "}
      results out of
      {" "}
      {controller.totalAppsAndServicesCount}
    </div>
  );

  const renderMyApplications = () => (
    <div className="my-applications-content">
      <div className="search-and-filters">
        <MyApplicationSearch />
        <MyApplicationFilters />
      </div>
      <div className="applications-list">
        {renderMyApplicationsList()}
      </div>
    </div>
  );
  return (
    <div className="MyAppsAndServices">
      <ContainerBox>
        <div className="my-applications">
          <h4 className="my-applications-title">My applications</h4>
          {renderMyApplications()}
        </div>
      </ContainerBox>
    </div>
  );
});

export default MyAppsAndServices;
