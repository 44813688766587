import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { eventDetailsPageActions } from "./redux";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";
import { useSelectorTyped } from "../../../common/redux/store";

interface UrlParams {
  namespaceName: string;
  eventName: string;
}

export default function useEventDetailsController() {
  const { namespaceName, eventName } = useParams<UrlParams>();
  const eventDetailsPEV = useSelectorTyped((state) => state.events.eventDetailsPage.eventDetailsPEV);
  const eventDetails = eventDetailsPEV.value;
  const eventDetailsProgress = eventDetailsPEV.progress;
  const eventDetailsError = eventDetailsPEV.error;
  const teamEmail = eventDetails?.teamEmail;
  const teamId = eventDetails?.team?.id;
  const teamName = eventDetails?.team?.name;
  const eventDescription = eventDetails?.description;
  const eventSchema: Record<string, unknown> = eventDetails?.schema || {};
  const eventTags = eventDetails?.tags;
  const eventCategory = eventDetails?.category;
  const isEventTagsEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Tags");
  const isTeamLinkEnabled = useGetFeatureToggleState("Events_EventsDetail_TeamLink");

  const history = useHistory();

  const shouldRenderEventDetails = () => (!eventDetailsProgress && !eventDetailsError);

  const isNotFoundError = eventDetailsError && (eventDetailsError as unknown as string)?.includes("not found");
  const isOverviewFeatureEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Overview");

  const onClickBackButton = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (isOverviewFeatureEnabled) {
      history.push("/events/discovery");
    }
    else {
      history.push("/events");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(eventDetailsPageActions.fetchEventDetails({ namespaceName, eventName }));
  }, []);

  return ({
    eventName,
    eventDescription,
    eventSchema,
    namespaceName,
    teamEmail,
    teamId,
    teamName,
    eventDetailsProgress,
    eventDetailsError,
    isNotFoundError,
    eventTags,
    eventCategory,
    shouldRenderEventDetails,
    onClickBackButton,
    shouldShowEventTags: () => isEventTagsEnabled,
    isTeamLinkEnabled,
  });
}
