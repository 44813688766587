import React from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import useTeamMembersTableController from "./controller";
import "./index.scss";
import { ITeamMember } from "../redux";
import { capitalizeFirstLetter } from "../../../utils/string";
import ImpactXButton from "../../../common/components/impactx-button";
import AddTeamMembersForm from "../add-members";
import Progress from "../../../common/components/progress";
import JoinTeamModal from "../../../common/components/join-team-modal";
import { HighlightValueAsNonGITS } from "../../../common/components/highlight-value-as-non-gits";
import CheckBox from "../../../common/components/check-box";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import AlertIcon from "../../../common/components/neo-modal-dialog/alert-icon";
import TableSortIcon from "../../../common/components/data-table-sort-icon";
import UserActions from "./user-actions";
import { identity } from "../../../utils/cast-to";
import { ExitTeamButton } from "../../../common/components/exit-team-button-with-modal";
import NotPartOfGITSLegend from "../../../common/components/not-part-of-gits-legend";
import { ExportCSVLink } from "../../../common/components/export-csv";
import Analytics from "../../../common/services/analytics";
import ToolTipWrapped from "../../../common/components/tooltip";
import SearchBar from "../../../common/components/search-bar";

// TODO rename to TeamMembersSection or something similar
const TeamMembersTable = createFunctionalComponentFromView(useTeamMembersTableController, (controller) => {
  const compareMembers = (member1: ITeamMember, member2: ITeamMember, stringifyFn: (member: ITeamMember) => string) =>
    stringifyFn(member1).toLowerCase().localeCompare(stringifyFn(member2).toLowerCase());

  const getMemberTypeTitle = () => (
    <div>
      Member Type
      <ToolTipWrapped
        id="information-icon-tooltip"
        content={renderMembersTypeCount()}
        placement="right-start"
        className="-members-type-count-info"
      >
        <i className="ri-information-line" />
      </ToolTipWrapped>
    </div>
  );
  const getColumns = () => identity<IDataTableColumn[]>([
    {
      name: "Name",
      selector: "name",

      cell: (member: ITeamMember) => (
        <div className="member-name-and-email">
          <div className="member-name">{`${member.firstName} ${member.lastName}`}</div>
          <div className="member-email">{member.emailId}</div>
        </div>
      ),
      grow: 2.7,
      sortable: true,
      sortFunction: (member1: ITeamMember, member2: ITeamMember) =>
        compareMembers(member1, member2, (member) => `${member.firstName} ${member.lastName} ${member.emailId}`),
    },
    {
      name: "Home Office",
      selector: "homeOffice",
      grow: 2.5,

      cell: (member: ITeamMember) => (
        <div className="member-home-office">{`${member.country} - ${member.homeOffice}`}</div>
      ),
      sortable: true,
      sortFunction: (member1: ITeamMember, member2: ITeamMember) =>
        compareMembers(member1, member2, (member) => `${member.country} - ${member.homeOffice}`),
    },
    {
      name: getMemberTypeTitle(),
      selector: "role",
      cell: (member: ITeamMember) => capitalizeFirstLetter(member.role),
      grow: 1.8,
      sortable: true,
      sortFunction: (member1: ITeamMember, member2: ITeamMember) =>
        member1.role.localeCompare(member2.role),
    },
    {
      name: "Department",
      selector: "departmentName",
      cell: (member: ITeamMember) => HighlightValueAsNonGITS({
        value: capitalizeFirstLetter(member.departmentName),
        shouldDifferentiateNonGITSMembers: controller.shouldDifferentiateNonGITSDepartment(member),
      }),
      grow: 2,
      sortable: true,
      sortFunction: (member1: ITeamMember, member2: ITeamMember) =>
        member1.departmentName.localeCompare(member2.departmentName),
    },
    {
      name: "Associated Project",
      selector: "associatedProject",
      grow: 2,
      cell: (row: ITeamMember) => renderProjectAssociation(row.associatedProjects),
      sortable: true,
      omit: !controller.showProjectAssociation(),
      sortFunction: (member1: ITeamMember, member2: ITeamMember) =>
        compareMembers(member1, member2, (member) => member.associatedProjects.map((project) => project.name).join(" ")),
    },
    {
      name: "Actions",

      cell: (member: ITeamMember) => (
        <UserActions
          userInfo={member}
          teamId={controller.teamDetails.id}
        />
      ),
      omit: !controller.canManageMembers(),
      grow: 0.4,
    },
  ]);

  function renderMembersTypeCount() {
    const memberTypeCounts = controller.getMemberTypeCounts();
    return (
      <div className="members-type-count">
        <div>
          Managers (
          {memberTypeCounts.manager}
          )
        </div>
        <div>
          Developers (
          {memberTypeCounts.developer}
          )
        </div>
        <div>
          Non-Developers (
          {memberTypeCounts["non-developer"]}
          )
        </div>
      </div>
    );
  }

  function renderProjectAssociation(projects: ITeamMember["associatedProjects"]) {
    if (projects === null || projects?.reduce((length, project) => project ? length + 1 : length, 0) === 0) {
      return "-";
    }
    return (
      <div>
        {projects?.map((project, index) => (
          <div
            key={index}
            data-tag="allowRowEvents"
            className="project-name"
          >
            {project.name}
          </div>
        ))}
      </div>
    );
  }

  function renderProgress() {
    return (
      <div className="update-member-progress-bar">
        <Progress text="Updating members" />
      </div>
    );
  }

  function renderTeamMembersTable() {
    return (
      <DataTable
        className="TeamMembersTable"
        columns={getColumns()}
        data={controller.filteredMembers}
        noHeader={true}
        sortFunction={controller.initialSortTeamMembersBasedOnRoleAndName}
        sortIcon={<TableSortIcon />}
        persistTableHead={true}
        pointerOnHover={false}
        progressPending={controller.shouldRenderUpdateMemberProgress()}
        progressComponent={renderProgress()}
        selectableRows={controller.shouldShowMultiSelectOption()}
        onSelectedRowsChange={controller.handleSelectedRowsChange}
        selectableRowsComponent={CheckBox}
        selectableRowsVisibleOnly={true}
        clearSelectedRows={controller.toggleClearSelectedMembers}
        pagination={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
    );
  }

  function renderJoinTeamButton() {
    if (controller.shouldRenderJoinTeamButton()) {
      return (
        <>
          <ImpactXButton
            label="Join Team"
            disabled={controller.isJoinTeamButtonDisabled()}
            onClickHandler={controller.onClickJoinTeamButton}
          />
          <JoinTeamModal
            teamName={controller.teamDetails.name}
            onCloseModal={controller.onCloseJoinTeamModel}
            isJoinTeamInProgress={controller.isJoinTeamInProgress}
            show={controller.showJoinTeamModal}
            onClickJoinButton={controller.onClickJoinTeamButtonInModel}
            pageTitle="Team Details"
          />
        </>
      );
    }
    return null;
  }

  function renderExitTeamButton() {
    if (controller.shouldRenderExitTeamButton()) {
      return (
        <>
          <ExitTeamButton
            team={controller.teamDetails}
            onClickConfirmExitButton={controller.onClickConfirmExitTeam}
            exitTeamPE={controller.exitTeamPE}
            loggedInUserEmail={controller.loggedInUserEmail}
            pageTitle="Team Details"
          />
        </>
      );
    }
    return null;
  }

  function renderAddMembersButton() {
    if (controller.canManageMembers()) {
      return (
        <ImpactXButton
          label="Add Members"
          disabled={controller.isAddMembersButtonDisabled()}
          onClickHandler={controller.onClickAddMembersButton}
        />
      );
    }
    return null;
  }

  function renderRemoveMembersButton() {
    if (controller.shouldRenderRemoveMembersButton()) {
      return (
        <ImpactXButton
          label="Remove Members"
          disabled={controller.isRemoveMembersButtonDisabled()}
          onClickHandler={controller.onClickRemoveMembersButton}
        />
      );
    }
    return null;
  }

  function renderNotPartOfGITSLegend() {
    if (controller.shouldRenderNonGITSLegend) {
      return <NotPartOfGITSLegend />;
    }
  }

  function renderProjectAssociationLegend() {
    if (controller.showProjectAssociation()) {
      return (
        <div className="project-association-legend">
          - Member manually managed
        </div>
      );
    }
  }

  function renderLegend() {
    return (
      <div className="legend">
        {renderNotPartOfGITSLegend()}
        {renderProjectAssociationLegend()}
      </div>
    );
  }

  const renderRemoveMembersWarningModal = () => (
    <NeoModalDialog
      dialogClassName="remove-multiple-members-warning-modal"
      dialogTitle="Remove Members"
      subTitle={controller.getRemoveMembersWarningModalConfirmationQuestion()}
      bodyText={controller.getRemoveMembersWarningModalAdditionalInfo()}
      dontAutoHideOnButtonClick={true}
      primaryButton={{
        show: true,
        label: controller.getRemoveButtonLabelInRemoveMembersWarningModal(),
        onClick: controller.onClickConfirmRemoveMembers,
        disabled: controller.shouldDisableButtonsInRemoveMembersWarningModal(),
      }}
      secondaryButton={{
        show: true,
        label: "Cancel",
        disabled: controller.shouldDisableButtonsInRemoveMembersWarningModal(),
        onClick: controller.onHideRemoveMembersWarningModal,
      }}
      show={controller.showRemoveMembersWarningModal}
      onHide={controller.onHideRemoveMembersWarningModal}
    />
  );

  const renderRemoveLastManagerWarningModal = () => (
    <NeoModalDialog
      dialogClassName="last-manager-warning-modal"
      dialogTitle="Remove Members"
      bodyIcon={<AlertIcon />}
      bodyText="Member couldn't be removed. At least one manager should be assigned to the team."
      show={controller.showRemoveLastManagerWarningModal}
      onHide={controller.onHideRemoveLastManagerWarningModal}
      primaryButton={{ show: true, label: "OK" }}
      secondaryButton={{ show: false }}
    />
  );

  const renderContent = () => {
    if (controller.shouldRenderTeamMembersTable()) {
      return (
        <>
          <AddTeamMembersForm />
          {renderTeamMembersTable()}
          {renderLegend()}
          {renderRemoveMembersWarningModal()}
          {renderRemoveLastManagerWarningModal()}
        </>
      );
    }
    else {
      return (
        <>
          <p style={{ marginTop: 16 }}>
            There is no member associated with this team.
          </p>
        </>
      );
    }
  };

  const renderActionButtons = () => {
    if (controller.shouldRenderTeamMembersTable()) {
      return (
        <>
          {renderRemoveMembersButton()}
          {renderAddMembersButton()}
          {renderJoinTeamButton()}
          {renderExitTeamButton()}
        </>
      );
    }
  };

  function renderExportCSVLink() {
    if (controller.shouldRenderExportCSVLink) {
      return (
        <ExportCSVLink
          data={controller.getCSVDataForDownload()}
          filename={controller.getCSVFileName()}
          onClick={() => Analytics.trackEventExportCSVTeamMembersDetailsFromTeamDetailsPage()}
        />
      );
    }
  }

  const renderSearchBar = () => {
    if (controller.shouldRenderTeamMembersTable()) {
      return (
        <SearchBar
          label="Search Members"
          className="team-members-search"
          placeholder="Search members by keyword"
          searchText={controller.searchText}
          onChangeSearchText={controller.onChangeSearchText}
          onClickClearSearchText={controller.onClickClearSearchText}
        />
      );
    }
  };

  return (
    <div className="TeamMembers">
      <div className="Heading">
        <div className="title">Members</div>
        {
          controller.shouldRenderTeamMembersTable()
          && (
            <div className="member-count">
              (
              {controller.getMemberCount()}
              )
            </div>
          )
        }
        {renderSearchBar()}
        <div className="member-actions">
          {renderActionButtons()}
        </div>
        <div className="export-members-details">
          {renderExportCSVLink()}
        </div>
      </div>
      {renderContent()}
    </div>
  );
});

export default TeamMembersTable;
