import { callBffApi } from "../../common/services/axios";
import { toast } from "react-toastify";
import { ApplicationCreationRequest, ICreateApplication, ProjectClassification, TeamStatus } from "./redux";
import { ITeam } from "../team-details/redux";

type FetchMyTeamResponse = {
  teams: ITeam[];
};

export const createApplication = async (applicationData: ApplicationCreationRequest): Promise<ICreateApplication> => {
  try {
    const result = await callBffApi<ICreateApplication, ApplicationCreationRequest>("post", "/applications", applicationData);
    toast.success("Application request submitted successfully!");
    return result;
  }
  catch (error) {
    toast.error("Error occurred in submitting the application request! Please try again.");
    throw error;
  }
};

export const fetchMyTeams = async (teamType: ProjectClassification, status: TeamStatus): Promise<ITeam[]> => {
  const result = await callBffApi<FetchMyTeamResponse>("get", `/teams/my-teams?teamType=${teamType}&status=${status}`);
  return result.teams;
};
