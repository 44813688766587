import React from "react";
import { InfrastructureDetails } from "../redux";
import Details from "../details";
import "./okta-chiclets-info.scss";
import NeoLink from "../../../../common/components/neo-link";

type Props = {
  oktaChiclets: InfrastructureDetails["oktaChiclets"];
};

// TODO remove file after removing Neo_Applications_RequestOktaPreview feature toggle
const OktaChicletsInfoOld = (props: Props) => {
  function shouldRenderHelpTextForOktaChiclet() {
    return props.oktaChiclets
      && props.oktaChiclets.length > 0;
  }

  const renderOktaChicletsInfo = () => {
    if (shouldRenderHelpTextForOktaChiclet()) {
      return (
        <div className="okta-chiclet-info">
          {props.oktaChiclets.map((oktaChiclet, idx) => (
            <div key={idx}>
              <span className="authentication-type">{oktaChiclet.authenticationType}</span>
              <span>: </span>
              <NeoLink
                link={oktaChiclet.url}
                className="details-link"
                external
              />
            </div>
          ))}
        </div>
      );
    }
  };

  const renderOktaChicletHelpText = () => {
    if (shouldRenderHelpTextForOktaChiclet()) {
      return (
        <div className="okta-chiclet-help-text">
          Ensure you click the Okta preview chiclet from your Okta home page before you access this link.
        </div>
      );
    }
    return null;
  };

  return (
    <div className="OktaChicletInfo">
      <div className={shouldRenderHelpTextForOktaChiclet() ? "okta-chiclet-field" : ""}>
        <Details fieldName="Okta">
          {renderOktaChicletsInfo()}
        </Details>
      </div>
      {renderOktaChicletHelpText()}
    </div>
  );
};

export default OktaChicletsInfoOld;
