import React from "react";
import "./index.scss";
import PageTitle from "../../../../common/components/page-title";
import useInfraConfigController from "./controller";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import NavigationButtons from "../../navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import StepBar from "../../../../common/components/step-bar";
import TeamLink from "../../../../common/components/team-link";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";

const InfraConfig = createFunctionalComponentFromView<ReturnType<typeof useInfraConfigController>>(
  useInfraConfigController,
  (controller) => {
    function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
      return (
        <NavigationButtons
          doesFormHasErrors={doesFormHasErrors}
          getFormData={getFormData}
          shouldGoToNextStep={controller.shouldGoToNextStep}
          shouldGoToPreviousStep={controller.shouldGoToPreviousStep}
        />
      );
    }

    const renderContent = () => {
      if (controller.shouldRenderForm) {
        return (
          <FormComponent
            questions={controller.questions}
            data={controller.infraConfigFormData}
            renderNavigationButtons={renderNavigationButtons}
          />
        );
      }
      return null;
    };

    const renderStepBar = () => (
      <StepBar
        stepLabels={controller.getStepLabelsForPage()}
        currentCompletedStep={3}
      />
    );

    const renderPageTitle = () => (
      <PageTitle
        title="Global IT Services"
        subText={(
          <TeamLink
            id={controller.teamDetails.teamId}
            name={controller.teamDetails.teamName}
          />
        )}
        subtitle="Infrastructure Configuration"
        displayTitleLighter={true}
      />
    );

    const renderError = () => {
      if (controller.shouldRenderError()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.errorText!)}
          />
        );
      }
    };

    return (
      <div className="InfraConfig">
        {renderStepBar()}
        {renderPageTitle()}
        <Progress text={controller.progressText} />
        {renderError()}
        {renderContent()}
      </div>
    );
  },
);

export default InfraConfig;
