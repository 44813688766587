import React, { FunctionComponent } from "react";
import "./index.scss";

type Props = {
  title: string;
  subtitle?: string | React.ReactNode;
  subText?: string | React.ReactNode;
  displayTitleLighter?: boolean;
};

const PageTitle: FunctionComponent<Props> = (props: Props) => {
  const renderSubtitle = () => {
    if (props.subtitle) {
      if (typeof props.subtitle === "string") {
        return (
          <div className="sub-title">
            <h3>{props.subtitle}</h3>
          </div>
        );
      }
      else {
        return props.subtitle;
      }
    }
  };

  return (
    <div className="PageTitle">
      <div className="main-title">
        {props.displayTitleLighter
          ? <div className="lighter">{props.title}</div>
          : <h3>{props.title}</h3>}
        {props.subText
          ? (
            <div className="subText">
              {props.subText}
            </div>
          )
          : null}
      </div>
      {renderSubtitle()}
    </div>
  );
};

export default PageTitle;
