import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { TeamsPageActions } from "../redux";
import { ITeam } from "../../team-details/redux";
import { convertSpaceToUnderscore } from "../../../utils/string";
import { useHistory } from "react-router-dom";
import { teamTypeDropDownOptions } from "../../../common/data/team-types";
import { ChangeEvent, useEffect, useState } from "react";
import Analytics from "../../../common/services/analytics";

export type Props = {
  onClickCreateTeamButton: () => void;
};

export default function useMyTeamsController(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const myTeamListPEV = useSelectorTyped((state) => state.teamsPage.myTeamListPEV);
  const { teamType, searchText, internalOpenSource, status } = useSelectorTyped((state) => state.teamsPage.myTeamsFilters);
  const { value: myTeams, progress: myTeamsProgressValue, error: myTeamsErrorValue } = myTeamListPEV;
  const [filteredTeams, setFilteredTeams] = useState(myTeams);
  const helpDocumentLink = useSelectorTyped((state) => state.header.uiConfigsPEV.value.externalLinks.helpDocumentLink);

  const shouldRenderNoAssociatedTeamsMessage = () => (!myTeams) || (myTeams.length === 0);

  const shouldRenderProgressMessage = () => myTeamsProgressValue !== null && myTeams.length === 0;

  const shouldRenderErrorMessage = () => !!myTeamsErrorValue;

  useEffect(() => {
    setFilteredTeams(myTeams);
    applyFilter();
  }, [myTeams, teamType, searchText, internalOpenSource, status]);

  const getTeamList = () => filteredTeams;

  const getProgressMsg = () => myTeamsProgressValue;

  const getErrorMsg = () => myTeamsErrorValue;

  const shouldRenderFiltersAndCount = () =>
    !shouldRenderNoAssociatedTeamsMessage()
    && !getErrorMsg();

  const shouldRenderExportMyTeamsLink = () =>
    !shouldRenderNoAssociatedTeamsMessage()
    && filteredTeams.length > 0
    && !getErrorMsg();

  const onClickRetryFetchMyTeams = () => {
    dispatch(TeamsPageActions.fetchMyTeams());
  };

  const onTableRowClickedHandler = (row: ITeam, event: MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`/teams/${row.id}/${convertSpaceToUnderscore(row.name)}`, "_blank");
    }
    else {
      history.push(`/teams/${row.id}/${convertSpaceToUnderscore(row.name)}`);
    }
  };

  const onClickCreateTeamButton = props.onClickCreateTeamButton;

  function applyFilter() {
    let filteredValue = myTeams;
    if (teamType && teamType?.length > 0) {
      filteredValue = myTeams.filter((team) => teamType.includes(team.teamType));
    }
    if (internalOpenSource && internalOpenSource.length === 1) {
      filteredValue = filteredValue.filter((team) => team.isOpen === (internalOpenSource[0] === "Yes"));
    }
    if (status && status.length > 0) {
      filteredValue = filteredValue.filter((team) => team.status && status.includes(team.status));
    }
    const searchTextInUpperCase = searchText.toUpperCase().trim();

    filteredValue = filteredValue.filter(
      (team) => (team.name.toUpperCase().includes(searchTextInUpperCase)
        || team.description.toUpperCase().includes(searchTextInUpperCase)
        || team.contactEmail.toUpperCase().includes(searchTextInUpperCase)
        || team.supportEmail.toUpperCase().includes(searchTextInUpperCase)
      ));
    setFilteredTeams(filteredValue);
  }

  function onChangeInternalOpenSourceFilter(selectedValue: string[]) {
    dispatch(TeamsPageActions.setMyTeamsFilter({ internalOpenSource: selectedValue }));
  }

  function onChangeTeamStatusFilter(selectedValue: string[]) {
    dispatch(TeamsPageActions.setMyTeamsFilter({ status: selectedValue }));
  }

  const getTeamsCountMessage = () => `Showing ${filteredTeams.length} of ${myTeams.length} teams`;

  function onChangeTeamTypeFilter(selectedTeamTypes: string[]) {
    Analytics.trackEventFilterTeamsFromMyTeamsTab();
    dispatch(TeamsPageActions.setMyTeamsFilter({ teamType: selectedTeamTypes }));
  }

  function onChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    Analytics.trackEventSearchTeamsFromMyTeamsTab();
    dispatch(TeamsPageActions.setMyTeamsFilter({ searchText: event.target.value }));
  }

  function onClickClearSearchText() {
    dispatch(TeamsPageActions.setMyTeamsFilter({ searchText: "" }));
  }

  function onClickResetButton() {
    dispatch(TeamsPageActions.clearMyTeamsFilter());
  }

  return {
    helpDocumentLink,
    shouldRenderNoAssociatedTeamsMessage,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    getTeamList,
    getProgressMsg,
    getErrorMsg,
    onClickRetryFetchMyTeams,
    onTableRowClickedHandler,
    onClickCreateTeamButton,
    getTeamTypeDropdownOptions: () => teamTypeDropDownOptions,
    onChangeTeamTypeFilter,
    getSelectedTeamType: () => teamType,
    getTeamsCountMessage,
    searchText,
    onClickClearSearchText,
    onChangeSearchText,
    shouldRenderFiltersAndCount,
    shouldRenderExportMyTeamsLink,
    getInternalOpenSourceDropdownOptions: () => [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    getTeamStatusDropdownOptions: () => [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
    onChangeInternalOpenSourceFilter,
    getInternalOpenSourceSelectedValue: () => internalOpenSource,
    getTeamStatusSelectedValue: () => status,
    onChangeTeamStatusFilter,
    onClickResetButton,
  };
}
