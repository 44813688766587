import React, { FunctionComponent } from "react";
import "./index.scss";
import useEventsDiscoveryController from "./controller";
import Progress from "../../../common/components/progress";
import SideBarItems from "./side-bar-items";
import EventsPanel from "./events-panel";
import ApplicationError from "../common/component/application-error";
import { NOT_FOUND_ERROR_CODE } from "../data/constants";

// const THRESHOLD = [0, 0.5, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1];

const EventsDiscovery: FunctionComponent = () => {
  const controller = useEventsDiscoveryController();
  const categoryMetadataPEV = controller.categoryMetadataPEV;
  const namespaceMetadataPEV = controller.namespaceMetadataPEV;
  const categoryMetadata = controller.sortedCategoryMetadata;
  const namespaceMetadata = controller.sortedNamespaceMetadata;
  const isNamespacesActive = controller.isNamespacesActive;

  const renderSideBarTitle = () => {
    if (controller.isNamespacesViewEnabled) {
      return (
        <div className="sidebar-title-nsview">
          <nav
            className={!isNamespacesActive ? "sidebar-title-selected" : ""}
            onClick={selectCategories}
          >
            Categories
          </nav>
          <span className="sidebar-title-separator" />
          <nav
            className={isNamespacesActive ? "sidebar-title-selected" : ""}
            onClick={selectNamespaces}
          >
            Namespaces
          </nav>
        </div>
      );
    }
    return (
      <div className="sidebar-title">Categories</div>
    );
  };

  const selectCategories = () => {
    controller.switchToNamespaceView(false);
  };

  const selectNamespaces = () => {
    controller.switchToNamespaceView(true);
  };

  function renderSidebar() {
    return (
      <div className="sidebar">
        <div className="sidebar-container">
          {renderSideBarTitle()}
          {renderSideBarItems()}
        </div>
      </div>
    );
  }

  function renderSideBarItems() {
    if (!isNamespacesActive) {
      return (
        <SideBarItems
          idPrefix="cat"
          itemMetadata={categoryMetadata}
          itemMetadataPEV={categoryMetadataPEV}
          highlightedItem={controller.selectedCategory}
          handleItemClick={controller.handleItemClick}
        />
      );
    }
    if (controller.isNamespacesViewEnabled && isNamespacesActive) {
      return (
        <SideBarItems
          idPrefix="ns"
          itemMetadata={namespaceMetadata}
          itemMetadataPEV={namespaceMetadataPEV}
          highlightedItem={controller.selectedNamespaceName}
          handleItemClick={controller.handleItemClick}
        />
      );
    }
  }

  function renderLoadingIcon(message: string) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loading-icon">
          <Progress text={message} />
        </div>
      </div>
    );
  }

  function renderSideBarProgress() {
    if (categoryMetadataPEV.progress) {
      return renderLoadingIcon("Fetching categories.");
    }
    if (isNamespacesActive && namespaceMetadataPEV.progress) {
      return renderLoadingIcon("Fetching namespaces.");
    }
  }

  function renderCategoryEvents() {
    if (!isNamespacesActive) {
      return (
        <EventsPanel
          itemMetadata={categoryMetadata}
          eventRegistrations={controller.eventRegistrations}
          fetchEventRegistrations={controller.fetchEventRegistrations}
          selectedItem={controller.selectedCategory}
          highlightedItem={controller.selectedCategory}
          hasMore={controller.hasMore}
          isLoading={controller.isLoading}
          handleSectionTitleInView={controller.handleSectionTitleInView}
          sectionHeights={controller.categorySectionHeights}
          onEventTileClick={controller.onEventTileClick}
          shouldShowEventTags={controller.shouldShowEventTags}
          filterTags={controller.filterTags}
          isNamespaceViewActive={controller.isNamespacesActive}
        />
      );
    }
    else if (isNamespacesActive && controller.isNamespacesViewEnabled) {
      return (
        <EventsPanel
          itemMetadata={namespaceMetadata}
          eventRegistrations={controller.nsEventRegistrations}
          fetchEventRegistrations={controller.fetchEventRegistrations}
          selectedItem={controller.selectedNamespaceName}
          highlightedItem={controller.selectedNamespaceName}
          hasMore={controller.hasMoreNamespace}
          isLoading={controller.isLoadingNamespace}
          handleSectionTitleInView={controller.handleSectionTitleInView}
          sectionHeights={controller.namespaceSectionHeights}
          onEventTileClick={controller.onEventTileClick}
          shouldShowEventTags={controller.shouldShowEventTags}
          filterTags={controller.filterTags}
          isNamespaceViewActive={controller.isNamespacesActive}
        />
      );
    }
  }

  const renderNotFoundError = () => {
    if (controller.isNotFoundError) {
      return (
        <ApplicationError
          message={"We can't seem to find the page you are looking for."}
          retryLink="/events"
          code={NOT_FOUND_ERROR_CODE}
        />
      );
    }
  };

  return (
    <div className="EventsDiscovery">
      {controller.isNotFoundError ? renderNotFoundError()
        : (
          <>
            <div className="d-flex">
              {renderSideBarProgress()}
              {/* {renderSideBarError()} */}
              {renderSidebar()}
            </div>
            <div
              className="events-container"
            >
              {renderCategoryEvents()}
            </div>
          </>
        )}
    </div>
  );
};
export default EventsDiscovery;
