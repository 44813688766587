import React from "react";
import "./index.scss";
import { discoverCardsData } from "./data/discover-cards-data";
import InfraStructureOfferings from "./infrastructure-offerings";
import { startBuildingData } from "./data/start-building";
import Analytics from "../../common/services/analytics";
import Banner from "./banner";
import TextNavigation from "./text-navigation";
import NavigationCard from "./navigation-card";
import ImageNavigationCard from "./image-navigation-cards";
import { GetStartedPageActions } from "../get-started/redux";
import { useDispatch } from "react-redux";
import { Pages } from "../../common/components/app/pages";
import { Carousel, CarouselItem } from "react-bootstrap";
import { createFunctionalComponentFromView } from "../../utils/view-controller";
import useHomeController from "./controller";
import Confetti from "react-confetti";
import { HomePageCarousel } from "./carousel-config";

const HomePage = createFunctionalComponentFromView(useHomeController, (controller) => {
  const dispatch = useDispatch();
  const renderBannerSection = () => (
    <Carousel
      interval={HomePageCarousel.interval}
      indicators={HomePageCarousel.items.length > 1}
      controls={false}
    >
      {
        HomePageCarousel.items.map((bannerProps: {
          heading: string;
          description: string;
          links?: Array<{ url: string; label: string; isExternalLink?: boolean }>;
          imageSource?: string;
          color?: string;
          backgroundColor?: string;
        }, index: number) => (
          <CarouselItem key={index}>
            <Banner {...bannerProps} />
          </CarouselItem>
        ))
      }
    </Carousel>
  );

  const renderDescriptionSection = () => (
    <div className="description-section">
      NEO is our own in-house built self-service developer experience portal that unifies and streamlines all the platform capabilities to help you
      focus on the most important thing, developing apps.
    </div>
  );

  const renderApplicationSection = () => (
    <div className="section-bg-dark">
      <div className="application-section">
        <div className="navigation-cards">
          {controller.applicationSectionCardConfigs.map((cardData) => (
            <NavigationCard
              key={cardData.buttonName}
              {...cardData}
              onTrackAnalytics={() => {
                Analytics.trackEventClickOnHomePageItems(cardData.heading, cardData.buttonName);
                if (cardData.link === Pages.PROJECT_CATEGORIES.path) {
                  dispatch(GetStartedPageActions.updateCurrentStep("PROJECT_CLASSIFICATION"));
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );

  const renderDiscoverCardsSection = () => (
    <div className="discover-section">
      <h1>Discover</h1>
      <div className="navigation-cards">
        {discoverCardsData.map((cardData) => (
          <ImageNavigationCard
            key={cardData.title}
            {...cardData}
            onTrackAnalytics={() => Analytics.trackEventClickOnHomePageItems("Discover", cardData.title)}
          />
        ))}
      </div>
    </div>
  );

  const renderStartBuildingSection = () => (
    <div className="start-building-section">
      <h1>Start building</h1>
      <div className="navigation-links">
        {startBuildingData.map((cardData) => (
          <TextNavigation
            {...cardData}
            key={cardData.text}
            onTrackAnalytics={() => Analytics.trackEventClickOnHomePageItems("Start building", cardData.text)}
          />
        ))}
      </div>
    </div>
  );

  const renderConfetti = () => {
    if (controller.shouldRenderConfetti()) {
      return (
        <Confetti
          colors={["#EDF1F3", "#000000", "#F2617A", "#003D4F", "#CC8501", "#6B9E78", "#47A1AD", "#634F7D", "#BD4257"]}
          numberOfPieces={100}
        />
      );
    }
  };

  return (
    <div className="HomePage">
      {renderConfetti()}
      {renderBannerSection()}
      {renderDescriptionSection()}
      {renderApplicationSection()}
      {renderDiscoverCardsSection()}
      <InfraStructureOfferings />
      {renderStartBuildingSection()}
    </div>
  );
});

export default HomePage;
