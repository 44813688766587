import React, { FunctionComponent } from "react";
import { Nullable } from "../../../utils/nullable";
import "./index.scss";
import Spinner from "react-bootstrap/cjs/Spinner";

export interface Props {
  text: Nullable<string>;
}

const renderProgress = (text: Nullable<string>) => (
  <div
    id="progress"
    className="d-flex flex-column align-items-center justify-content-center"
  >
    <Spinner
      className="spinner"
      animation="border"
      role="status"
    />
    <div className="text">
      {text}
    </div>
  </div>
);

const isValidText = (text: Nullable<string>) => !!text;

const Progress: FunctionComponent<Props> = (props: Props) => (
  isValidText(props.text) ? renderProgress(props.text) : null
);

export default Progress;
