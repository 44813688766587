import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as service from "./service";
import { PEV, ProgressErrorValue } from "../../utils/progress-error-value";
import { Nullable } from "../../utils/nullable";
import { PE, ProgressError } from "../../utils/progress-error";
import { ApplicationBasicInfo } from "../applications/application-details/redux";
import { TeamType } from "../create-team/redux";
import { INeoErrorInfo, NeoError } from "../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../../common/redux/createAsyncThunkWithRejectValue";
import { IPotentialMember } from "../teams/redux";
import { TeamStatus } from "../get-started/redux";
import { PES, ProgressErrorSuccess } from "../../utils/progress-error-success";
import { IProject } from "../../common/redux/slices/projects";

export interface IApiClient {
  id: number;
  name: string;
  clientId: string;
  apiTeamId: number;
  neoTeamId: string;
  teamName: string;
  applicationId: string;
  applicationName: string;
  status: string;
  updatedBy: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  secretRefreshedAt: string;
}

export interface IEditTeamForm {
  name: string;
  description: string;
  contactEmail: string;
  supportEmail: string;
  isOpen?: boolean;
}

export interface IAddTeamMembersForm {
  "managers"?: string[];
  "developers"?: string[];
  "non-developers"?: string[];
}

export interface IAddOneTeamMemberRequest {
  employeeId: string;
  role: Role;
}

export type JoinTeamRequest = IAddOneTeamMemberRequest;

export type Role = "manager" | "developer" | "non-developer";

export type collaborationType = "Open" | "Closed";

export interface ITeamMember extends IPotentialMember {
  role: Role;
  homeOffice: string;
  country: string;
  workingOffice: string;
  workingCountry: string;
  projects: Array<{
    id: string;
    name: string;
    code: string;
    accountProjectCode: string;
    effort: string;
  }>;
  associatedProjects: Array<{
    id: string;
    name: string;
  }>;
}

export type IProjectDetails = IProject & {
  isActive: boolean;
};

export interface ITeam {
  id: NanoId;
  name: string;
  status: TeamStatus;
  description: string;
  contactEmail: string;
  supportEmail: string;
  teamType: TeamType;
  members: ITeamMember[];
  isOpen: boolean;
  isTeamDetailsEditable: boolean;
  canManageMembers: boolean;
  canJoinTeam: boolean;
  canDeactivateTeam: boolean;
  canExitTeam: boolean;
  canManageProjects?: boolean;
  projects?: Nullable<IProjectDetails[]>;
}

export interface IUpdateTeamRequest {
  name: string;
  description: string;
  contactEmail: string;
  supportEmail: string;
  isOpen?: boolean;
}

export interface ActivityHistory {
  eventType: string;
  action: string;
  performedBy?: string;
  occurredAt: string;
}

export interface ITeamProject {
  id: string;
  name: string;
  code: string;
  type: string;
  accountId: string;
  accountProjectCode: string;
  opportunityId: string;
  isActive: boolean;
  startDate: DateOnly;
  endDate: DateOnly;
  employeeIds: EmployeeId[];
}

export interface ITeamDetailsPageState {
  teamDetailsPEV: ProgressErrorValue<Nullable<ITeam>, Nullable<string>, Nullable<INeoErrorInfo>>;
  editTeamDetailsPE: ProgressError;
  isTeamDetailsInEditMode: boolean;
  addTeamMembersPE: ProgressError;
  joinTeamPE: ProgressError;
  showAddMembersModal: boolean;
  showJoinTeamModal: boolean;
  showProjectSelectionForm: boolean;
  applicationsPEV: ProgressErrorValue<ApplicationBasicInfo[], Nullable<string>, Nullable<INeoErrorInfo>>;
  activitiesPEV: ProgressErrorValue<ActivityHistory[], Nullable<string>, Nullable<INeoErrorInfo>>;
  updateTeamMemberRolePE: ProgressError;
  removeMemberPES: ProgressErrorSuccess;
  removeMultipleMembersPES: ProgressErrorSuccess;
  exitTeamPE: ProgressError;
  deactivateTeamPES: ProgressErrorSuccess;
  idOfTeamBeingFetched: string;
  apiClientsPEV: ProgressErrorValue<IApiClient[], Nullable<string>, Nullable<INeoErrorInfo>>;
  associateProjectsPES: ProgressErrorSuccess;
  dissociateProjectPES: ProgressErrorSuccess;
}

const initialState: ITeamDetailsPageState = {
  teamDetailsPEV: PEV(null),
  editTeamDetailsPE: PE(),
  isTeamDetailsInEditMode: false,
  addTeamMembersPE: PE(),
  joinTeamPE: PE(),
  showAddMembersModal: false,
  showJoinTeamModal: false,
  applicationsPEV: PEV([]),
  activitiesPEV: PEV([]),
  updateTeamMemberRolePE: PE(),
  removeMemberPES: PES(),
  removeMultipleMembersPES: PES(),
  exitTeamPE: PE(),
  deactivateTeamPES: PES(),
  idOfTeamBeingFetched: "",
  apiClientsPEV: PEV([]),
  showProjectSelectionForm: false,
  associateProjectsPES: PES(),
  dissociateProjectPES: PES(),
};

const fetchTeamDetails = createAsyncThunkWithRejectValue(
  "/team",
  (teamId: string) => service.fetchTeamDetails(teamId),
);

const fetchApiClientsOfTeam = createAsyncThunkWithRejectValue(
  "/team/apiClients",
  (teamId: string) => service.fetchApiClientsOfTeam(teamId),
);

const updateTeamDetails = createAsyncThunk(
  "/update-team",
  (payload: { teamId: string; teamDetails: IUpdateTeamRequest }) => {
    const { teamDetails, teamId } = payload;
    return service.updateTeamDetails(teamId, teamDetails);
  },
);

const addMembersToTheTeam = createAsyncThunk(
  "/add-team-members",
  (payload: { teamId: string; memberData: IAddTeamMembersForm }) => {
    const { memberData, teamId } = payload;
    return service.addMembersToTeam(teamId, memberData);
  },
);

const joinTeam = createAsyncThunk(
  "join-team",
  (payload: { teamId: string; memberData: IAddOneTeamMemberRequest }) => {
    const { memberData, teamId } = payload;
    return service.joinTeam(teamId, memberData);
  },
);

const updateTeamMemberRole = createAsyncThunk(
  "/update-team-member-role",
  (payload: {
    teamId: string;
    employeeId: string;
    role: string;
  }) => {
    const { teamId, employeeId, role } = payload;
    return service.updateTeamMemberRole(teamId, employeeId, role);
  },
);

const fetchApplicationsOfTeam = createAsyncThunkWithRejectValue(
  "/all-applications/team/:teamId",
  (teamId: string) => service.fetchApplicationsOfTeam(teamId),
);

const fetchActivitiesOfTeam = createAsyncThunkWithRejectValue(
  "/activities/team/:teamId",
  (teamId: string) => service.fetchActivitiesOfTeam(teamId),
);

const removeMemberFromTeam = createAsyncThunk(
  "delete /:teamId/members/:employeeId",
  (payload: { teamId: string; employeeId: string }) => {
    const { teamId, employeeId } = payload;
    return service.removeMemberFromTeam(teamId, employeeId);
  },
);

const associateProjectsToTeam = createAsyncThunk(
  "put /:teamId/projects/associate",
  (payload: { teamId: string; projectIds: Array<IProject["id"]> }) => {
    const { teamId, projectIds } = payload;
    return service.associateProjectsToTeam(teamId, projectIds);
  },
);

const dissociateProjectFromTeam = createAsyncThunk(
  "put /:teamId/projects/:projectId",
  (payload: { teamId: string; projectId: IProject["id"] }) => {
    const { teamId, projectId } = payload;
    return service.dissociateProjectFromTeam(teamId, projectId);
  },
);

const exitTeam = createAsyncThunk(
  "exit team",
  (payload: { teamId: string; employeeId: string }) => {
    const { teamId, employeeId } = payload;
    return service.exitTeam(teamId, employeeId);
  },
);

const deactivateTeam = createAsyncThunk(
  "post /:teamId/deactivate",
  (teamId: string) => service.deactivateTeam(teamId),
);

const removeMultipleMembersFromTeam = createAsyncThunk(
  "delete /:teamId/members/bulk",
  (payload: { teamId: string; employeeIds: string[] }) => {
    const { teamId, employeeIds } = payload;
    return service.removeMultipleMembersFromTeam(teamId, employeeIds);
  },
);

function isCurrentFetchTeamRequestSameAsExistingTeam(
  teamIdArg: string,
  existingTeamDetails: Nullable<ITeam>,
) {
  return teamIdArg === existingTeamDetails?.id;
}

function isFetchingTeamActionCompleted(
  idOfTeamBeingFetched: string,
  teamIdFromAction: string,
) {
  return idOfTeamBeingFetched === teamIdFromAction;
}

export const teamDetailsPageSlice = createSlice({
  name: "teamDetailsPage",
  initialState,
  reducers: {
    updateTeamDetailsPage:
      (state: ITeamDetailsPageState, action: PayloadAction<Partial<ITeamDetailsPageState>>) => ({
        ...state,
        ...action.payload,
      }),
  },
  extraReducers: {
    [fetchTeamDetails.pending.type]: (state, action: PayloadAction<ITeam, string, { arg: string }>) => {
      if (isCurrentFetchTeamRequestSameAsExistingTeam(action.meta.arg, state.teamDetailsPEV.value)) {
        state.teamDetailsPEV = PEV(state.teamDetailsPEV.value);
      }
      else {
        state.teamDetailsPEV = PEV(null, "Fetching team details.");
      }
      state.idOfTeamBeingFetched = action.meta.arg;
    },
    [fetchTeamDetails.fulfilled.type]: (state, action: PayloadAction<ITeam, string, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.teamDetailsPEV = PEV(action.payload);
      }
    },
    [fetchTeamDetails.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.teamDetailsPEV = PEV(null, null, action.payload.getErrorInfo());
      }
    },
    [updateTeamDetails.pending.type]: (state) => {
      state.editTeamDetailsPE = PE("Updating team details.", null);
    },
    [updateTeamDetails.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      state.teamDetailsPEV = PEV(action.payload);
      state.editTeamDetailsPE = PE();
      state.isTeamDetailsInEditMode = false;
    },
    [updateTeamDetails.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.editTeamDetailsPE = PE(null, `Error occurred while updating team details (${action.error.message})`);
    },
    [addMembersToTheTeam.pending.type]: (state) => {
      state.addTeamMembersPE = PE("Adding members to the team.", null);
    },
    [addMembersToTheTeam.fulfilled.type]: (state, action: PayloadAction<Partial<ITeam>>) => {
      state.teamDetailsPEV = PEV({
        ...state.teamDetailsPEV.value,
        ...action.payload,
      } as ITeam);
      state.addTeamMembersPE = PE();
      state.showAddMembersModal = false;
    },
    [addMembersToTheTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.addTeamMembersPE = PE(null, `Error occurred while adding members to the team (${action.error.message})`);
    },
    [joinTeam.pending.type]: (state) => {
      state.joinTeamPE = PE("Joining to the team.", null);
    },
    [joinTeam.fulfilled.type]: (state, action: PayloadAction<Partial<ITeam>>) => {
      state.teamDetailsPEV = PEV({
        ...state.teamDetailsPEV.value,
        ...action.payload,
      } as ITeam);
      state.joinTeamPE = PE();
      state.showJoinTeamModal = false;
    },
    [joinTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.joinTeamPE = PE(null, `Error occurred while joining the team (${action.error.message})`);
      state.showJoinTeamModal = false;
    },
    [fetchApplicationsOfTeam.pending.type]: (state, action) => {
      if (isCurrentFetchTeamRequestSameAsExistingTeam(action.meta.arg, state.teamDetailsPEV.value)) {
        state.applicationsPEV = PEV(state.applicationsPEV.value);
      }
      else {
        state.applicationsPEV = PEV([], "Fetching all-applications");
      }
    },
    [fetchApplicationsOfTeam.fulfilled.type]: (state, action: PayloadAction<ApplicationBasicInfo[], string, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.applicationsPEV = PEV(action.payload);
      }
    },
    [fetchApplicationsOfTeam.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.applicationsPEV = PEV([], null, action.payload.getErrorInfo());
      }
    },
    [fetchApiClientsOfTeam.pending.type]: (state, action) => {
      if (isCurrentFetchTeamRequestSameAsExistingTeam(action.meta.arg, state.teamDetailsPEV.value)) {
        state.apiClientsPEV = PEV(state.apiClientsPEV.value);
      }
      else {
        state.apiClientsPEV = PEV([], "Fetching api clients information");
      }
    },
    [fetchApiClientsOfTeam.fulfilled.type]: (state, action: PayloadAction<IApiClient[], string, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.apiClientsPEV = PEV(action.payload);
      }
    },
    [fetchApiClientsOfTeam.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.apiClientsPEV = PEV([], null, action.payload.getErrorInfo());
      }
    },
    [fetchActivitiesOfTeam.pending.type]: (state, action) => {
      if (isCurrentFetchTeamRequestSameAsExistingTeam(action.meta.arg, state.teamDetailsPEV.value)) {
        state.activitiesPEV = PEV(state.activitiesPEV.value);
      }
      else {
        state.activitiesPEV = PEV([], "Fetching activities");
      }
    },
    [fetchActivitiesOfTeam.fulfilled.type]: (state, action: PayloadAction<ActivityHistory[], string, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.activitiesPEV = PEV(action.payload);
      }
    },
    [fetchActivitiesOfTeam.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: string }>) => {
      if (isFetchingTeamActionCompleted(state.idOfTeamBeingFetched, action.meta.arg)) {
        state.activitiesPEV = PEV([], null, action.payload.getErrorInfo());
      }
    },
    [updateTeamMemberRole.pending.type]: (state) => {
      state.updateTeamMemberRolePE = PE("Updating team member role.", null);
    },
    [updateTeamMemberRole.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.updateTeamMemberRolePE = PE(null, null);
    },
    [updateTeamMemberRole.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.updateTeamMemberRolePE = PE(null, `Error occurred while updating team member role (${action.error.message})`);
    },
    [removeMemberFromTeam.pending.type]: (state) => {
      state.removeMemberPES = PES("Removing member from team.", null);
    },
    [removeMemberFromTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.removeMemberPES = PES(null, null, true);
    },
    [removeMemberFromTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.removeMemberPES = PES(null, `Error occurred while removing team member (${action.error.message})`);
    },
    [exitTeam.pending.type]: (state) => {
      state.exitTeamPE = PE("Exiting team.", null);
    },
    [exitTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.exitTeamPE = PE(null, null);
    },
    [exitTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.exitTeamPE = PE(null, `Error occurred while exiting team (${action.error.message})`);
    },
    [removeMultipleMembersFromTeam.pending.type]: (state) => {
      state.removeMultipleMembersPES = PES("Removing members from team.", null);
    },
    [removeMultipleMembersFromTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.removeMultipleMembersPES = PES(null, null, true);
    },
    [removeMultipleMembersFromTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.removeMultipleMembersPES = PES(null, `Error occurred while removing team members (${action.error.message})`);
    },
    [deactivateTeam.pending.type]: (state) => {
      state.deactivateTeamPES = PES("Deactivating team.", null);
    },
    [deactivateTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.deactivateTeamPES = PES(null, null, true);
    },
    [deactivateTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.deactivateTeamPES = PES(null, `Error occurred while deactivating team (${action.error.message})`);
    },
    [associateProjectsToTeam.pending.type]: (state) => {
      state.associateProjectsPES = PES("Associating projects", null);
    },
    [associateProjectsToTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.associateProjectsPES = PES(null, null, true);
      state.showProjectSelectionForm = false;
    },
    [associateProjectsToTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.associateProjectsPES = PES(null, `Error occurred while associating projects to team (${action.error.message})`);
    },
    [dissociateProjectFromTeam.pending.type]: (state) => {
      state.dissociateProjectPES = PES("Dissociating projects", null);
    },
    [dissociateProjectFromTeam.fulfilled.type]: (state, action: PayloadAction<ITeam>) => {
      if (state.teamDetailsPEV.value != null) {
        state.teamDetailsPEV = PEV(action.payload);
      }
      state.dissociateProjectPES = PES(null, null, true);
    },
    [dissociateProjectFromTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.dissociateProjectPES = PES(null, `Error occurred while dissociating projects from team (${action.error.message})`);
    },
  },
});

const updateTeamDetailsPageState: ActionCreatorWithPayload<Partial<ITeamDetailsPageState>>
  = teamDetailsPageSlice.actions.updateTeamDetailsPage;

const updateTeamDetailsEditableState = (editable: boolean) =>
  updateTeamDetailsPageState({ isTeamDetailsInEditMode: editable });

const displayAddMembersModal = (value: boolean) =>
  updateTeamDetailsPageState({ showAddMembersModal: value });

const displayProjectSelection = (value: boolean) =>
  updateTeamDetailsPageState({ showProjectSelectionForm: value });

const displayJoinTeamModal = (value: boolean) =>
  updateTeamDetailsPageState({ showJoinTeamModal: value });

const updateTeamDetailsPEVState = (team: ITeam) =>
  updateTeamDetailsPageState({ teamDetailsPEV: PEV(team, null, null) });

const resetRemoveMultipleMembersPES = () => updateTeamDetailsPageState({ removeMultipleMembersPES: PES() });

const resetRemoveMemberPES = () => updateTeamDetailsPageState({ removeMemberPES: PES() });

const resetDeactivateTeamPES = () => updateTeamDetailsPageState({ deactivateTeamPES: PES() });

export const teamDetailsPageReducer = teamDetailsPageSlice.reducer;

export const TeamDetailsPageActions = {
  fetchTeamDetails,
  updateTeamDetails,
  addMembersToTheTeam,
  joinTeam,
  updateTeamDetailsEditableState,
  displayAddMembersModal,
  displayProjectSelection,
  fetchApplicationsOfTeam,
  fetchActivitiesOfTeam,
  updateTeamMemberRole,
  removeMemberFromTeam,
  removeMultipleMembersFromTeam,
  updateTeamDetailsPEVState,
  displayJoinTeamModal,
  deactivateTeam,
  fetchApiClientsOfTeam,
  exitTeam,
  resetRemoveMemberPES,
  resetRemoveMultipleMembersPES,
  resetDeactivateTeamPES,
  associateProjectsToTeam,
  dissociateProjectFromTeam,
};
