import techStack from "../../../../get-started/data/tech-stack";
import { getApplicationOwnerNameValidatorConfig } from "../../../../get-started/utils/validator";
import applicationCategories from "../../../../get-started/data/application-category";
import getDataClassificationQuestions from "../../../../get-started/data/data-classification";
import moment from "moment";

type EditApplicationFormOptions = {
  countryNames: string[];
  businessFunctions: string[];
  countryHelpText: string;
  applicationPurposes: string[];
  isDataClassificationEditable: boolean;
  shouldRenderLifespan: boolean;
  isApplicationLifespanQuestionEditable: boolean;
  neoSupportEmail: string;
};

const getGeneralDetailsQuestions = (options: EditApplicationFormOptions) => [
  {
    name: "description",
    type: "comment",
    title: "Description",
    description: "A brief of the application's objective or problem it solves.",
    descriptionLocation: "underTitle",
    isRequired: true,
    rows: 3,
  },
  {
    type: "dropdown",
    name: "purpose",
    title: "Purpose",
    isRequired: true,
    choices: options.applicationPurposes,
    hasOther: true,
    otherText: "Other",
    otherPlaceHolder: "Specify purpose",
    storeOthersAsComment: false,
  },
  options.shouldRenderLifespan
  && {
    type: "datepicker",
    name: "endDate",
    description: "Application ends on",
    inputType: "date",
    title: "Lifespan",
    dateFormat: "M dd, yy",
    min: moment().add(7, "days").format("MMM DD, YYYY"),
    max: moment().add(6, "months").format("MMM DD, YYYY"),
    prevText: "Prev",
    nextText: "Next",
    isRequired: true,
    readOnly: !options.isApplicationLifespanQuestionEditable,
  },
  {
    type: "text",
    name: "targetAudience",
    title: "Target audience",
    isRequired: true,
  },
  {
    type: "dropdown",
    name: "businessFunction",
    title: "Business function",
    placeHolder: "Select the business function",
    isRequired: false,
    description: "Select the TW activity which your application will support",
    descriptionLocation: "underTitle",
    choices: options.businessFunctions,
  },
  {
    type: "checkbox",
    name: "category",
    title: "Category",
    isRequired: true,
    choices: applicationCategories,
    hasOther: true,
    otherText: "Others",
    otherPlaceHolder: "Specify comma separated categories",
  },
  {
    type: "tagbox",
    name: "techStack",
    title: "Tech stack",
    isRequired: false,
    hasOther: true,
    otherText: "Others",
    choices: techStack,
  },
  {
    type: "text",
    name: "owner",
    title: "Owner",
    description: "Comma separated Thoughtworks domain email addresses",
    descriptionLocation: "underTitle",
    isRequired: true,
    validators: getApplicationOwnerNameValidatorConfig(),
  },
  {
    type: "dropdown",
    name: "countryName",
    title: "Country",
    description: options.countryHelpText,
    descriptionLocation: "underTitle",
    isRequired: true,
    choices: options.countryNames,
  },
  {
    type: "text",
    name: "project",
    title: "Billing project",
    readOnly: true,
    description: `Billing project is non-editable. Contact ${options.neoSupportEmail} for further details.`,
  },
];

const getDataClassificationQuestionsForEditApplication = (isDataClassificationEditable: boolean) => {
  const dataClassificationQuestions = getDataClassificationQuestions();
  if (isDataClassificationEditable) {
    return dataClassificationQuestions;
  }
  return {
    ...dataClassificationQuestions,
    elements: [
      {
        type: "html",
        html: "<div class='alert-message'>MNPI and PII consents are editable only by privileged users. "
          + "Contact engineering-platform-support@thoughtworks.com to make any change.</div>",
      },
      ...dataClassificationQuestions.elements.map((question) => ({
        ...question,
        readOnly: true,
      })),
    ],
  };
};

const editApplicationInformationQuestions = (options: EditApplicationFormOptions) => [
  {
    type: "panel",
    title: "General Details",
    elements: getGeneralDetailsQuestions(options),
  },
  getDataClassificationQuestionsForEditApplication(options.isDataClassificationEditable),
];

export default editApplicationInformationQuestions;
