import React from "react";
import useTeamDetailsController, { TeamDetailsProps } from "./controller";
import "./index.scss";
import ContainerBox from "../../common/components/container-box";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../common/components/error-new";
import Progress from "../../common/components/progress";
import { EditTeamDetails } from "./edit-team-details";
import { TeamInformation } from "./team-information";
import { TeamApplicationsTable } from "./team-applications";
import BackButton from "../../common/components/back-button";
import { createFunctionalComponentFromView } from "../../utils/view-controller";
import { TeamActivitiesTable } from "./team-activities";
import { TeamMembership } from "./team-membership";
import { Tabs } from "antd";
import { teamTypeLabelMap } from "../../common/data/team-types";
import { PageTitleWithRightElement } from "../../common/components/page-title-with-right-column-element";
import ImpactXIconButton from "../../common/components/impactx-icon-button";
import NeoModalDialog from "../../common/components/neo-modal-dialog";

export const TeamDetails = createFunctionalComponentFromView<ReturnType<typeof useTeamDetailsController>, TeamDetailsProps>(
  useTeamDetailsController,
  (controller) => {
    function renderTitle() {
      return (
        <div>
          <div className="team-type-info">
            {controller.teamDetails && teamTypeLabelMap[controller.teamDetails.teamType]}
          </div>
          <PageTitleWithRightElement
            title={controller.teamDetails?.name ?? ""}
            rightColumnElement={renderRightColumnElement()}
          />
        </div>
      );
    }

    function renderRightColumnElement() {
      return (
        !(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox)
          ? (
            <div style={{ display: "flex" }}>
              {renderDeactivateButton()}
            </div>
          )
          : <></>)
      ;
    }

    function renderDeactivateButton() {
      if (controller.shouldRenderDeactivateTeamButton()) {
        return (
          <ImpactXIconButton
            onClick={controller.onClickDeactivateButton}
            className="deactivate-button"
            ariaLabel="Deactivate team"
          >
            <i className="ri-shut-down-line" />
          </ImpactXIconButton>
        );
      }
    }

    const renderDeactivateTeamWarningModal = () => (
      <NeoModalDialog
        dialogClassName="deactivate-team-warning-modal"
        dialogTitle="Deactivate Team"
        primaryButton={{
          show: controller.shouldRenderModalPrimaryButton(),
          onClick: controller.onClickConfirmDeactivateTeam,
          label: controller.getConfirmButtonLabel(),
          disabled: controller.shouldDisableDialogButtons(),
        }}
        secondaryButton={{
          show: controller.shouldRenderModalSecondaryButton(),
          label: "Cancel",
          disabled: controller.shouldDisableDialogButtons(),
        }}
        show={controller.showDeactivateTeamWarningModal}
        onHide={controller.onHideDeactivateTeamWarningModal}
      >
        {renderDeactivateModalContent()}
      </NeoModalDialog>
    );

    const renderActiveApplicationsAssociatedToTeamWarning = () => (
      <>
        <p>Active applications are associated to this team. Decommission those before you deactivate this team.</p>
      </>
    );

    function renderDeactivateModalContent() {
      return (
        <div>
          {controller.shouldRenderDeactivateTeamModalProgress() && <Progress text={controller.deactivateTeamProgressText} />}
          {controller.shouldRenderDeactivateTeamModalError()
          && (
            <Error

              {...mapApplicationErrorInfoToErrorComponentProps(controller.deactivateTeamErrorText!)}
            />
          )}
          {
            controller.shouldRenderDeactivateTeamModalContent()
            && controller.shouldRenderActiveApplicationsWarningContent()
            && renderActiveApplicationsAssociatedToTeamWarning()
          }
          {controller.shouldRenderDeactivateTeamModalContent() && !controller.shouldRenderActiveApplicationsWarningContent() && (
            <>
              <p><b>Are you sure you want to deactivate this team?</b></p>
              <p>{controller.getDeactivateTeamWarningModalMessage()}</p>
            </>
          )}
        </div>
      );
    }

    function renderContent() {
      if (controller.shouldRenderProgressMessage()) {
        return (<Progress text={controller.progressText} />);
      }
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.errorText!)}
            onClick={controller.onRetryButtonClick}
          />
        );
      }
      if (controller.shouldRenderTeamDetails()) {
        return (
          <>
            {renderTitle()}
            {renderDeactivateTeamWarningModal()}
            <Tabs
              animated={{ inkBar: true, tabPane: false }}
              tabBarStyle={{ color: "#000000" }}
              items={[{ label: "Team Details", key: "team-details",
                        children:
                        controller.isTeamDetailsInEditMode ? <EditTeamDetails /> : <TeamInformation />,
              },
                      {
                        label: "Membership",
                        key: "membership",
                        children: <TeamMembership />,
                      },
                      {
                        label: "Applications",
                        key: "applications",
                        children: <TeamApplicationsTable />,
                      },
                      {
                        label: "Activity History",
                        key: "activity-history",
                        children: <TeamActivitiesTable />,
                      },
              ]}
            />
          </>
        );
      }
    }

    return (
      <div className="TeamDetails">
        <ContainerBox
          componentToRenderInEmptySpace={
            <BackButton onClickHandler={controller.onClickBackButton} />
          }
          isDisplayingBackButton={true}
        >
          {renderContent()}
        </ContainerBox>
      </div>
    );
  },
);
