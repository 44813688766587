import React, { FunctionComponent, ReactNode } from "react";

export type ControllerProvider<Props, Controller> = (props: Props) => Controller;

export type View<Controller> = (
  controller: Controller,
  children?: ReactNode,
) => ReturnType<FunctionComponent>;

export function createFunctionalComponentFromView<Controller, Props = unknown>(
  controllerProvider: ControllerProvider<Props, Controller>,
  view: View<Controller>,
): React.FunctionComponent<Props> {
  return ({
    children,
    ...props
  }) => {
    const controller = controllerProvider(props as Props);
    return view(controller, children);
  };
}
