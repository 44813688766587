import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../../utils/nullable";
import { getFeatureToggles } from "../../services/feature-toggles";

export interface IFeatureToggle {
  name: string;
  description: string;
  type: string;
  enabled: boolean;
  stale: boolean;
  strategies: Array<{ name: string }>;
  variants: Nullable<string>;
  createdAt: string;
}

type IFeatureTogglesState = ProgressErrorValue<IFeatureToggle[]>;

const initialState: IFeatureTogglesState = PEV([]);

const fetchFeatureToggles = createAsyncThunk<IFeatureToggle[]>("feature-toggles", async (): Promise<IFeatureToggle[]> => {
  const featureToggles = await getFeatureToggles();
  return featureToggles.features;
});

export const featureTogglesSlice = createSlice({
  name: "featureToggles",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFeatureToggles.pending.type]: (state) =>
      PEV(state.value, "Fetching feature toggles"),
    [fetchFeatureToggles.fulfilled.type]: (state, action: PayloadAction<IFeatureToggle[]>) =>
      PEV(action.payload),
    [fetchFeatureToggles.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) =>
      PEV(state.value, null, `Unable to fetch feature toggles. ${action.error.message}.`),
  },
});

export const featureTogglesReducer = featureTogglesSlice.reducer;

export const FeatureToggleActions = {
  fetchFeatureToggles,
};
