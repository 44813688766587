import React, { FunctionComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  EVENT_CARD_DESCRIPTION_MAX_LENGTH,
  EVENT_CARD_NAME_MAX_LENGTH,
  HIDE_TOOLTIP_DELAY,
  SHOW_TOOLTIP_DELAY,
} from "../data/constants";
import { IEventRegistrationV3, IEventRegistration } from "./redux";
import ItemName from "../common/component/item-name";
import { HashLink as Link } from "react-router-hash-link";
import EventTag from "../common/component/event-tag";

type Props = {
  event: IEventRegistrationV3 | IEventRegistration;
  resourceDescriptionClassName: string;
  onEventTileClick: (namespaceName: string, eventName: string) => void;
  shouldShowEventTags: () => boolean;
  filterTags: (tags: string[]) => string[] | undefined;
};

const EventCard: FunctionComponent<Props> = (props: Props) => {
  const event = props.event;
  const renderFullEventDescription = (fullDescription: string) => (
    <Tooltip id="button-tooltip">
      {fullDescription}
    </Tooltip>
  );

  const getEventCardDescription = function(description: string, maxDescriptionLength: number) {
    if (description.length <= maxDescriptionLength) {
      return description;
    }
    return `${description.slice(0, maxDescriptionLength)}...`;
  };

  const renderEventDescription = (eventName: string, eventDescription: string) => (
    eventDescription.length < EVENT_CARD_DESCRIPTION_MAX_LENGTH
      ? (
        <div className="event-registration-desc">
          {getEventCardDescription(eventDescription, EVENT_CARD_DESCRIPTION_MAX_LENGTH)}
        </div>
      )
      : (
        <OverlayTrigger
          placement="auto"
          delay={{ show: SHOW_TOOLTIP_DELAY, hide: HIDE_TOOLTIP_DELAY }}
          overlay={renderFullEventDescription(eventDescription)}
        >
          <div className="event-registration-desc">
            {getEventCardDescription(eventDescription, EVENT_CARD_DESCRIPTION_MAX_LENGTH)}
          </div>
        </OverlayTrigger>
      )
  );

  const renderTags = (tags: string[] | undefined, filteredTags: string[] | undefined) => {
    if (props.shouldShowEventTags()) {
      return (
        <div className="event-tags">
          {
            filteredTags && filteredTags.map((tag) => (
              <EventTag
                key={tag}
                name={tag}
                className="text"
              />
            ),
            )
          }
          {filteredTags && filteredTags.length !== tags?.length
          && (
            <EventTag
              name="..."
              className="text"
            />
          )}
        </div>
      );
    }
  };

  return (
    <Link
      key={event.name}
      id={`namespace_${event.namespace.name}_event_${event.name}`}
      className="event-registration"
      onClick={() => props.onEventTileClick(event.namespace.name, event.name)}
      to={{
        pathname: `/events/${event.namespace.name}/${event.name}`,
      }}
    >
      <div className="event-header">
        {event.namespace.name}
      </div>
      <ItemName
        name={event.name}
        maxLength={EVENT_CARD_NAME_MAX_LENGTH}
        itemNameClassName="event-registration-name"
      />
      {renderEventDescription(event.name, event.description)}
      {renderTags(event.tags, props.filterTags(event.tags))}
    </Link>
  );
};

export default EventCard;
