import { Hash } from "../../utils/hash";

export const applicationTypeLabelMap: Hash<string> = {
  EXPERIMENT_AND_INNOVATION: "Experiment and Innovation",
  OTHERS: "Others",
  GITS: "Global IT Services",
};

export const applicationTypeDropDownOptions = [
  { label: applicationTypeLabelMap.EXPERIMENT_AND_INNOVATION, value: "EXPERIMENT_AND_INNOVATION" },
  { label: applicationTypeLabelMap.OTHERS, value: "OTHERS" },
  { label: applicationTypeLabelMap.GITS, value: "GITS" },
];
