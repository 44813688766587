import { useSelectorTyped } from "../../../common/redux/store";
import { IProjectDetails, TeamDetailsPageActions } from "../redux";
import { TeamType } from "../../create-team/redux";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";
import { useEffect, useState } from "react";
import castTo from "../../../utils/cast-to";
import { useDispatch } from "react-redux";
import Analytics from "../../../common/services/analytics";
import { getEnvVars } from "../../../common/config/env";

export default function useTeamMembershipController() {
  const team = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV.value);
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const [showDissociateProjectModal, setShowDissociateProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(castTo<IProjectDetails>([]));
  const dissociateProjectPES = useSelectorTyped((state) => state.teamDetailsPage.dissociateProjectPES);
  const associateProjectProgress = useSelectorTyped((state) => state.teamDetailsPage.associateProjectsPES.progress);
  const dissociateProjectProgress = useSelectorTyped((state) => state.teamDetailsPage.dissociateProjectPES.progress);
  const updateTeamMemberRoleProgress
        = useSelectorTyped((state) => state.teamDetailsPage.updateTeamMemberRolePE.progress);
  const dispatch = useDispatch();

  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const { unificationConfig } = getEnvVars();
  const environmentConfig = unificationConfig.environmentSpecificConfig;

  useEffect(() => {
    if (dissociateProjectPES.success) {
      setShowDissociateProjectModal(false);
    }
  }, [dissociateProjectPES]);

  const isDissociateProjectInProgress = !!dissociateProjectPES.progress;

  const shouldRenderProjectAssociation = () =>
    shouldDisableManageProjectOptions()
      ? false
      : (team?.teamType === TeamType.OTHERS || team?.teamType === TeamType.GITS) && projectAssociationToggleEnabled;

  const shouldDisableManageProjectOptions = () => isApiUnificationToggleEnabled && environmentConfig.isSandbox;

  return ({
    teamDetails: team,
    projectAssociationToggleEnabled,
    showDissociateProjectModal,
    selectedProject,
    isDissociateProjectInProgress,
    isDissociateProjectIconDisabled: !!updateTeamMemberRoleProgress || !!associateProjectProgress,
    shouldRenderProjectAssociation,
    noProjectAssociationMessage: "Not associated to any Staffing project.",

    getAssociatedProjects: () => team?.projects?.map((project) => project.name),
    shouldRenderNoProjectAssociationMessage: () => !team?.projects || team?.projects?.length === 0,
    onClickDissociateIcon: (project: IProjectDetails) => {
      setSelectedProject(project);
      setShowDissociateProjectModal(true);
    },
    onClickCancelButton: () => {
      setShowDissociateProjectModal(false);
    },
    onClickDissociateButton: () => {
      Analytics.trackEventDissociateProject();
      dispatch(TeamDetailsPageActions.dissociateProjectFromTeam({

        teamId: team!.id,
        projectId: selectedProject.id,
      }));
    },
    getPrimaryButtonLabel: isDissociateProjectInProgress ? "Dissociating" : "Dissociate",
    shouldRenderAssociateProjectButton: () => shouldRenderProjectAssociation() && team?.canManageProjects,
    isAssociateProjectButtonDisabled: () => !!dissociateProjectProgress || !!associateProjectProgress,
    onClickAssociateProjectButton: () => dispatch(TeamDetailsPageActions.displayProjectSelection(true)),
    shouldRenderDissociateProjectButton: () => shouldRenderProjectAssociation() && team?.canManageProjects,
  });
}
