import React, { FunctionComponent } from "react";
import HeaderRow from "./header-row";
import Row, { OfferAvailabilities, OfferAvailabilityStatus } from "./row";
import GITSTooltipInfo from "./gits-tooltip-info";
import OthersTooltipInfo from "./others-tooltip-info";
import ExperimentAndInnovationToolTipInfo from "./experiment-innovation-tooltip-info";
import "./index.scss";

const OfferingsTable: FunctionComponent = () => {
  const gitsOffersAvailabilities: OfferAvailabilities = [
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Github Enterprise organisation" },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Private CircleCI pipeline" },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE },
  ];

  const OthersOffersAvailabilities: OfferAvailabilities = [
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Github Enterprise organisation" },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Private CircleCI pipeline" },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.NOT_AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE },
  ];

  const experimentAndInnovationOffersAvailabilities: OfferAvailabilities = [
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Private Github Repository on twlabs organisation" },
    { available: OfferAvailabilityStatus.AVAILABLE, text: "100 US$ credits/month" },
    { available: OfferAvailabilityStatus.AVAILABLE, text: "Pipeline in shared organisation" },
    { available: OfferAvailabilityStatus.AVAILABLE },
    { available: OfferAvailabilityStatus.NOT_AVAILABLE },
    { available: OfferAvailabilityStatus.AVAILABLE },
  ];

  return (
    <table className="InfrastructureOfferingsTable">
      <thead>
        <HeaderRow />
      </thead>
      <tbody>
        <Row
          headerColumnText="EXPERIMENTS AND INNOVATIONS"
          tooltipContent={<ExperimentAndInnovationToolTipInfo />}
          offerAvailabilities={experimentAndInnovationOffersAvailabilities}
        />
        <Row
          headerColumnText="OTHERS"
          tooltipContent={<OthersTooltipInfo />}
          offerAvailabilities={OthersOffersAvailabilities}
        />
        <Row
          headerColumnText="GITS"
          tooltipContent={<GITSTooltipInfo />}
          offerAvailabilities={gitsOffersAvailabilities}
        />
      </tbody>
    </table>
  );
};

export default OfferingsTable;
