import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../../utils/nullable";
import { IProject } from "../../../common/redux/slices/projects";

export interface IBasicInfo {
  "projectName": string;
  "projectDescription": string;
  "purpose": string;
  "projects"?: IProject[]; // TODO: make it mandatory
  "country": string;
  "countryCode": string;
  "targetAudience": string;
  "businessFunction": string;
  "projectCategory": string[];
  "projectCategory-Comment": string;
  "projectOwner": string[];
  "techStack": string[];
  "techStack-Comment": string;
  "teamId": string;
  "teamName": string;
  "hasMnpi": Nullable<boolean>;
  "hasPii": Nullable<boolean>;
  "hasSensitivePii": Nullable<boolean>;
  "endDate": DateOnly;
  "billingProjectId"?: Nullable<string>;
  "billingProjectName"?: Nullable<string>;
}

export interface IInfraConfig {
  isCodeRepositoryNeeded: string;
  isCiCdPipelineNeeded: string;
  githubRepos: Array<{ name: string }>;
  isHostingServiceNeeded: string;
  gcpProjectName: string;
  gcpProjects: Array<{ name: string }>;
  comment: string;
}

export type IExperimentsAndInnovationsFormData = {
  basicInfo: IBasicInfo;
  infraConfig: IInfraConfig;
};

export const experimentsAndInnovationsInitialState: IExperimentsAndInnovationsFormData = {
  basicInfo: {
    projectName: "",
    projectDescription: "",
    country: "",
    countryCode: "",
    targetAudience: "",
    businessFunction: "",
    projectCategory: [],
    "projectCategory-Comment": "",
    projectOwner: [],
    techStack: [],
    "techStack-Comment": "",
    teamId: "",
    teamName: "",
    purpose: "",
    projects: [],
    hasMnpi: null,
    hasPii: null,
    hasSensitivePii: null,
    endDate: "",
  },
  infraConfig: {
    isCodeRepositoryNeeded: "",
    githubRepos: [],
    gcpProjects: [],
    isCiCdPipelineNeeded: "",
    isHostingServiceNeeded: "",
    gcpProjectName: "",
    comment: "",
  },
};

const experimentsAndInnovationsSlice = createSlice({
  name: "getStartedPage/experimentsAndInnovations",
  initialState: experimentsAndInnovationsInitialState,
  reducers: {
    updateInfraConfig: (state: IExperimentsAndInnovationsFormData, action: PayloadAction<Partial<IInfraConfig>>) => {
      state.infraConfig = {
        ...state.infraConfig,
        ...action.payload,
      };
    },
    updateBasicInfo: (state: IExperimentsAndInnovationsFormData, action: PayloadAction<Partial<IBasicInfo>>) => {
      state.basicInfo = {
        ...state.basicInfo,
        ...action.payload,
      };
    },
  },
});

export const experimentsAndInnovationsReducer = experimentsAndInnovationsSlice.reducer;

const updateInfraConfig: ActionCreatorWithPayload<Partial<IInfraConfig>>
  = experimentsAndInnovationsSlice.actions.updateInfraConfig;

const updateBasicInfo: ActionCreatorWithPayload<Partial<IBasicInfo>>
  = experimentsAndInnovationsSlice.actions.updateBasicInfo;

export const experimentsAndInnovationsCategoryActions = {
  updateInfraConfig,
  updateBasicInfo,
};
