import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { TeamDetailsPageActions } from "./redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TeamsPageActions } from "../teams/redux";
import Analytics from "../../common/services/analytics";
import { useGetFeatureToggleState } from "../../common/hooks/feature-toggles";
import { useSelectorTyped } from "../../common/redux/store";
import { TeamCreationPageActions, TeamType } from "../create-team/redux";
import { getEnvVars } from "../../common/config/env";

export type TeamDetailsProps = RouteComponentProps<{ teamId: string }>;

export default function useTeamDetailsController(props: TeamDetailsProps) {
  const teamId = props.match.params.teamId;
  const { value: teamDetails, error: teamDetailsError, progress: teamDetailsProgress }
    = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV);
  const isTeamDetailsInEditMode = useSelectorTyped((state) => state.teamDetailsPage.isTeamDetailsInEditMode);
  const dispatch = useDispatch();
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const history = useHistory();
  const [showDeactivateTeamWarningModal, setShowDeactivateTeamWarningModal] = useState(false);
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const { value: teamApplications, error: teamApplicationsError, progress: teamApplicationsProgress }
    = useSelectorTyped((state) => state.teamDetailsPage.applicationsPEV);
  const { value: apiClients, error: apiClientsError, progress: apiClientsProgress }
    = useSelectorTyped((state) => state.teamDetailsPage.apiClientsPEV);
  const shouldRenderDeactivateTeamModalContent = () => teamApplications && !teamApplicationsError && !teamApplicationsProgress
    && apiClients && !apiClientsProgress && !apiClientsError;
  const deactivateTeamPES = useSelectorTyped((state) => state.teamDetailsPage.deactivateTeamPES);

  const { unificationConfig } = getEnvVars();
  const { value: uiConfig } = useSelectorTyped((state) => state.header.uiConfigsPEV);

  const shouldRenderDeactivateTeamModalProgress = () => teamApplicationsProgress && teamApplications.length === 0
    && apiClientsProgress && apiClients.length === 0;
  const shouldRenderDeactivateTeamModalError = () => teamApplicationsError && !teamApplicationsProgress
    && apiClientsError && !apiClientsProgress;

  useEffect(() => {
    Analytics.trackPageTeamDetails();
    dispatch(TeamDetailsPageActions.fetchTeamDetails(teamId));
    dispatch(TeamsPageActions.fetchAllPotentialMembers());
    dispatch(TeamDetailsPageActions.updateTeamDetailsEditableState(false));
    dispatch(TeamDetailsPageActions.fetchApplicationsOfTeam(teamId));
    dispatch(TeamDetailsPageActions.fetchActivitiesOfTeam(teamId));
    dispatch(TeamDetailsPageActions.displayProjectSelection(false));
    dispatch(TeamDetailsPageActions.fetchApiClientsOfTeam(teamId));

    if (deactivateTeamPES.success) {
      setShowDeactivateTeamWarningModal(false);
      dispatch(TeamDetailsPageActions.resetDeactivateTeamPES());
      if (teamDetails) {
        dispatch(TeamDetailsPageActions.fetchActivitiesOfTeam(teamDetails.id));
      }
    }
  }, [deactivateTeamPES]);

  useEffect(function scrollWindowToTop() {
    window.scrollTo(0, 0);
  }, [isTeamDetailsInEditMode]);

  useEffect(
    () => {
      if (projectAssociationToggleEnabled && (teamDetails && teamDetails.teamType !== TeamType.EXPERIMENT_AND_INNOVATION)) {
        dispatch(TeamCreationPageActions.getProjects({
          isEmployeeIdsNotRequired: true,
        }));
      }
    }, [projectAssociationToggleEnabled, teamDetails]);

  const errorText = teamDetailsError;

  const progressText = teamDetailsProgress;

  const shouldRenderTeamDetails
    = () => teamDetails !== null;

  const shouldRenderProgressMessage = () => teamDetails === null && teamDetailsProgress !== null;
  const shouldRenderDeactivateTeamButton = () => teamDetails && teamDetails.canDeactivateTeam;
  const onClickDeactivateButton = () => setShowDeactivateTeamWarningModal(true);

  const onClickConfirmDeactivateTeam = () => {
    if (!doesTeamHaveActiveApplications() && teamDetails) {
      Analytics.trackEventDeactivateTeam();
      dispatch(TeamDetailsPageActions.deactivateTeam(teamDetails.id));
      setShowDeactivateTeamWarningModal(true);
    }
  };

  function doesTeamHaveActiveApplications() {
    return teamApplications.some((application) => uiConfig.activeApplicationStatuses.includes(application.status));
  }

  const getDeactivateTeamWarningModalMessage = () => {
    let message = "";
    if (apiClients.length > 0) {
      message = `${apiClients.length} client(s) are associated to this team. `;
    }
    return `${message}Once the team is deactivated, no one can access the team or retrieve it again.`;
  };

  const shouldRenderActiveApplicationsWarningContent = () => doesTeamHaveActiveApplications();

  const shouldRenderErrorMessage = () => !!teamDetailsError;

  const onRetryButtonClick = () => {
    dispatch(TeamDetailsPageActions.fetchTeamDetails(teamId));
  };

  function navigateBack() {
    history.go(-1);
  }

  const onClickBackButton = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (isTeamDetailsInEditMode) {
      dispatch(TeamDetailsPageActions.updateTeamDetailsEditableState(false));
    }
    else {
      navigateBack();
    }
  };

  return ({
    teamDetails,
    errorText,
    progressText,
    shouldRenderTeamDetails,
    onRetryButtonClick,
    isTeamDetailsInEditMode,
    onClickBackButton,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    shouldRenderDeactivateTeamButton,
    onClickDeactivateButton,
    showDeactivateTeamWarningModal,
    environmentConfig: unificationConfig.environmentSpecificConfig,
    isApiUnificationToggleEnabled,
    shouldRenderDeactivateTeamModalContent,
    shouldRenderModalPrimaryButton: () => shouldRenderDeactivateTeamModalContent(),
    onClickConfirmDeactivateTeam,
    getConfirmButtonLabel: () => doesTeamHaveActiveApplications() ? "OK" : (deactivateTeamPES.progress ? "Deactivating" : "Deactivate"),
    shouldDisableDialogButtons: () => !!deactivateTeamPES.progress,
    shouldRenderActiveApplicationsWarningContent,
    shouldRenderModalSecondaryButton: () => !shouldRenderActiveApplicationsWarningContent(),
    onHideDeactivateTeamWarningModal: () => setShowDeactivateTeamWarningModal(false),
    shouldRenderDeactivateTeamModalProgress,
    shouldRenderDeactivateTeamModalError,
    getDeactivateTeamWarningModalMessage,
    deactivateTeamProgressText: teamApplicationsProgress ?? apiClientsProgress,
    deactivateTeamErrorText: teamApplicationsError ?? apiClientsError,
  });
}
