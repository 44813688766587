import React, { FunctionComponent, ReactNode } from "react";
import "./index.scss";
import { invokeImmediately } from "../../../utils/functions";

type Props = {
  "ariaLabel": string;
  "onClick"?: () => void;
  "className"?: string;
  "disabled"?: boolean;
  "children"?: ReactNode;
  "id"?: string;
  "data-testid"?: string;
  "clearDefaultStyles"?: boolean;
};

const ImpactXIconButton: FunctionComponent<Props> = (props: Props) => (
  <button
    className={invokeImmediately(function getClassName() {
      if (props.clearDefaultStyles) {
        return props.className;
      }
      return `impact-x-icon-button ${props.className || ""}`;
    })}
    aria-label={props.ariaLabel}
    title={props.ariaLabel}
    onClick={props.onClick}
    disabled={props.disabled}
    type="button"
    ix-styles="outline"
    id={props.id}
    data-testid={props["data-testid"]}
  >
    {props.children}
  </button>
);

export default ImpactXIconButton;
