import { callBffApi } from "../../common/services/axios";
import { ActivityHistory, IAddTeamMembersForm, IApiClient, ITeam, IUpdateTeamRequest, JoinTeamRequest } from "./redux";
import { toast } from "react-toastify";
import { ApplicationBasicInfo } from "../applications/application-details/redux";
import { IProject } from "../../common/redux/slices/projects";

export const fetchTeamDetails = async (teamId: string) => callBffApi<ITeam>("get", `/teams/${teamId}`);

export const updateTeamDetails = async (teamId: string, teamDetails: IUpdateTeamRequest): Promise<ITeam> => {
  const teamNameAlreadyExistError = "Team name already exists";

  function getErrorMessageForToast(e: Error) {
    return e.message === teamNameAlreadyExistError
      ? teamNameAlreadyExistError
      : `Error occurred in updating the details! Please try again. (${e.message})`;
  }

  try {
    const teamInformation = await callBffApi<ITeam>("put", `/teams/${teamId}`, teamDetails);
    toast.success("Team details updated successfully");
    return teamInformation;
  }
  catch (error) {
    toast.error(getErrorMessageForToast(error as Error));
    throw error;
  }
};

export const addMembersToTeam = async (teamId: string, membersData: IAddTeamMembersForm): Promise<Partial<ITeam>> => {
  try {
    const teamInformation = await callBffApi<ITeam>("post", `/teams/${teamId}/members/bulk`, membersData);
    toast.success("Members added to the team successfully");
    return teamInformation;
  }
  catch (error) {
    toast.error(`Error occurred while adding members to the team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const joinTeam = async (teamId: string, membersData: JoinTeamRequest): Promise<Partial<ITeam>> => {
  try {
    const teamInformation = await callBffApi<ITeam>("post", `/teams/${teamId}/member/`, membersData);
    toast.success("You are joined to the team.");
    return teamInformation;
  }
  catch (error) {
    toast.error(`Error occurred while joining the team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const updateTeamMemberRole = async (teamId: string, employeeId: string, role: string) => {
  try {
    const teamMembersInformation = await callBffApi<ITeam>("put", `/teams/${teamId}/members/${employeeId}`, { role });
    toast.success("Member role updated successfully.");
    return teamMembersInformation;
  }
  catch (error) {
    toast.error(`Error occurred while updating member role! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

type ApplicationsResponse = {
  data: ApplicationBasicInfo[];
};

export const fetchApplicationsOfTeam = async (teamId: string) => {
  const response = await callBffApi<ApplicationsResponse>("get", `/applications?teamId=${teamId}`);
  return response.data;
};

export const fetchApiClientsOfTeam = async (teamId: string) => {
  const response = await callBffApi<{ data: IApiClient[] }>("get", `/teams/${teamId}/apiClients`);
  return response.data;
};

export const removeMemberFromTeam = async (teamId: string, employeeId: string) => {
  try {
    const response = await callBffApi<ITeam>("delete", `/teams/${teamId}/members/${employeeId}`);
    toast.success("Member has been removed successfully.");
    return response;
  }
  catch (error) {
    toast.error(`Error occurred while removing member from team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const associateProjectsToTeam = async (teamId: string, projectIds: Array<IProject["id"]>) => {
  try {
    const response = await callBffApi<ITeam>(
      "put",
      `/teams/${teamId}/projects/associate`,
      { projectIds },
    );
    toast.success("Projects associated successfully.");
    return response;
  }
  catch (error) {
    toast.error(`Projects association failed! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const dissociateProjectFromTeam = async (teamId: string, projectId: IProject["id"]) => {
  try {
    const response = await callBffApi<ITeam>(
      "delete",
      `/teams/${teamId}/projects/${projectId}`,
    );
    toast.success("Project dissociated successfully.");
    return response;
  }
  catch (error) {
    toast.error(`Project dissociation failed! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const exitTeam = async (teamId: string, employeeId: string) => {
  try {
    const response = await callBffApi<ITeam>("delete", `/teams/${teamId}/members/${employeeId}`);
    toast.success("You have exited the team successfully");
    return response;
  }
  catch (error) {
    toast.error(`Error occurred while exiting the team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const deactivateTeam = async (teamId: string) => {
  try {
    const response = await callBffApi<{ data: ITeam }>("post", `/teams/${teamId}/deactivate`);
    toast.success("Team deactivated successfully.");
    return response.data;
  }
  catch (error) {
    toast.error(`Error occurred while deactivating team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const removeMultipleMembersFromTeam = async (teamId: string, employeeIds: string[]) => {
  try {
    const response = await callBffApi<ITeam>(
      "delete",
      `/teams/${teamId}/members/bulk`,
      { employeeIds },
    );
    toast.success("Members removed from the team successfully.");
    return response;
  }
  catch (error) {
    toast.error(`Error occurred while removing members from team! Please try again. (${(error as Error).message})`);
    throw error;
  }
};

export const fetchActivitiesOfTeam = async (teamId: string) => {
  const response = await callBffApi<{ data: ActivityHistory[] }>("get", `/teams/${teamId}/events`);
  return response.data;
};
