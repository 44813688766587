import YesNoChoices from "../../../data/yes-no-choice";
import { replaceInvalidGitHubRepoNameCharactersWithHyphens } from "../../../utils/process-fields";
import {
  getGcpProjectNameValidatorConfig,
  getGcpValidatorConfig,
  getGithubRepoNameValidatorConfig,
  getGithubReposValidatorConfig,
} from "../../../utils/validator";
import NeoLink from "../../../../../common/components/neo-link";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { thoughtworksApiUserGuideUrl } from "../../../data/urls";
import "../index.scss";

const renderApiGuideLink = () => (
  <div>
    Please follow the instructions on the&nbsp;
    <NeoLink
      className="tw-api-url"
      link={thoughtworksApiUserGuideUrl}
      external
    >
      API User Guide
    </NeoLink>
    , if you are looking for APIs of Thoughtworks systems.
  </div>
);

const renderGcpCostAlert = () => (
  <div className="cost-alert">
    <i className="ri-information-fill" />
    <div className="alert-message">
      All GCP projects (Production & Non-production) associated to this application will be disabled when the sum of all GCP projects exceeds the
      $100 monthly budget.
    </div>
  </div>
);

const getQuestions = (
  applicationName: string,
  hideGcpCostAlert: boolean,
  isDisplayGCPBudgetAlertToggleEnabled: boolean,
) => {
  const githubRepoPrefix = replaceInvalidGitHubRepoNameCharactersWithHyphens(applicationName);
  return [
    {
      type: "panel",
      title: "Code Repository",
      elements: [
        {
          type: "radiogroup",
          name: "isCodeRepositoryNeeded",
          title: "Do you need GitHub repository?",
          isRequired: true,
          colCount: 1,
          choices: [
            YesNoChoices.YES,
            YesNoChoices.NO,
          ],
        },
        {
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 5,
          rowCount: 1,
          width: "max-content",
          name: "githubRepos",
          title: "Repository names (upto 5 repositories)",
          addRowText: "+ Add Repo",
          removeRowText: "\t",
          visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.YES}'`,
          columns: [
            {
              name: "name",
              width: 600,
              description: `pre--${githubRepoPrefix}-`,
              cellType: "text",
              validators: getGithubRepoNameValidatorConfig(),
            },
            {
              name: "dummy-field",
              description: "This is needed for github repo to allow empty values",
              defaultValue: "dummy-value",
              visible: "disabled",
            },
          ],
          validators: getGithubReposValidatorConfig(),
        },
        {
          type: "radiogroup",
          name: "isCiCdPipelineNeeded",
          title: "Do you need CI/CD pipeline (CircleCI)?",
          colCount: 1,
          choices: [
            YesNoChoices.YES,
            YesNoChoices.NO,
          ],
          visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.YES}'`,
          isRequired: true,
        }],
    },
    {
      type: "panel",
      title: "Cloud Provider",
      elements: [
        {
          type: "radiogroup",
          name: "isHostingServiceNeeded",
          title: "Do you need GCP project?",
          choices: [
            YesNoChoices.YES,
            YesNoChoices.NO,
          ],
          isRequired: true,
        },
        (!hideGcpCostAlert && isDisplayGCPBudgetAlertToggleEnabled) && {
          type: "html",
          html: ReactDOMServer.renderToString(renderGcpCostAlert()),
          visibleIf: `{isHostingServiceNeeded}='${YesNoChoices.YES}'`,
        },
        {
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 5,
          rowCount: 1,
          width: "max-content",
          name: "gcpProjects",
          title: "GCP project names (upto 5 projects)",
          description: "Use the word \"prod\" if the project is for a production "
            + "environment. E.g. app-name-prod.",
          addRowText: "+ Add Project",
          removeRowText: "\t",
          visibleIf: `{isHostingServiceNeeded}='${YesNoChoices.YES}'`,
          columns: [
            {
              name: "name",
              width: 600,
              cellType: "text",
              defaultValue: applicationName,
              isRequired: true,
              requiredErrorText: "Required",
              validators: getGcpProjectNameValidatorConfig(),
            },
          ],
          validators: getGcpValidatorConfig(),
        },
      ],
    },
    {
      type: "panel",
      title: "Other requirements",
      elements: [
        {
          type: "comment",
          name: "comment",
          title: "Describe your requirement, if you didn't find what you are looking for.",
        },
      ],
      visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.NO}' and {isHostingServiceNeeded}='${YesNoChoices.NO}'`,
    },
    {
      type: "html",
      html: ReactDOMServer.renderToString(renderApiGuideLink()),
      visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.NO}' and {isHostingServiceNeeded}='${YesNoChoices.NO}'`,
    },
  ];
};

export default getQuestions;
