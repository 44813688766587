import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { iif } from "../../../utils/functions";
import Analytics from "../../services/analytics";
import { getEnvVars } from "../../config/env";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";

export type MenuItemProps = {
  id: string;
  title: string;
  route: string;
  highlightTabsForAdditionalRoutes?: string[];
};

export type SubHeaderProps = {
  menuItems: MenuItemProps[];
  headerTitle: string;
  backgroundColor: string;
};

interface MenuChange {
  detail: { id: string; title: string };
}

export default function useSubHeaderController(props: SubHeaderProps) {
  const [activeMenuId, setActiveMenuId] = useState(props.menuItems[0].id);
  const history = useHistory();
  const { pathname } = useLocation();
  const { unificationConfig } = getEnvVars();
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");

  useEffect(() => {
    const activeMenu = iif(function findActiveMenu() {
      const activeMenuFoundFromMenuItemsRoute = iif(function findByMenuItemsRoute() {
        return props.menuItems.find((item) => pathname === item.route);
      });
      return activeMenuFoundFromMenuItemsRoute
        ? activeMenuFoundFromMenuItemsRoute
        : iif(function findByMenuItemsAdditionalRoutes() {
          return props.menuItems.find((item) => {
            if (item.highlightTabsForAdditionalRoutes) {
              return item.highlightTabsForAdditionalRoutes.some((route) => {
                const urlRegExpAcceptingAnyPathParameter = new RegExp(`${route.replace(new RegExp(":([^/]+)", "g"), "(\\w|\\d|-|_)+")}$`);
                return urlRegExpAcceptingAnyPathParameter.test(pathname);
              });
            }
            return false;
          });
        });
    });
    setActiveMenuId(activeMenu?.id ?? activeMenuId);
  }, [pathname]);

  const handleTabClick = (event: MenuChange) => {
    Analytics.trackEventClickSubHeaderTab(props.headerTitle, event.detail.title);
    const clickedMenuId = event.detail.id;
    setActiveMenuId(clickedMenuId);
    const activeMenu = props.menuItems.find((item) => item.id === clickedMenuId);
    if (activeMenu?.route) {
      history.push(activeMenu?.route);
    }
  };

  return ({
    ...props,
    isApiUnificationToggleEnabled,
    handleTabClick,
    activeMenuId,
    environmentConfig: { ...unificationConfig.environmentSpecificConfig },
  });
}
