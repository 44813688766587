import React, { FunctionComponent } from "react";
import "./index.scss";

export type Props = {
  hideApplicationSpecificInfo?: boolean;
};

const ExperimentAndInnovationInfo: FunctionComponent<Props> = (props: Props) => {
  const renderInfraSupport = () => {
    if (!props.hideApplicationSpecificInfo) {
      return (
        <div className="infra-support">
          <div className="title">
            Infrastructure support
          </div>
          <div className="infra-details">
            <div className="detail">
              <div className="detail-field">Github</div>
              <div className="detail-value">Private Github repo</div>
            </div>
            <div className="detail">
              <div className="detail-field">GCP</div>
              <div className="detail-value">100 US$ credits/month</div>
            </div>
            <div className="detail">
              <div className="detail-field">Circle CI</div>
              <div className="detail-value">Pipeline in shared organisation</div>
            </div>
            <div className="detail">
              <div className="detail-field">OKTA</div>
              <div className="detail-value">Single sign on</div>
            </div>
            <div className="detail">
              <div className="detail-field">Matomo</div>
              <div className="detail-value">Analytics tool</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  function renderAdditionalNotes() {
    if (!props.hideApplicationSpecificInfo) {
      return (
        <>
          <br />
          <br />
          <strong>*For Thoughtworks internal usage.</strong>
        </>
      );
    }
    return null;
  }

  return (
    <div className="project-classification-content">
      <div className="team-type-details">
        For teams that are members of the beach, working on a non-sensitive and non-public POC for a client, or would like to experiment with and
        improve our internal systems and processes.
        {renderAdditionalNotes()}
      </div>
      {renderInfraSupport()}
    </div>
  );
};

export default ExperimentAndInnovationInfo;
