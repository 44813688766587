import React, { FunctionComponent, HTMLProps } from "react";
import "./index.scss";

type Props = HTMLProps<HTMLInputElement>;

const CheckBox: FunctionComponent<Props> = (props: Props) => (
  <div className="CheckBox">
    <input
      {...props}
      type="checkbox"
    />
    <span />
  </div>
);

export default CheckBox;
