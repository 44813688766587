import React, { FunctionComponent } from "react";
import HeaderDropdownMenu from "../dropdown-menu";
import "./index.scss";
import useHeaderMenuController from "./controller";
import { Pages } from "../../app/pages";
import { IMicroFrontendHeaderMenus } from "../../../data/microfrontend-types";

const HeaderMenus: FunctionComponent = () => {
  const controller = useHeaderMenuController();

  function renderEventsMenu() {
    return (
      <HeaderDropdownMenu
        dropdownItems={[]}
        label="Events"
        url="/events"
      />
    );
  }

  function renderMffMenus(menus: IMicroFrontendHeaderMenus | null) {
    return menus?.map((menu) => (
      <HeaderDropdownMenu
        key={menu.label}
        dropdownItems={menu.dropdownItems || []}
        label={menu.label}
        url={menu.url}
      />
    ),
    ) || null;
  }

  function renderCatalogueMenu() {
    return (
      <HeaderDropdownMenu
        dropdownItems={[]}
        label={Pages.CATALOGUE.title}
        url={Pages.CATALOGUE.path}
      />
    );
  }

  return (
    <div className="HeaderMenus">
      {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderCatalogueMenu()}
      {renderMffMenus(controller.apiGwMenus)}
      {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderEventsMenu()}
      {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderMffMenus(controller.dataCatalogueMenus)}
      {renderMffMenus(controller.masterDataMenus)}
      {controller.isAdrMicroFrontendToggleEnabled && renderMffMenus(controller.adrMenus)}
      {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderMffMenus(controller.fourKeyMetricsMenus)}
    </div>
  );
};

export default HeaderMenus;
