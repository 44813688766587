import { getEditTeamQuestions } from "./data/questions";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { IEditTeamForm, ITeam, IUpdateTeamRequest, TeamDetailsPageActions } from "../redux";
import Analytics from "../../../common/services/analytics";
import { extractFieldValues } from "../../../utils/extractor";
import { iif } from "../../../utils/functions";
import { getCollaborationType } from "../../../utils/teams/functions";

export function useEditTeamDetailsController() {
  const teamDetailsPEV = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV);
  const updateTeamDetailsPE = useSelectorTyped((state) => state.teamDetailsPage.editTeamDetailsPE);
  const teamListPEV = useSelectorTyped((state) => state.teamsPage.teamsListPEV);

  const teamDetails = teamDetailsPEV.value!;
  const dispatch = useDispatch();

  const onSave = ({ isOpen, ...formData }: IEditTeamForm) => {
    const teamInformation: IUpdateTeamRequest = {
      ...formData,
      name: formData.name.trim(),
      contactEmail: `${formData.contactEmail?.trim()}@thoughtworks.com`,
      supportEmail: `${formData.supportEmail?.trim()}@thoughtworks.com`,
      isOpen,
    };

    dispatch(TeamDetailsPageActions.updateTeamDetails({
      teamId: teamDetails.id,
      teamDetails: teamInformation,
    }));
    Analytics.trackEventEditAndSaveTeamDetails();

    iif(function trackEventOnlyIfCollaborationTypeIsChanged() {
      if (teamDetails.isOpen !== isOpen) {
        Analytics.trackEventUpdateTeamCollaborateType(getCollaborationType(isOpen));
      }
    });
  };

  const getEmailPrefixFromEmailId = (email: string | undefined) => email?.split("@")[0] || "";

  const getFormData = () => {
    const { name, contactEmail, supportEmail, description, isOpen } = teamDetails;
    const teamInformation: IEditTeamForm = {
      contactEmail: getEmailPrefixFromEmailId(contactEmail),
      supportEmail: getEmailPrefixFromEmailId(supportEmail),
      description,
      name,
      isOpen,
    };
    return teamInformation;
  };

  const shouldRenderForm = () => !teamDetailsPEV.error
    && teamDetails
    && (!teamListPEV.progress && !teamListPEV.error);

  const onDiscard = () => {
    dispatch(TeamDetailsPageActions.updateTeamDetailsEditableState(false));
  };

  const questions = getEditTeamQuestions({
    teamNames: extractFieldValues<ITeam, string>("name", teamListPEV.value),
    currentTeamName: teamDetails.name,
    teamType: teamDetails.teamType,
  });

  const isEditTeamInProgress = () => !!updateTeamDetailsPE.progress;

  const getSaveButtonLabel = () => isEditTeamInProgress() ? "Saving" : "Save";

  const getProgressMsg = () => {
    const shouldDisplayProgress = teamDetailsPEV.progress !== null;
    return shouldDisplayProgress ? "Fetching details" : null;
  };

  const shouldRenderError = () => !!teamDetailsPEV.error;

  const getErrorMsg = () => teamDetailsPEV.error;

  return ({
    questions,
    onSave,
    getFormData,
    shouldRenderForm,
    getSaveButtonLabel,
    isEditTeamInProgress,
    onDiscard,
    getProgressMsg,
    shouldRenderError,
    getErrorMsg,
  });
}
