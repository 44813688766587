import React from "react";
import useJoinTeamController from "./controller";
import NeoModalDialog from "../neo-modal-dialog";
import "./index.scss";
import FormComponent, { INavigationButtonsProps } from "../forms";
import { NavigationButtons } from "../forms/navigation-buttons";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import Progress from "../progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../error-new";

const JoinTeamModal = createFunctionalComponentFromView(
  useJoinTeamController,
  (controller) => {
    function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
      return (
        <NavigationButtons
          nextButtonLabel={controller.getJoinButtonLabel()}
          previousButtonLabel="Cancel"
          getFormData={getFormData}
          doesFormHasErrors={doesFormHasErrors}
          onClickNext={controller.onClickJoinButton}
          onClickPrevious={controller.onCancel}
          isNextButtonDisabled={controller.isJoinTeamInProgress}
          isPreviousButtonDisabled={controller.isJoinTeamInProgress}
        />
      );
    }

    const renderForm = () => {
      if (controller.shouldRenderForm()) {
        return (
          <FormComponent
            questions={controller.questions}
            data={controller.getInitialFormData()}
            renderNavigationButtons={renderNavigationButtons}
          />
        );
      }
    };

    function renderError() {
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMessage()!)}
            buttonLabel="Close"
            onClick={controller.onCloseModal}
          />
        );
      }
    }

    return (
      <NeoModalDialog
        dialogClassName="JoinTeamModel"
        dialogTitle="Join Team"
        show={controller.showModal}
        onHide={controller.onCloseModal}
      >
        {renderForm()}
        <Progress text={controller.getProgressMessage()} />
        {renderError()}
      </NeoModalDialog>
    );
  },
);

export default JoinTeamModal;
