// todo why is this?

import React, { forwardRef, useImperativeHandle, useRef } from "react";
import "./index.scss";

import * as Survey from "survey-react";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";
import showdown from "showdown";
import * as widgets from "surveyjs-widgets";
import "select2/dist/css/select2.css";
import "select2/dist/js/select2.js";
import checkBox from "./custom-widgets/checkbox";
import radioGroup from "./custom-widgets/radio-group";
import isEqual from "lodash.isequal";
import inputBox from "./custom-widgets/input-box";
import castTo from "../../../utils/cast-to";
import { Nullable } from "../../../utils/nullable";

export interface INavigationButtonsProps {
  doesFormHasErrors: () => boolean;
  getFormData: () => any;
}

export type OnValueChangedOptions = {
  name: string;
  question: Survey.Question;
};

export type onAfterQuestionRenderedOptions = {
  question: Survey.Question;
  htmlElement: HTMLElement;
};

type Props = {
  questions: any[];
  data?: any;
  layoutConfig?: any;
  renderNavigationButtons: (props: INavigationButtonsProps) => Nullable<React.ReactElement>;
  handleValueChanged?: (sender: Survey.SurveyModel, options: OnValueChangedOptions) => void;
  renderAdditionalContent?: () => React.ReactElement;
  handleOnAfterRenderQuestion?: (sender: Survey.SurveyModel, options: onAfterQuestionRenderedOptions) => void;
};

export type FormRef = {
  reset: () => void;
};

const styles = {
  text: "impactx-input",
  question: {
    title: "survey-title",
  },
  matrixdynamic: {
    buttonAdd: "add-btn", // css style
    buttonRemove: "remove-btn",
    // "iconAdd": "ri-information-fill",
    iconRemove: "ri-delete-bin-2-line ri-xl",
  },
};

const MemoizedSurvey = React.memo(
  Survey.Survey,
  (prevProps, nextProps) => isEqual(prevProps.css, nextProps.css)
    && isEqual(prevProps.model.extraPropContent, nextProps.model.extraPropContent)
    && isEqual(prevProps.model.data, nextProps.model.data)
    && isEqual(prevProps.onValueChanged, nextProps.onValueChanged)
    && isEqual(prevProps.onAfterRenderQuestion, nextProps.onAfterRenderQuestion),
);

function addWidgetsAndInitialize() {
  // temporarily disable custom input box until the performance issues are fixed
  Survey.CustomWidgetCollection.Instance.addCustomWidget(inputBox);
  Survey.CustomWidgetCollection.Instance.addCustomWidget(checkBox);
  Survey.CustomWidgetCollection.Instance.addCustomWidget(radioGroup);

  (window as unknown as { $: unknown }).$ = (window as unknown as { jQuery: unknown }).jQuery = $;
  widgets.select2tagbox(Survey);
  widgets.select2(Survey);
  widgets.jqueryuidatepicker(Survey);
}

function processMarkdown() {
  return function(survey: Survey.SurveyModel, options: any) {
    const converter: showdown.Converter = new showdown.Converter();
    let str = converter.makeHtml(options.text);
    str = str.slice(3);
    str = str.slice(0, Math.max(0, str.length - 4));
    options.html = str;
  };
}

function addRequiredText() {
  return function(survey: Survey.SurveyModel, options: Survey.SurveyModel) {
    const requiredText = " <span style='color: red;'>*</span>";

    const isRequiredTextNotPresent = () => !options.question.title.includes(requiredText);

    if (options.question.isRequired) {
      if (!options.question.requiredErrorText) {
        options.question.requiredErrorText = "Required";
        options.question.otherErrorText = "Required";
      }
      if (isRequiredTextNotPresent()) {
        options.question.title += requiredText;
      }
    }
  };
}

function customizeMinAndMaxErrorText() {
  return function(survey: Survey.SurveyModel, options: Survey.SurveyModel) {
    const minMaxErrorText
      = `The value cannot be less than ${castTo<string>(options.question.min)} and more than ${castTo<string>(options.question.max)}`;
    if (options.question.inputType === "date") {
      options.question.minErrorText = minMaxErrorText;
      options.question.maxErrorText = minMaxErrorText;
    }
  };
}

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormRef, Props>((props: Props, ref) => {
  Survey
    .StylesManager
    .applyTheme("bootstrap");

  addWidgetsAndInitialize();

  const propsContent = {
    questions: props.questions,
    showQuestionNumbers: "off",
    questionTitlePattern: "numTitle",
    ...props.layoutConfig,
  };

  const modelRef = useRef(new Survey.Model(propsContent));
  modelRef.current.extraPropContent = propsContent;

  function setModelDataIfNotAlreadySet() {
    if (props.data && isEqual(modelRef.current.data, {})) {
      modelRef.current.data = props.data;
    }
  }

  setModelDataIfNotAlreadySet();

  modelRef.current.showNavigationButtons = false;

  modelRef.current.questionErrorLocation = "bottom";

  modelRef
    .current
    .onTextMarkdown
    .add(processMarkdown());

  modelRef
    .current
    .onAfterRenderQuestion
    .add(addRequiredText());

  modelRef
    .current
    .onAfterRenderQuestion
    .add(customizeMinAndMaxErrorText());

  function doesFormHasErrors(): boolean {
    return modelRef.current.isCurrentPageHasErrors;
  }

  function getFormData() {
    return modelRef.current.data;
  }

  useImperativeHandle(ref, () => ({
    reset() {
      Object
        .keys(modelRef.current.getAllValues())
        .map((questionName) => modelRef.current.clearValue(questionName));
    },
  }));

  return (
    <div className="FormComponent">
      <MemoizedSurvey
        model={modelRef.current}
        css={styles}
        onValueChanged={props.handleValueChanged}
        onAfterRenderQuestion={props.handleOnAfterRenderQuestion}
      />
      {props.renderAdditionalContent && props.renderAdditionalContent()}
      {props.renderNavigationButtons && props.renderNavigationButtons({ doesFormHasErrors, getFormData })}
    </div>
  );
});

export default FormComponent;
