import React, { FunctionComponent, PropsWithChildren } from "react";
import "./index.scss";
import NeoLink from "../neo-link";

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & { "ix-styles"?: string; "isInternalLink"?: boolean };

const ImpactXLinkButton: FunctionComponent<Props> = ({ children, ...restOfProps }: PropsWithChildren<Props>) => {
  const commonProps = {
    ...restOfProps,
    className: `impactx-link-button ${restOfProps.className ?? ""}`,
    "ix-styles": restOfProps["ix-styles"] ?? "outline",
  };

  if (restOfProps.isInternalLink) {
    return (
      <NeoLink
        {...commonProps}
        link={restOfProps.href ?? ""}
        clearDefaultStyles
      >
        {children}
      </NeoLink>
    );
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      {...commonProps}
    >
      {children}
    </a>
  );
};

export default ImpactXLinkButton;
