import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import TeamTypesInfo from "./team-types-info";
import ExperimentAndInnovationInfo from "../../../common/components/project-classification-contents/experiment-innovation-info";
import { TeamType } from "../../create-team/redux";
import OthersInfo from "../../../common/components/project-classification-contents/others-info";
import GITSInfo from "../../../common/components/project-classification-contents/gits-info";

const TeamTypesButtons: FunctionComponent = () => (
  <Row>
    <Col>
      <TeamTypesInfo
        heading="Experiment and Innovation"
        content={<ExperimentAndInnovationInfo hideApplicationSpecificInfo={true} />}
        teamType={TeamType.EXPERIMENT_AND_INNOVATION}
        id="experiment-and-innovation-btn"
      />
    </Col>
    <Col>
      <TeamTypesInfo
        heading="Others"
        content={<OthersInfo hideApplicationSpecificInfo={true} />}
        teamType={TeamType.OTHERS}
        id="others-btn"
      />
    </Col>
    <Col>
      <TeamTypesInfo
        heading="Global IT Services"
        content={<GITSInfo hideApplicationSpecificInfo={true} />}
        teamType={TeamType.GITS}
        id="gits-info-btn"
      />
    </Col>
  </Row>
);

export default TeamTypesButtons;
