import { formatDate } from "../../../../utils/date-format";
import { IRequest } from "../../types/requests";

export type TasksProps = { requestDetails: IRequest; showActions: boolean; title: string };

export default function useTasksController(props: TasksProps) {
  return ({

    requestDetails: props.requestDetails,
    showActions: props.showActions,
    shouldRenderTaskDetails: () => props.requestDetails.tasks && props.requestDetails.tasks.length > 0,
    getLastUpdated: () => {
      const tasksWithUpdatedDateTime = props.requestDetails.tasks?.filter((task) => !!task.updatedDateTime);
      if (tasksWithUpdatedDateTime?.length) {
        const tasksSortedByUpdatedDateTime = tasksWithUpdatedDateTime
          .sort((task1, task2) => (new Date(task1.updatedDateTime) < new Date(task2.updatedDateTime) ? 1 : -1));
        return formatDate(tasksSortedByUpdatedDateTime[0].updatedDateTime);
      }
      return null;
    },
    getETA: () => formatDate(props.requestDetails.eta),
    getTitle: () => props.title,
  });
}
