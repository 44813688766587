import { useDispatch } from "react-redux";
import { CurrentStep, GetStartedPageActions } from "../../redux";

export default function useSectionHeaderController() {
  const dispatch = useDispatch();
  const onEditButtonClick = (currentStep: CurrentStep) => {
    dispatch(GetStartedPageActions.updateCurrentStep(currentStep));
  };

  return {
    onEditButtonClick,
  };
}
