import { useDispatch } from "react-redux";
import { TeamCreationPageActions } from "../../create-team/redux";
import { TeamsPageActions } from "../redux";
import { useSelectorTyped } from "../../../common/redux/store";

export default function useTeamTypesController() {
  const dispatch = useDispatch();
  const showChooseTeamTypeForTeamCreationModal = useSelectorTyped((state) => state.teamsPage.showChooseTeamTypeForTeamCreationModal);
  const createTeamUnAuthorizedPageIsActive = useSelectorTyped((state) => state.teamCreationPage.createTeamUnAuthorizedPageIsActive);
  const createTeamUnAuthorizedPageTeamType = useSelectorTyped((state) => state.teamCreationPage.createTeamUnAuthorizedPageTeamType);
  const userAdditionalInfoPEV = useSelectorTyped((state) => state.header.userInfo.additionalInfo);

  const onClickHideChooseTeamTypeForTeamCreationModal = () => {
    dispatch(TeamsPageActions.updateShowChooseTeamTypeForTeamCreationModal(false));
  };

  const onClickGoBackToCreateTeam = () => {
    dispatch(TeamCreationPageActions.updateCreateTeamUnAuthorizedPageIsActive(false));
  };

  const shouldRenderTeamTypes = () => !userAdditionalInfoPEV.progress && !userAdditionalInfoPEV.error;

  const getProgressMessage = () => userAdditionalInfoPEV.progress ? "Fetching team type" : null;
  const getErrorMessage = () => userAdditionalInfoPEV.error;

  const shouldRenderErrorMessage = () => !!userAdditionalInfoPEV.error;

  const getPrimaryButtonOnClickHandler = () => {
    if (createTeamUnAuthorizedPageIsActive) {
      return onClickGoBackToCreateTeam();
    }
    else {
      return onClickHideChooseTeamTypeForTeamCreationModal();
    }
  };

  const getModalPrimaryButtonLabel = () => createTeamUnAuthorizedPageIsActive ? "Go Back" : "Cancel";

  return {
    onClickHideChooseTeamTypeForTeamCreationModal,
    showChooseTeamTypeForTeamCreationModal,
    createTeamUnAuthorizedPageIsActive,
    onClickGoBackToCreateTeam,
    createTeamUnAuthorizedPageTeamType,
    shouldRenderTeamTypes,
    getProgressMessage,
    getErrorMessage,
    shouldRenderErrorMessage,
    getPrimaryButtonOnClickHandler,
    getModalPrimaryButtonLabel,
  };
}
