import React, { FunctionComponent } from "react";
import useEventDetailsController from "./controller";
import Progress from "../../../common/components/progress";
import "./index.scss";
import { NEO_TEAM_DETAILS_URL, REQUEST_FOR_AUTH_URL, SUBSCRIBER_API_URL } from "./data/constants";
import EventsAnalytics from "../common/services/events-analytics";
import ApplicationError from "../common/component/application-error";
import EventsPlatformError from "../common/component/events-platform-error";
import FOUR_ZERO_FOUR_IMAGE from "./assets/404.png";
import { NOT_FOUND_ERROR_CODE } from "../data/constants";
import EventTag from "../common/component/event-tag";
import { GetItemString, JSONTree } from "react-json-tree";
import CopyableItem from "../../../common/components/copyable-item";
import serialize from "serialize-javascript";

const EventDetails: FunctionComponent = () => {
  const controller = useEventDetailsController();
  const theme = {
    base09: "#F39E0C",
    base0B: "#F39E0C",
    base0D: "#53C4D3",
    base03: "#EDF1F3",
  };

  const renderBackLink = () => (
    <div className="back-to-events-discovery-button">
      <a
        href="/#"
        onClick={(event) => controller.onClickBackButton(event)}
        className="d-flex flex-row"
      >
        <i className="ri-arrow-left-s-line left-arrow" />
        {" "}
        Back to discover events
      </a>
    </div>
  );

  const renderTags = (tags: string[] | undefined) => {
    if (controller.shouldShowEventTags() && tags && tags.length > 0) {
      return (
        <div
          className="event-tags"
        >
          {
            tags.map((tag) => (
              <EventTag
                key={tag}
                name={tag}
                className="text"
              />
            ),
            )
          }
        </div>
      );
    }
  };

  const getItemString: GetItemString = (nodeType, data, itemType) => (
    <span className="item-type">
      {" "}
      {itemType}
    </span>
  );

  const renderEventDetails = () => {
    if (controller.shouldRenderEventDetails()) {
      // const eventDetailsHeight = controller.shouldShowEventTags() && controller.eventTags &&
      // controller.eventTags.length>0 ? "event-details event-height-with-tags" : "event-details event-height-no-tags";
      return (
        <div className="event-details-container">
          <div className="event-registration">
            {renderBackLink()}
            <div className="event-details">
              {controller.isTeamLinkEnabled
                ? (
                  <>
                    <div className="event-category-namespace">
                      Category:
                      {" "}
                      {controller.eventCategory}
                      {" "}
                      | Namespace:
                      {" "}
                      {controller.namespaceName}
                    </div>
                  </>
                )
                : <></>}

              <div className="event-name">{controller.eventName}</div>
              {!controller.isTeamLinkEnabled ? renderTags(controller.eventTags) : <></>}
              <div className="event-description">
                <p>{controller.eventDescription}</p>
              </div>
              {controller.isTeamLinkEnabled ? renderTags(controller.eventTags) : <></>}

              {!controller.isTeamLinkEnabled
                ? (
                  <>
                    <div className="publisher-details">
                      <span>Namespace:</span>
                      <div className="publisher-details-text">
                                                &nbsp;
                        {controller.namespaceName}
                      </div>
                    </div>
                    <div className="publisher-details">
                      <span>Category:</span>
                      <div className="publisher-details-text">
                                                &nbsp;
                        {controller.eventCategory}
                      </div>
                    </div>
                  </>
                )
                : <></>}

              {controller.isTeamLinkEnabled
                ? (
                  <>
                    <div className="publisher-details team-link">
                      <span>Publish Team:</span>
                      <div className="publisher-details-text">
                                                &nbsp;
                        <a
                          onClick={() => EventsAnalytics.trackEventEventDetailsLinkClick("NEO Team Link Click")}
                          href={NEO_TEAM_DETAILS_URL.concat("/").concat(controller.teamId ?? "")}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {controller.teamName ?? ""}
                        </a>
                      </div>
                    </div>
                  </>
                )
                : (
                  <>
                    <div className="publisher-details">
                      <span>Published by:</span>
                      <div className="publisher-details-text">
                                                &nbsp;
                        {controller.teamEmail}
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div className="event-registration-schema">
              <div className="schema">
                <div className="schema-title">
                  <span className="schema-title-text">
                    Schema
                  </span>
                </div>
                <div className="schema-body">
                  <div className="tree-view-schema">
                    <JSONTree
                      data={controller.eventSchema}
                      theme={theme}
                      getItemString={getItemString}
                      shouldExpandNodeInitially={() => true}
                    />
                  </div>
                  <div className="copy-schema">
                    <CopyableItem
                      copyText={serialize(controller.eventSchema, { isJSON: true, space: 3 })}
                      toolTip={false}
                      iconBeforeCopy={(
                        <i
                          className="ri-file-copy-line before-copy"
                        />
                      )}
                      iconAfterCopy={(
                        <i
                          className="ri-check-line"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-subscriptions-help-text">
            <div className="help-text-title">Create subscriptions</div>
            <p className="help-text">
              <div className="api-gw-text">
                <span>
                  <a
                    onClick={() => EventsAnalytics.trackEventEventDetailsLinkClick("API Platform Link Click")}
                    href={SUBSCRIBER_API_URL}
                  >
                    Go to the NEO Portal API page
                  </a>
                  {" "}
                  to create subscriptions
                </span>
              </div>
            </p>
            <div className="help-text-user-guide">
              <span>
                Authorization is required to create a subscription.
                Refer&nbsp;
                <a
                  onClick={() => EventsAnalytics.trackEventEventDetailsLinkClick("User Guide Link Click")}
                  href={REQUEST_FOR_AUTH_URL}
                >
                  user guide
                </a>
                {" "}
                for more info.
              </span>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderEventDetailsProgress = () => {
    if (controller.eventDetailsProgress) {
      return (
        <div className="event-details-progress-text">
          <Progress text={controller.eventDetailsProgress} />
        </div>
      );
    }
  };

  const renderNotFoundError = () => {
    if (controller.eventDetailsError && controller.isNotFoundError) {
      return (
        <ApplicationError
          message={"We can't seem to find the page you are looking for."}
          retryLink="/events"
          image={FOUR_ZERO_FOUR_IMAGE}
          code={NOT_FOUND_ERROR_CODE}
        />
      );
    }
  };

  const renderGenericError = () => {
    if (controller.eventDetailsError && !controller.isNotFoundError) {
      return (
        <EventsPlatformError
          message={controller.eventDetailsError as unknown as string}
          retryLink="#"
        />
      );
    }
  };

  const renderEventDetailsError = () => (
    <>
      {renderNotFoundError()}
      {renderGenericError()}
    </>
  );

  return (
    <div className="EventDetails">
      {renderEventDetails()}
      {renderEventDetailsProgress()}
      {renderEventDetailsError()}
    </div>
  );
};

export default EventDetails;
