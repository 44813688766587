import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { useEffect } from "react";
import { ApplicationsCataloguePageActions } from "./redux";
import Analytics from "../../../common/services/analytics";
import { teamTypeLabelMap } from "../../../common/data/team-types";
import { getYesNoChoiceFromBoolean } from "../../../utils/forms";
import { formatDate } from "../../../utils/date-format";
import moment from "moment";
import { ApplicationBasicInfo } from "../../applications/application-details/redux";
import { omit } from "lodash";

export default function useApplicationsController() {
  const dispatch = useDispatch();
  const {
    error: fetchAllApplicationsError,
    progress: fetchAllApplicationsProgress,
    value: allApplications,
  } = useSelectorTyped((state) => state.applicationsCataloguePage.applicationsPEV);
  const filteredApplications = useSelectorTyped((state) => state.applicationsCataloguePage.filteredApplications);

  useEffect(() => {
    Analytics.trackPageApplicationCatalogue();
    dispatch(ApplicationsCataloguePageActions.fetchAllApplications());
  }, []);

  const isAllApplicationListEmpty = () => allApplications.length === 0;

  const isFilteredApplicationsListEmpty = () => filteredApplications.length === 0;

  return ({
    allApplications,
    filteredApplications,
    fetchAllApplicationsError,
    fetchAllApplicationsProgress,
    totalApplicationsCount: allApplications.length,
    filteredApplicationsCount: filteredApplications.length,
    retryFetchApplicationsCatalogue: () => {
      dispatch(ApplicationsCataloguePageActions.fetchAllApplications());
    },
    shouldRenderNoApplicationsInCatalogueMessage: () => isAllApplicationListEmpty(),
    shouldRenderNoRecordsToDisplayMessage: () => isFilteredApplicationsListEmpty(),
    shouldRenderProgressMessage: () => fetchAllApplicationsProgress !== null && isAllApplicationListEmpty(),
    shouldRenderErrorMessage: () => !!fetchAllApplicationsError,
    shouldRenderExportCsvLink: () => !isFilteredApplicationsListEmpty(),
    getCSVFileName: () => `Application_Catalog_${moment().format("YYYY-MM-DDTHH-mm-ss")}`,
    getCSVDataForDownload: () => {
      const escapeDoubleQuote = (textWithDoubleQuote: string) =>
        textWithDoubleQuote.replace(/"/g, "\"\"");

      return filteredApplications.map((application) => {
        const fieldNames: Array<keyof ApplicationBasicInfo> = [
          "name", "countryName", "country", "businessFunction", "category", "createdAt", "createdBy", "description",
          "endDate", "hasMnpi", "hasPii", "hasSensitivePii", "isDataClassificationEditable", "isEditable", "isStatusEditable",
          "lifespan", "name", "owner", "project", "purpose", "targetAudience", "team", "teamId", "teamType", "techStack", "updatedAt", "updatedBy",
          "id", "status", "decommissionReason",
        ];
        const dynamicFields: Record<string, unknown> = omit(application, fieldNames);
        Object.keys(dynamicFields).forEach((field) => {
          dynamicFields[field] = String(dynamicFields[field]);
        });

        return {
          "Team ID": application.team.id,
          "Team Name": escapeDoubleQuote(application.team.name),
          "Application ID": application.id,
          "Application Name": escapeDoubleQuote(application.name),
          Type: teamTypeLabelMap[application.team.teamType],
          Status: application.status,
          Description: escapeDoubleQuote(application.description),
          Purpose: application.purpose ?? "",
          "Target Audience": escapeDoubleQuote(application.targetAudience),
          "Business Function": application.businessFunction ?? "",
          Category: application.category,
          "Tech Stack": application.techStack,
          Owner: application.owner,
          Country: `${application.countryName} - ${application.country}`,
          MNPI: getYesNoChoiceFromBoolean(application.hasMnpi),
          PII: getYesNoChoiceFromBoolean(application.hasPii),
          "Sensitive PII": getYesNoChoiceFromBoolean(application.hasSensitivePii),
          "Open Team": getYesNoChoiceFromBoolean(application.team.isOpen),
          "End Date": formatDate(application.endDate),
          "Billing Project Name": application.project?.name,
          "Billing Project Code": application.project?.accountProjectCode,
          "Decommission Reason": application.decommissionReason ?? "",
          "Created By": application.createdBy,
          "Created At": formatDate(application.createdAt),
          "Updated By": application.updatedBy,
          "Updated At": formatDate(application.updatedAt),
          ...dynamicFields,
        };
      });
    },
  });
}
