import React from "react";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../error-new";
import { NeoError, NeoErrorType } from "../../../utils/errors/neo-errors";

class ErrorBoundary extends React.Component<unknown, { error: boolean }> {
  public constructor(props: unknown) {
    super(props);
    this.state = { error: false };
  }

  public getErrorInfo = () => {
    const errorInstance = new NeoError(NeoErrorType.UNHANDLED_EXCEPTION,
      "Unhandled Exception occurred");
    return mapApplicationErrorInfoToErrorComponentProps(errorInstance.getErrorInfo());
  };

  public componentDidCatch: Required<React.Component>["componentDidCatch"]
    = (error, errorInfo) => {
      console.log(error, errorInfo);
      this.setState({
        error: true,
      });
    };

  public render() {
    if (this.state.error) {
      return (
        <Error
          {...this.getErrorInfo()}
          renderAsFullPage={true}
          isFromErrorBoundary={true}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
