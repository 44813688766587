import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelectorTyped } from "../../../common/redux/store";
import { RequestsPageActions } from "../redux";
import Analytics from "../../../common/services/analytics";
import { useHistory } from "react-router-dom";
import { IRequest } from "../types/requests";

export type IMyRequestsController = ReturnType<typeof useMyRequestsController>;

export default function useMyRequestsController() {
  const myRequestsPEV = useSelectorTyped((state) => state.requestsPage.myRequestsPEV);
  const myRequests = myRequestsPEV.value;
  const fetchMyRequestsError = myRequestsPEV.error;
  const fetchMyRequestsProgress = myRequestsPEV.progress;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    Analytics.trackPageMyRequests();
    dispatch(RequestsPageActions.fetchMyRequests());
  }, []);

  function shouldRenderNoRequestsMessage(): boolean {
    return (!fetchMyRequestsError) && (!fetchMyRequestsProgress) && (!userHasApplications());
  }

  function shouldRenderError(): boolean {
    return !!fetchMyRequestsError;
  }

  function userHasApplications(): boolean {
    return (myRequests.length > 0);
  }

  function onClickRetryButton() {
    dispatch(RequestsPageActions.fetchMyRequests());
  }

  function shouldRenderProgressMessage() {
    return fetchMyRequestsProgress !== null && myRequests.length === 0;
  }

  return ({
    myRequests,
    shouldRenderNoRequestsMessage,
    fetchMyRequestsProgress,
    fetchMyRequestsError,
    shouldRenderError,
    onClickRetryButton,
    shouldRenderProgressMessage,
    onTableRowClickedHandler: (row: IRequest, event: MouseEvent) => {
      if (event.metaKey || event.ctrlKey) {
        window.open(`/requests/${row.id}`, "_blank");
      }
      else {
        history.push(`/requests/${row.id}`);
      }
    },
  });
}
