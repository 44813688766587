import YesNoChoices from "./yes-no-choice";
import { IExperimentsAndInnovationsFormData, IInfraConfig } from "../experiments-and-innovations/redux";
import { IOthersFormData } from "../others/redux";
import Analytics from "../../../common/services/analytics";
import { ApplicationFormData } from "../redux";
import { replaceInvalidGitHubRepoNameCharactersWithHyphens } from "../utils/process-fields";

export const prefixApplicationNameToGitHubRepos = (githubRepos: IInfraConfig["githubRepos"], applicationName: string) => {
  const prefix = `${replaceInvalidGitHubRepoNameCharactersWithHyphens(applicationName)}`;
  return githubRepos?.map(
    (repo) => repo.name ? `${prefix}-${repo.name}` : prefix,
  ) ?? [];
};

export const getGcpProjectNameIfNeeded = (app: ApplicationFormData) =>
  isHostingServiceNeeded(app) ? app.infraConfig.gcpProjectName.trim() : "";

export const getGcpProjectNames = (gcpProjects: IInfraConfig["gcpProjects"]) => gcpProjects?.map(
  (project) => project.name.trim() ?? "",
);
export const getGcpProjectNamesIfNeeded = (app: ApplicationFormData) =>
  isHostingServiceNeeded(app) ? getGcpProjectNames(app.infraConfig.gcpProjects) : [];

export const getGitHubRepoIfNeeded = (
  app: ApplicationFormData,
) => {
  const { infraConfig, basicInfo } = app as IExperimentsAndInnovationsFormData;
  return isCodeRepositoryNeeded(app)
    ? prefixApplicationNameToGitHubRepos(infraConfig.githubRepos, basicInfo.projectName)
    : [];
};

export const getGithubOrganisationIfNeeded = (application: ApplicationFormData) => {
  const app = application as IOthersFormData;
  return app.infraConfig.githubOrganisation ? app.infraConfig.githubOrganisation : "";
};

export const getGithubUsernameIfNeeded = (application: ApplicationFormData) => {
  const app = application as IOthersFormData;
  return app.infraConfig.githubUsername ? app.infraConfig.githubUsername : "";
};

export const isCodeRepositoryNeeded = (application: ApplicationFormData) =>
  application.infraConfig.isCodeRepositoryNeeded === YesNoChoices.YES;

export const isHostingServiceNeeded = (application: ApplicationFormData) =>
  application.infraConfig.isHostingServiceNeeded === YesNoChoices.YES;

export const isCiCdPipelineNeeded = (application: ApplicationFormData) =>
  application.infraConfig.isCodeRepositoryNeeded === YesNoChoices.YES
  && application.infraConfig.isCiCdPipelineNeeded === YesNoChoices.YES;

export const isInfrastructureNeeded = (application: ApplicationFormData) =>
  isCodeRepositoryNeeded(application) || isHostingServiceNeeded(application);

export const getApplicationCategoryIfOthers = (app: ApplicationFormData) => {
  const indexOfOtherValue = app.basicInfo.projectCategory.findIndex((category) => (category === "other") || (category === "Others"));
  if (indexOfOtherValue > -1) {
    const projectCategory = [...app.basicInfo.projectCategory];
    projectCategory.splice(indexOfOtherValue, 1);
    return [...projectCategory, ...app.basicInfo["projectCategory-Comment"].split(",")];
  }
  return app.basicInfo.projectCategory;
};

export const getTechStackIfOthers = (app: ApplicationFormData) => {
  const indexOfOtherValue = app.basicInfo.techStack.indexOf("other");
  if (indexOfOtherValue !== -1) {
    const techStack = [...app.basicInfo.techStack];
    techStack.splice(indexOfOtherValue, 1);
    const otherTechStack = app.basicInfo["techStack-Comment"];
    Analytics.trackEventOtherTechStackInGetStarted(otherTechStack);
    return [...techStack, ...otherTechStack.split(",")];
  }
  return app.basicInfo.techStack;
};
