import React from "react";
import { InfrastructureDetails } from "../redux";
import Details from "../details";
import "./okta-chiclets-info.scss";
import NeoLink from "../../../../common/components/neo-link";
import RequestedInfraDetail from "./requested-infra-detail";

type Props = {
  providedOktaChiclets?: InfrastructureDetails["oktaChiclets"];
  requestedOktaChiclets?: InfrastructureDetails["requestedInfra"]["oktaChiclets"];
  showTaskDetailsPageUrl?: boolean;
  oktaChicletHelpDocumentLink: string;
  diSupportEmail: string;
};

const OktaChicletsInfo = (props: Props) => {
  const isProvidedOktaChicletsExists = () => props.providedOktaChiclets
    && props.providedOktaChiclets.length > 0;

  const isRequestedOktaChicletsExists = () => props.requestedOktaChiclets
    && props.requestedOktaChiclets.length > 0;

  const renderProvidedOktaChiclets = () => {
    if (isProvidedOktaChicletsExists()) {
      return (
        <div className="provided-okta-chiclets">
          {
            props.providedOktaChiclets?.map((oktaChiclet, idx) => (
              <div key={idx}>
                <span className="authentication-type">{oktaChiclet.authenticationType}</span>
                <span>: </span>
                <NeoLink
                  link={oktaChiclet.url}
                  label={oktaChiclet.chicletLabel}
                  className="details-link"
                  external
                />
              </div>
            ))
          }
        </div>
      );
    }
  };

  const renderRequestedOktaChiclets = () => {
    if (isRequestedOktaChicletsExists()) {
      return (
        <div className="requested-okta-chiclets">
          {
            props.requestedOktaChiclets?.map((oktaChiclet, idx) => (
              <RequestedInfraDetail
                requestId={oktaChiclet.requestId}
                key={idx}
                label={(
                  <>
                    <span className="authentication-type">{oktaChiclet.type}</span>
                    <span>: </span>
                    <span>{oktaChiclet.name}</span>
                  </>
                )}
                showTaskDetailsPageUrl={props.showTaskDetailsPageUrl}
              />
            ))
          }
        </div>
      );
    }
  };

  const renderDescription = () => (
    <div className="okta-chiclet-description">
      Not for testing in local environment, for that use Okta dev. Check how create Okta dev in this&nbsp;
      <NeoLink
        link={props.oktaChicletHelpDocumentLink}
        label="tutorial"
        external
      />
      .
    </div>
  );

  const renderNotes = () => (
    <div className="okta-chiclet-notes">
      <b>NOTES:</b>
      <br />
      {
        isProvidedOktaChicletsExists()
          ? (
            <>
              Ensure you have logged to the Okta preview using the Okta Preview chiclet before you access the link above.
              <br />
            </>
          )
          : ""
      }
      If you need more than one Okta preview chiclet, please contact&nbsp;
      <NeoLink
        label={props.diSupportEmail}
        link={`mailto:${props.diSupportEmail}`}
        external
      />
      .
    </div>
  );

  const renderContent = () => {
    if (isProvidedOktaChicletsExists() || isRequestedOktaChicletsExists()) {
      return (
        <>
          {renderProvidedOktaChiclets()}
          {renderRequestedOktaChiclets()}
          {renderNotes()}
        </>
      );
    }
    return "---";
  };

  return (
    <div className="OktaChicletInfo">
      <Details fieldName="Okta Preview">
        {renderDescription()}
        {renderContent()}
      </Details>
    </div>
  );
};

export default OktaChicletsInfo;
