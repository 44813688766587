import React from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import "./index.scss";
import { ITask, Resource } from "../../../types/requests";
import ImpactXButton from "../../../../../common/components/impactx-button";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import { ResourceTableProps, useResourceTableController } from "./controller";
import { DisapproveTaskModal } from "../../../task-details/disapprove-task-modal";
import TableSortIcon from "../../../../../common/components/data-table-sort-icon";

const ResourceTable = createFunctionalComponentFromView<ReturnType<typeof useResourceTableController>, ResourceTableProps>(
  useResourceTableController,
  (controller) => {
    function renderResourceType(row: ITask) {
      return <div className="resource-type">{controller.getTaskTypeLabel(row.taskType)}</div>;
    }

    function renderActionButtons(task: ITask) {
      return (
        <div>
          {controller.getActionButtons(task).map((button, key) => (
            <ImpactXButton
              key={key}
              className="action-button"
              label={button.name}
              onClickHandler={button?.onClickHandler}
              disabled={button?.disabled ?? false}
            />
          ))}
        </div>
      );
    }

    function renderResource(resource: Resource) {
      return (
        <div>
          {
            controller.getResourceAttributeAndValueAsTuple(resource).map(([attribute, value], index) => (
              <div
                className="resource"
                key={index}
              >
                <div>
                  {" "}
                  {attribute}
                </div>
                <div>
                  {" "}
                  {value}
                </div>
              </div>
            ),
            )
          }
        </div>
      );
    }

    function renderAttributeValueColumnHeader() {
      return (
        <div className="resource">
          <div> Attribute</div>
          <div> Value</div>
        </div>
      );
    }

    function renderTaskDisapprovalReason(task: ITask) {
      if (controller.shouldRenderTaskDisapprovalReason(task)) {
        return <div>{controller.getTaskDisapprovalReason(task)}</div>;
      }
    }

    function renderTaskStatus(task: ITask) {
      return (
        <div className="status-column">
          <div className={`task-status-${task.status}`}>
            {controller.getTaskStatusLabel(task.status)}
          </div>
          {renderTaskDisapprovalReason(task)}
        </div>
      );
    }

    const columns: Array<IDataTableColumn<ITask>> = [
      {
        name: "Resource",
        selector: "taskType",
        cell: (task) => renderResourceType(task),
        sortable: true,
        sortFunction: (a, b) => a.taskType.localeCompare(b.taskType),
      },
      {
        name: renderAttributeValueColumnHeader(),
        cell: (task) => renderResource(task.resource),
        grow: 3.5,
      },
      {
        name: "Status",
        selector: "status",
        cell: (task) => renderTaskStatus(task),
        grow: 1.5,
        sortable: true,
        sortFunction: (a, b) => a.status.localeCompare(b.status),
      },
      {
        name: "Actions",
        cell: (task) => renderActionButtons(task),
        omit: !controller.showActions,
        grow: 1.5,
      },
    ];

    return (
      <div>
        <DisapproveTaskModal taskDetails={controller.selectedTask} />
        <DataTable
          className="ResourceTable"
          columns={columns}
          data={controller.tasks}
          noHeader={true}
          persistTableHead={true}
          defaultSortField="createdDateTime"
          defaultSortAsc={true}
          sortIcon={<TableSortIcon />}
        />
      </div>
    );
  },
);

export default ResourceTable;
