import * as Survey from "survey-react";
import { IInfraConfig } from "../experiments-and-innovations/redux";
import { hasDuplicates } from "../../../utils/array";
import { iif } from "../../../utils/functions";

export const PROJECT_NOT_ASSIGNED_ERROR_MESSAGE
  = "You are not assigned to a project in Jigsaw. Please contact your manager or engineering-platform-support@thoughtworks.com.";

function isApplicationNameExist(applicationNames: string[]) {
  return (params: string[]) => {
    const applicationName = String(params[0])?.trim();
    return applicationNames.some((appName) => appName.toLowerCase() === applicationName?.toLowerCase());
  };
}

function isGithubRepoNameAlreadyUsed() {
  return (params: Array<IInfraConfig["githubRepos"]>) => {
    const githubRepoNames = params[0] ? params[0].map((repo) => repo.name?.trim() ?? "") : [];
    return hasDuplicates(githubRepoNames);
  };
}

export const getApplicationNameRegExPattern = () => "^[A-Za-z0-9- ]{4,40}$";

export const getApplicationNameValidatorConfig = (applicationNames: string[]) => {
  Survey
    .FunctionFactory
    .Instance
    .register("isApplicationNameExist", isApplicationNameExist(applicationNames), false);

  return [
    {
      type: "regexvalidator",
      text: "Please enter only lower/upper case alphabets, numbers, hyphen(-), space with 4 - 40 characters.",
      regex: getApplicationNameRegExPattern(),
    },
    {
      type: "expression",
      expression: "isApplicationNameExist({projectName}) = false",
      text: "An application with the same name already exists. Please provide a different name.",
    },
  ];
};

export const getApplicationOwnerNameValidatorConfig = () => [{
  type: "regexvalidator",
  text: "Email address(es) is not in an accepted format",
  regex: "^([a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)+@+(thoughtworks)+[.]{1}(com)+,?)*$",
},
];

export const getGithubReposValidatorConfig = () => {
  Survey
    .FunctionFactory
    .Instance
    .register("isGithubRepoNameAlreadyUsed", isGithubRepoNameAlreadyUsed(), false);

  return [
    {
      type: "expression",
      expression: "isGithubRepoNameAlreadyUsed({githubRepos}) = false",
      text: "Repository names must be unique",
    },
  ];
};

export const getGcpValidatorConfig = () => {
  Survey
    .FunctionFactory
    .Instance
    .register(
      "isGcpProjectNameAlreadyUsed",
      iif(function isGcpProjectNameAlreadyUsed() {
        return (params: Array<IInfraConfig["gcpProjects"]>) => {
          const gcpProjectNames = params[0] ? params[0].map((project) => project.name?.trim() ?? "") : [];
          return hasDuplicates(gcpProjectNames);
        };
      }),
      false,
    );

  return [
    {
      type: "expression",
      expression: "isGcpProjectNameAlreadyUsed({gcpProjects}) = false",
      text: "Project names must be unique",
    },
  ];
};

export const getGcpProjectNameValidatorConfig = () => [
  {
    type: "regexvalidator",
    text: "Name must start with a letter.",
    regex: "^[A-Za-z]{1}",
  },
  {
    type: "regexvalidator",
    text: "Please enter only lower/upper case alphabets, numbers, single quotes, hyphen(-), space with 6 - 25 characters.",
    regex: "^.{1}[A-Za-z0-9-' ]{5,24}$",
  },
  {
    type: "regexvalidator",
    text: "Name cannot contain restricted strings, such as google and ssl.",
    regex: /^((?!(google|ssl)).)*$/i,
  },
  {
    type: "regexvalidator",
    text: "Name cannot end with hyphen.",
    regex: ".+(?<!-)$",
  },
];

export const getGithubRepoNameValidatorConfig = () => [
  {
    type: "regexvalidator",
    text: "Please enter only lower/upper case alphabets, numbers, hyphen(-) or underscores",
    regex: "^[a-zA-Z0-9-_]+$",
  },
];

export const getSponsoredClientValidatorConfig = (projects: string[]) => {
  Survey
    .FunctionFactory
    .Instance
    .register(
      "isProjectNotAssignedForClientPocPurpose",
      iif(function isProjectNotAssignedForClientPocPurpose() {
        return (params: string[]) => {
          const purpose = params[0]?.trim();
          if (purpose === "Sponsored client pre-sales / POC") {
            return projects.length === 0;
          }
          return false;
        };
      }),
      false,
    );

  return [
    {
      type: "expression",
      expression: "isProjectNotAssignedForClientPocPurpose({purpose}) = false",
      text: "You are not assigned to any projects. Please select different purpose or contact engineering-platform-support@thoughtworks.com.",
    },
  ];
};

export const getBillingProjectValidatorConfig = (projects: string[], errorMessage: string) => {
  Survey
    .FunctionFactory
    .Instance
    .register(
      "isProjectAssigned",
      iif(function isProjectAssigned() {
        return () => projects.length > 0;
      }),
      false,
    );

  return [
    {
      type: "expression",
      expression: "isProjectAssigned()",
      text: errorMessage,
    },
  ];
};
