import { IPage } from "../../../common/components/app/pages";
import EventDetails from "../event-details";
import EventsUserGuide from "../events-user-guide";
import EventsDiscovery from "../events-discovery";
import EventsOverview from "../events-overview";

export class EventsPages {
  public static readonly EVENTS_OVERVIEW: IPage = {
    title: "EventsOverview",
    path: "/events",
    component: EventsOverview,
  };

  public static readonly EVENTS_DISCOVERY: IPage = {
    title: "EventsDiscovery",
    path: "/events/discovery",
    component: EventsDiscovery,
  };

  public static readonly EVENT_DETAILS: IPage = {
    title: "EventDetails",
    path: "/events/:namespaceName/:eventName",
    component: EventDetails,
  };

  public static readonly EVENTS_USER_GUIDE: IPage = {
    title: "EventsUserGuide",
    path: "/events/user-guide",
    component: EventsUserGuide,
  };

  public static readonly EVENTS_NAMESPACES_VIEW: IPage = {
    title: "EventsNamespacesView",
    path: "/events/:nsViewNamespaceName",
    component: EventsDiscovery,
  };
}
