import React from "react";
import SectionHeader from "../../../review-and-submit/section-header";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import useBasicInfoReviewController from "./controller";
import ReviewDetails from "../../../review-and-submit/review-details";
import ReviewDetailsList from "../../../review-and-submit/review-details-list";
import { processOtherTextForProjectCategory, processOtherTextForTechStack } from "../../../utils/process-surveyjs-other-text-control";
import Panel from "../../../../../common/components/panel";

const BasicInfoReview = createFunctionalComponentFromView(
  useBasicInfoReviewController,
  (controller) => {
    function renderLifespan() {
      return (
        <ReviewDetails
          title="Lifespan"
          details={controller.getEndDate()}
          className="lifespan"
        />
      );
    }

    function renderBillingProject() {
      if (controller.shouldRenderBillingProject()) {
        return (
          <ReviewDetails
            title="Billing project"

            details={controller.basicInfo.billingProjectName!}
          />
        );
      }
    }

    function renderGeneralDetails() {
      return (
        <>
          <ReviewDetails
            title="Application name"
            details={controller.basicInfo.projectName}
          />
          <ReviewDetails
            title="Description"
            details={controller.basicInfo.projectDescription}
          />
          <ReviewDetails
            title="Purpose"
            details={controller.basicInfo?.purpose || ""}
          />
          {renderBillingProject()}
          {renderLifespan()}
          <ReviewDetails
            title="Target audience"
            details={controller.basicInfo.targetAudience}
          />
          <ReviewDetails
            title="Business function"
            details={controller.basicInfo.businessFunction}
          />
          <ReviewDetailsList
            title="Category"
            detailsList={processOtherTextForProjectCategory(controller.basicInfo)}
          />
          <ReviewDetailsList
            title="Tech stack"
            detailsList={processOtherTextForTechStack(controller.basicInfo)}
          />
          <ReviewDetailsList
            title="Owner"
            detailsList={controller.basicInfo.projectOwner}
          />
          <ReviewDetails
            title="Country"
            details={controller.basicInfo.country}
          />
        </>
      );
    }

    function renderDataClassificationDetails() {
      return (
        <>
          <ReviewDetails
            title="Does it contain thoughtworks financial information?"
            details={controller.dataClassificationDetails.hasMnpi}
          />
          <ReviewDetails
            title="Does it contain PII?"
            details={controller.dataClassificationDetails.hasPii}
          />
          <ReviewDetails
            title="Does it contain sensitive PII?"
            details={controller.dataClassificationDetails.hasSensitivePii}
          />
        </>
      );
    }

    return (
      <>
        <SectionHeader
          title="Application Information"
          step="BASIC_INFO"
        />
        <Panel
          titleText="General Details"
          content={renderGeneralDetails()}
        />
        <Panel
          titleText="Data Classification"
          content={renderDataClassificationDetails()}
        />
      </>
    );
  },
);

export default BasicInfoReview;
