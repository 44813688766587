import { getEmailIdValidator, getTeamNameValidatorConfig } from "../../../../utils/teams/validator";
import { TeamType } from "../../../../pages/create-team/redux";
import { Choice, paginatedDropdownControl } from "../../custom-form-controls/paginated-dropdown-control";

type Options = {
  potentialMembers: Choice[];
  teamNames: string[];
  teamType: TeamType;
  projects: Choice[];
  projectAssociationToggleEnabled: boolean;
};

export const PROJECTS_NAME = "projectIds";
export const MANAGER_QUESTION_NAME = "managers";
export const DEVELOPER_QUESTION_NAME = "developers";
export const NON_DEVELOPER_QUESTION_NAME = "non-developers";

const getManagerDescription = (teamType: TeamType) => teamType === TeamType.EXPERIMENT_AND_INNOVATION
  ? "Team point of contact. Access to all team's infrastructure resources."
  : "Add and remove members, change team roles and access to all team's infrastructure resources.";

const getInternalOpenSourceQuestion = ({ teamType }: Options) => {
  if (teamType === TeamType.EXPERIMENT_AND_INNOVATION) {
    return [{
      type: "radiogroup",
      name: "isOpen",
      title: "Internal open source",
      isRequired: true,
      choices: [
        {
          text: "<strong>Yes:</strong> All the applications created by this team will be internal open source and "
            + "any Thoughtworker can join this team and contribute to code.",
          value: true,
        },
        {
          text: "<strong>No:</strong> Only team members can work on the applications created by this team.",
          value: false,
        },
      ],
    }];
  }
  return [];
};

const getProjectAssociationQuestion = (options: Options) => {
  if (options.projectAssociationToggleEnabled && (options.teamType === TeamType.OTHERS || options.teamType === TeamType.GITS)) {
    return [{
      type: "tagbox",
      name: PROJECTS_NAME,
      title: "Staffing project association",
      isRequired: false,
      description: "Staffing project association will automatically add and remove members of your team based on the Staffing assignments.",
      descriptionLocation: "underTitle",
      select2Config: paginatedDropdownControl(options.projects),
    }];
  }
  return [];
};

export const getCreateTeamQuestions = (options: Options) => (
  [
    {
      type: "panel",
      title: "Team Details",
      elements: [
        {
          type: "text",
          name: "name",
          title: "Name",
          description: "Accepts 4 - 50 characters (lower/upper case alphabets, numbers, hyphen(-) and space)",
          descriptionLocation: "underTitle",
          isRequired: true,
          validators: getTeamNameValidatorConfig(options.teamNames),
        },
        {
          name: "description",
          type: "comment",
          title: "Description",
          description: "A brief of the team's objective.",
          descriptionLocation: "underTitle",
          isRequired: true,
          rows: 3,
        },
        {
          title: "Contact email address",
          type: "text",
          name: "contactEmail",
          description: "pos--@thoughtworks.com",
          descriptionLocation: "none",
          isRequired: true,
          validators: getEmailIdValidator(),
        },
        {
          type: "checkbox",
          name: "isSupportEmailSameAsContact",
          title: "Support email address",
          choices: [
            "Use the same as contact",
          ],
        },
        {
          type: "text",
          name: "supportEmail",
          title: "Please provide the support email address below",
          titleLocation: "hidden",
          isRequired: true,
          description: "pos--@thoughtworks.com",
          descriptionLocation: "none",
          visibleIf: "{isSupportEmailSameAsContact} empty",
          validators: getEmailIdValidator(),
        },
        ...getInternalOpenSourceQuestion(options),
      ],
    },
    {
      type: "panel",
      title: "Team Members",
      elements: [
        ...getProjectAssociationQuestion(options),
        {
          type: "tagbox",
          name: MANAGER_QUESTION_NAME,
          title: "Managers",
          isRequired: true,
          description: getManagerDescription(options.teamType),
          descriptionLocation: "underTitle",
          choices: options.potentialMembers,
          select2Config: paginatedDropdownControl(options.potentialMembers, 3),
        },
        {
          type: "tagbox",
          name: DEVELOPER_QUESTION_NAME,
          title: "Developers",
          description: "Access to all team’s infrastructure resources.",
          descriptionLocation: "underTitle",
          choices: options.potentialMembers,
          select2Config: paginatedDropdownControl(options.potentialMembers, 3),
        },
        {
          type: "tagbox",
          name: NON_DEVELOPER_QUESTION_NAME,
          title: "Non-Developers",
          description: "Access to all team’s infrastructure resources, except codebase.",
          descriptionLocation: "underTitle",
          choices: options.potentialMembers,
          select2Config: paginatedDropdownControl(options.potentialMembers, 3),
        },
      ],
    },
  ]
);
