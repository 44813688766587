import React, { FunctionComponent } from "react";
import BasicInformation from "./basic-info";
import useOthersController from "./controller";
import InfraConfig from "./infra-config";
import ReviewAndSubmit from "./review-and-submit";
import { SelectTeam } from "./select-team";

const Others: FunctionComponent = () => {
  const controller = useOthersController();

  function renderCurrentStep() {
    if (controller.shouldRenderTeamSelection()) {
      return (
        <SelectTeam />
      );
    }
    if (controller.shouldRenderBasicInfo()) {
      return (
        <BasicInformation />
      );
    }
    if (controller.shouldRenderInfraConfig()) {
      return (
        <InfraConfig />
      );
    }
    if (controller.shouldRenderReviewSubmit()) {
      return (
        <ReviewAndSubmit />
      );
    }
    return null;
  }

  return renderCurrentStep();
};

export default Others;
