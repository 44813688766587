import { useEffect, useState } from "react";
import castTo from "../../utils/cast-to";
import { getEnvVars } from "../config/env";
import { iif, invokeImmediately } from "../../utils/functions";
import { createLinkElement, createScriptElement } from "../../utils/elements";
import { IMicroFrontendData } from "../data/microfrontend-types";

export const microFrontedLoadedEventName = "MicroFrontedLoaded";

invokeImmediately(function loadAuthZMffJsFiles() {
  const authZJsUrl = getEnvVars().authZMffJsUrl;
  if (authZJsUrl !== "" || !authZJsUrl) {
    const scriptElement = createScriptElement(authZJsUrl);
    document.body.append(scriptElement);
  }
});

invokeImmediately(function loadMffJsFiles() {
  const microFrontends = getEnvVars().microFrontends;
  microFrontends.forEach(function loadMffJsFile(microFrontend) {
    const scriptElement = createScriptElement(microFrontend.jsUrl);
    scriptElement.addEventListener(
      "load",
      function dispatchMffLoadedEvent(_ev) {
        window.dispatchEvent(createMffLoadedEvent(microFrontend));
      },
    );
    document.body.append(scriptElement);
  });
});

invokeImmediately(function loadMffCssFiles() {
  const microFrontends = getEnvVars().microFrontends;
  microFrontends.forEach(function loadMffCssFile({ cssUrl }) {
    if (cssUrl) {
      const linkElement = createLinkElement(cssUrl);
      document.body.append(linkElement);
    }
  });
});

export function createMffLoadedEvent(microFrontend: IMicroFrontendData) {
  return new CustomEvent(microFrontedLoadedEventName, { detail: microFrontend });
}

/**
 * @param microFrontendData will reload the component when this mff is loaded.
 *    if `undefined`, will reload the component when any mff is loaded.
 *
 */
export const useRerenderComponentWhenMicroFrontendIsLoaded = (microFrontendData?: IMicroFrontendData) => {
  const [loadedMicroFrontendsData, setMffLoaded] = useState<IMicroFrontendData[]>([]);
  useEffect(function listenForMffLoadedEvent() {
    window.addEventListener(microFrontedLoadedEventName, (event) => {
      const mffLoadedEvent = castTo<CustomEvent<IMicroFrontendData>>(event);
      if (microFrontendData) {
        iif(function reloadComponentIfGivenMffIsLoaded() {
          if (mffLoadedEvent.detail.id === microFrontendData.id) {
            setMffLoaded([...loadedMicroFrontendsData, microFrontendData]);
          }
        });
      }
      else {
        iif(function reloadComponentWhenAnyMffIsLoaded() {
          setMffLoaded([...loadedMicroFrontendsData, mffLoadedEvent.detail]);
        });
      }
    });
  }, []);
  return { loadedMicroFrontendsData };
};
