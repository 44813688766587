import { useDispatch } from "react-redux";
import { GITSCategoryActions, IInfraConfig } from "../redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import getInfraConfigQuestions from "./data/questions";
import { stepLabels } from "../../utils/step-labels";

export default function useInfraConfigController() {
  const dispatch = useDispatch();
  const infraConfigFormData = useSelectorTyped((state) => state.getStartedPage.gits.infraConfig);
  const projectsPEV = useSelectorTyped((state) => state.projectsPEV);
  const { projectName, teamId, teamName } = useSelectorTyped((state) => state.getStartedPage.gits.basicInfo);
  const progressText = projectsPEV.progress;
  const errorText = projectsPEV.error;

  const shouldGoToNextOrPreviousStep = (formData: Partial<IInfraConfig>) => {
    dispatch(GITSCategoryActions.updateInfraConfig(formData));
    return true;
  };

  return {
    questions: getInfraConfigQuestions(
      projectName,
    ),
    errorText,
    teamDetails: { teamId: teamId ?? "", teamName: teamName ?? "" },
    progressText,
    shouldRenderForm: (!progressText && !errorText),
    infraConfigFormData,
    shouldGoToNextStep: shouldGoToNextOrPreviousStep,
    shouldGoToPreviousStep: shouldGoToNextOrPreviousStep,
    shouldRenderError: () => !!errorText,
    getStepLabelsForPage: () => stepLabels,
  };
}
