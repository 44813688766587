import ImpactXButton from "../impactx-button";
import React, { FunctionComponent } from "react";
import "./navigation-buttons.scss";
import { INavigationButtonsProps } from ".";

interface IProps {

  onClickPrevious: (formData: any) => void;

  onClickNext: (formData: any) => void;

  validateBusinessLogic?: (formData: any) => void;
  previousButtonLabel?: string;
  nextButtonLabel?: string;
  doesFormHasErrors: INavigationButtonsProps["doesFormHasErrors"];
  getFormData: INavigationButtonsProps["getFormData"];
  isNextButtonDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
}

export const NavigationButtons: FunctionComponent<IProps> = (props: IProps) => {
  function onClickPrevious() {
    props.onClickPrevious(props.getFormData());
  }

  function onClickNext() {
    const didBasicFormValidationPass = !props.doesFormHasErrors();
    const didBusinessValidationPass = props.validateBusinessLogic
      ? props.validateBusinessLogic(props.getFormData())
      : true;
    if (didBasicFormValidationPass && didBusinessValidationPass) {
      props.onClickNext(props.getFormData());
    }
  }

  return (
    <div className="NavigationButtons">
      <ImpactXButton
        className="previous-btn"
        label={props.previousButtonLabel ?? "PREVIOUS"}
        onClickHandler={onClickPrevious}
        outline
        disabled={props.isPreviousButtonDisabled ?? false}
      />
      <ImpactXButton
        className="next-btn"
        label={props.nextButtonLabel ?? "NEXT"}
        onClickHandler={onClickNext}
        disabled={props.isNextButtonDisabled ?? false}
      />
    </div>
  );
};
