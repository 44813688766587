import { getEnvVars } from "../../../common/config/env";
import { NavigationCardProps } from "../navigation-card";
import { identity } from "../../../utils/cast-to";
import { UiConfig } from "../../../common/components/header/redux";

const { thoughtworksAPIPlatformBaseURL } = getEnvVars();

export const getApplicationSectionCardConfigs = (whatsRecentConfig: UiConfig["whatsRecent"]) => identity<NavigationCardProps[]>([
  {
    heading: "Create Applications",
    title: "Quickly Build Applications",
    description: "Rapidly create and deploy the critical applications that "
      + "evolve with your business and publicise it.",
    buttonName: "Get started",
    link: "/get-started",
  },
  {
    heading: "Contribute",
    title: "Publish  API and Events",
    description: "The API and Events publish capabilities remains in the respective platforms, "
      + "you could start from the NEO API portal.",
    buttonName: "Go to NEO API",
    link: thoughtworksAPIPlatformBaseURL,
    isExternalLink: true,
  },
  {
    heading: "What's Recent",
    ...whatsRecentConfig,
  },
]);
