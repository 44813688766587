import { createAsyncThunk } from "@reduxjs/toolkit";

export const createAsyncThunkWithRejectValue: typeof createAsyncThunk
  // @ts-ignore
  = (typePrefix: string, payloadCreator) => (
    createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI);
      }
      catch (error) {
        throw thunkAPI.rejectWithValue(error);
      }
    })
  );
