import React, { FunctionComponent } from "react";
import "./index.scss";
import { Link } from "react-router-dom";

type Props = {
  message: string;
  retryLink?: string;
  image?: string;
  code?: number;
};

const ApplicationError: FunctionComponent<Props> = (props: Props) => {
  const errorMessage = props.message;

  const renderRecoverFromErrorMessages = () => (
    <div className="possible-ways-list">
      <Link
        // @ts-ignore
        to={props.retryLink}
      >
        <button className="link-button">
          Back to home
        </button>
      </Link>
    </div>
  );

  return (
    <div className="Application-Error">
      <div className="error-message-container">
        <div className="error-message-sub-container">
          <div className="error-title">
            Sorry!
          </div>
          <div className="error-message">
            {errorMessage}
          </div>
          {renderRecoverFromErrorMessages()}
        </div>
      </div>
      {/* <div className="error-image-container"> */}
      {/*  <img */}
      {/*    className="error-image" */}
      {/*    src={errorImage || defaultErrorImage} */}
      {/*    alt="error-image" */}
      {/*  /> */}
      {/* </div> */}
    </div>
  );
};

export default ApplicationError;
