import React, { FunctionComponent } from "react";
import OthersInfo from "../../../common/components/project-classification-contents/others-info";

const OthersTooltipInfo: FunctionComponent = () => (
  <div className="tooltip-content">
    <OthersInfo />
  </div>
);

export default OthersTooltipInfo;
