import { useSelectorTyped } from "../../../../common/redux/store";
import { getEnvVars } from "../../../../common/config/env";
import { useEffect, useState } from "react";
import Analytics from "../../../../common/services/analytics";
import { identity } from "../../../../utils/cast-to";
import { NeoAccordionProps } from "../../../../common/components/neo-accordion";
import { useDispatch } from "react-redux";
import { ApplicationDetailsPageActions } from "../redux";
import { useGetFeatureToggleState } from "../../../../common/hooks/feature-toggles";
import { IResourceConfig } from "../../../../common/components/header/redux";
import _ from "lodash";

export default function useAdditionalSupportController() {
  const { applicationBasicInfoPEV, requestedAdditionalSupportPEV } = useSelectorTyped((state) => state.applicationDetailsPage);

  const application = applicationBasicInfoPEV.value!;
  const requestedAdditionalSupport = requestedAdditionalSupportPEV.value;
  const [activeAccordion, setActiveAccordion] = useState<NeoAccordionProps["activeKey"]>();
  const resourceConfig = useSelectorTyped((state) => state.header.uiConfigsPEV.value.resourceConfig);
  const dispatch = useDispatch();
  const isRequestZendeskResourceToggleEnabled = useGetFeatureToggleState("Neo_Applications_RequestAppleDeveloperLicense");

  useEffect(() => {
    dispatch(ApplicationDetailsPageActions.fetchRequestedAdditionalSupport({
      applicationId: application.id,
      requestTypes: isRequestZendeskResourceToggleEnabled
        ? _.uniq(resourceConfig.map((config) => config.requestType))
        : undefined,
    }));
    Analytics.trackPageAppNeedAdditionalSupport();
  }, [resourceConfig, isRequestZendeskResourceToggleEnabled]);

  return {
    resourceConfig,
    isRequestZendeskResourceToggleEnabled,
    isApplicationActive: application.isActive,
    getProgressText: () => requestedAdditionalSupportPEV.progress,
    getErrorInfo: () => requestedAdditionalSupportPEV.error,
    onRetryButtonClick: () =>
      dispatch(ApplicationDetailsPageActions.fetchRequestedAdditionalSupport({
        applicationId: application.id,
        requestTypes: isRequestZendeskResourceToggleEnabled
          ? _.uniq(resourceConfig.map((config) => config.requestType))
          : undefined,
      })),
    shouldRenderError: () => !!requestedAdditionalSupportPEV.error,
    shouldRenderContent: () => !requestedAdditionalSupportPEV.progress
      && !requestedAdditionalSupportPEV.error,
    neoSupportMail: getEnvVars().neoSupportMailId,
    application,
    activeAccordion,
    onSelectAccordion: identity<NeoAccordionProps["onSelect"]>((eventKey) => {
      setActiveAccordion(eventKey ?? "");
    }),
    shouldRenderDecommissionRequestUrl: () => !!requestedAdditionalSupport?.decommissionRequests[0],
    shouldRenderRequestZendeskResourceUrl: (config: IResourceConfig) =>
      !!requestedAdditionalSupport?.zendeskResourceRequests.find((data) => data.tasks[0].taskType === config.resourceType),
    decommissionRequestUrl: requestedAdditionalSupport
      ? `/requests/${requestedAdditionalSupport.decommissionRequests[0]?.id}`
      : "",
    requestZendeskResourceUrl: (config: IResourceConfig) => {
      const zendeskResourceRequest = requestedAdditionalSupport?.zendeskResourceRequests
        .find((data) => data.tasks[0].taskType === config.resourceType);

      return `/requests/${zendeskResourceRequest?.id}`;
    },
    closeAccordion: () => {
      setActiveAccordion("");
    },
  };
}
