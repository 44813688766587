export const getDownloadDeveloperToolQuestions = () => ([
  {
    name: "accountName",
    type: "text",
    title: "Account name",
    description: "Client or account name for which this code will be utilized",
    descriptionLocation: "underTitle",
    isRequired: true,
  },
  {
    name: "purpose",
    type: "comment",
    title: "Purpose",
    description: "Reason for which you are downloading this code",
    descriptionLocation: "underTitle",
    isRequired: true,
  },
  {
    type: "checkbox",
    name: "acknowledgeConsumption",
    choices: [
      "I understand and acknowledge that I will be responsible for fixing vulnerabilities "
      + "in this code wherever it is being used in my application within the SLA (Service Level Agreement).",
    ],
    titleLocation: "hidden",
    requiredErrorText: "You must acknowledge before you proceed",
    isRequired: true,
  },
]);
