import { IPotentialMember } from "../../../pages/teams/redux";
import { useSelectorTyped } from "../../redux/store";

export const useSelectedNonGITSMembers = (selectedMembersToBeAddedToTeam: Array<IPotentialMember["employeeId"]>) => {
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  const isNonGITSMember = (member: IPotentialMember) => !(member.isGITSMember || member.isOthersMember);
  let selectedNonGITSMembers: IPotentialMember[] = [];
  if (selectedMembersToBeAddedToTeam.length > 0) {
    selectedNonGITSMembers = potentialMembersPEV.value.filter((member) =>
      isNonGITSMember(member) && selectedMembersToBeAddedToTeam.includes(member.employeeId),
    );
  }
  return selectedNonGITSMembers;
};
