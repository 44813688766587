import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { TeamCreationPageActions, TeamType } from "./redux";
import { useHistory } from "react-router-dom";
import { TeamDetailsPageActions } from "../team-details/redux";
import { Pages } from "../../common/components/app/pages";
import { TeamsPageActions } from "../teams/redux";
import { useSelectorTyped } from "../../common/redux/store";
import { iif } from "../../utils/functions";
import { useGetFeatureToggleState } from "../../common/hooks/feature-toggles";
import { convertSpaceToUnderscore } from "../../utils/string";

export default function useCreateTeamController() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedTeamType = useSelectorTyped((state) => state.teamCreationPage.selectedTeamType);
  const createTeamPEV = useSelectorTyped((state) => state.teamCreationPage.createTeamPEV);
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");

  useEffect(
    function resetReduxStateAndNavigateToTeamDetailsPageWhenTeamIsCreated() {
      if (createTeamPEV.value) {
        const team = createTeamPEV.value;
        dispatch(TeamCreationPageActions.resetState());
        dispatch(TeamDetailsPageActions.updateTeamDetailsPEVState(team));
        history.push(`/teams/${team.id}/${convertSpaceToUnderscore(team.name)}`);
      }
    },
    [createTeamPEV.value],
  );

  useEffect(
    () => {
      iif(function redirectToMyTeamPageIfNoTeamIsSelected() {
        if (selectedTeamType === TeamType.NONE) {
          dispatch(TeamCreationPageActions.resetState());
          dispatch(TeamsPageActions.updateShowChooseTeamTypeForTeamCreationModal(true));
          history.replace(Pages.TEAMS.path);
        }
      });
      if (projectAssociationToggleEnabled && selectedTeamType !== TeamType.EXPERIMENT_AND_INNOVATION) {
        dispatch(TeamCreationPageActions.getProjects());
      }
    },
    [],
  );

  const isSelectedTeamTypeExperimentAndInnovation = () => selectedTeamType === TeamType.EXPERIMENT_AND_INNOVATION;

  const isSelectedTeamTypeOthers = () => selectedTeamType === TeamType.OTHERS;

  const isSelectedTeamTypeGITS = () => selectedTeamType === TeamType.GITS;

  const onCreateTeamCancel = () => {
    dispatch(TeamCreationPageActions.resetState());
    history.push(Pages.TEAMS.path);
  };

  const shouldRenderCreateTeamForm = () => isSelectedTeamTypeExperimentAndInnovation()
    || isSelectedTeamTypeOthers()
    || isSelectedTeamTypeGITS();

  const getPageTitle = () => {
    if (isSelectedTeamTypeGITS()) {
      return "Create Global IT Services Team";
    }
    else if (isSelectedTeamTypeOthers()) {
      return "Create Others Team";
    }
    else {
      return "Create Experiment & Innovation Team";
    }
  };

  return ({
    selectedTeamType,
    onCreateTeamCancel,
    getPageTitle,
    shouldRenderCreateTeamForm,
  });
}
