import { ITeam } from "../../../pages/team-details/redux";
import { useEffect, useState } from "react";
import { ProgressError } from "../../../utils/progress-error";
import { RoleTypeLabel } from "../../data/role-types";
import { ApplicationBasicInfo } from "../../../pages/applications/application-details/redux";
import Analytics from "../../services/analytics";

type ExitTeamProps = {
  team: ITeam | ApplicationBasicInfo["team"];
  onClickConfirmExitButton: () => void;
  exitTeamPE: ProgressError;
  loggedInUserEmail: string;
  buttonType?: "primary" | "secondary";
  pageTitle: string;
};
export default function useExitTeamController(props: ExitTeamProps) {
  const { team, loggedInUserEmail, exitTeamPE, buttonType, pageTitle } = props;
  const [showExitTeamLastManagerWarningModal, setShowExitTeamLastManagerWarningModal] = useState<boolean>(false);
  const [showExitTeamModal, setShowExitTeamModal] = useState<boolean>(false);

  useEffect(() => {
    if (exitTeamPE.progress === null && exitTeamPE.error === null) {
      setShowExitTeamModal(false);
    }
  }, [exitTeamPE]);

  return ({
    showExitTeamLastManagerWarningModal,
    onHideExitTeamLastManagerWarningModal: () => setShowExitTeamLastManagerWarningModal(false),
    onClickExitTeamButton: () => {
      function isMemberLastManagerOfTeam() {
        const membersWithManagerRole = team?.members.filter((member) => member.role === "manager");
        const loggedInUserRoleInTeam = team?.members.find((member) => member.emailId === loggedInUserEmail)?.role;
        return membersWithManagerRole.length === 1 && loggedInUserRoleInTeam === RoleTypeLabel.MANAGER;
      }

      if (isMemberLastManagerOfTeam()) {
        setShowExitTeamLastManagerWarningModal(true);
      }
      else {
        setShowExitTeamModal(true);
      }
    },
    showExitTeamModal,
    onHideExitTeamModal: () => setShowExitTeamModal(false),
    shouldExitTeamModalButtonBeDisabled: () => !!exitTeamPE.progress,
    getExitTeamConfirmButtonLabel: () => exitTeamPE.progress ? "Exiting" : "Exit",
    onClickConfirmExitButton: () => {
      props.onClickConfirmExitButton();
      setShowExitTeamModal(true);
      Analytics.trackEventClickExitTeamButton(pageTitle);
    },
    isButtonOutline: () => buttonType === "secondary",
  });
}
