import * as React from "react";
import { Nullable } from "../../../utils/nullable";
import ImpactXButton from "../impactx-button";
import "./index.scss";

export interface Props {
  text: Nullable<string>;
  subText?: string;
  buttonText?: string;
  onClickRetry?: () => void;
  showRetry?: boolean;
}

const Error: React.FunctionComponent<Props> = (props: Props) => {
  const renderError = () => {
    if (isValidText(props.text)) {
      const messageToRetry = props.showRetry ? ", please retry to perform same action again." : "";
      return (
        <div id="error">
          <div className="error-text">
            {props.text}
            {messageToRetry}
          </div>
          {renderRetryButton()}
        </div>
      );
    }
  };

  const renderRetryButton = () => {
    if (props.showRetry) {
      return (
        <ImpactXButton
          label="Retry"
          onClickHandler={props.onClickRetry || (() => ({}))}
        />
      );
    }
  };

  return (
    <>
      {renderError()}
    </>
  );
};

const isValidText = (text: Nullable<string>) => !!text;

export default Error;
