export const API_GW_MFF_ID = "api-gateway";
export const MASTER_DATA_MFF_ID = "master-data";
export const DATA_CATALOGUE_GW_MFF_ID = "data-catalogue";
export const FOUR_KEY_METRICS_MFF_ID = "reports";
export const ADR_MFF_ID = "adr";

export type MffId = typeof API_GW_MFF_ID
  | typeof MASTER_DATA_MFF_ID
  | typeof DATA_CATALOGUE_GW_MFF_ID
  | typeof FOUR_KEY_METRICS_MFF_ID
  | typeof ADR_MFF_ID;

export type Url = string;

export interface IMicroFrontendData {
  id: MffId;
  jsUrl: Url;
  cssUrl?: Url;
  renderRootElementFunctionName: string;
  headerMenusObjectName: string;
  getHeaderMenus: () => IMicroFrontendHeaderMenus | null;
}

export type IMicroFrontendHeaderMenus = Array<{
  label: string;
  url?: Url;
  dropdownItems?: Array<{
    label: string;
    url: Url;
  }>;
}>;

export interface Window {
  // @ts-ignore duplicate-index-signature

  [renderRootElementFunctionName: string]: (container: any, history: any) => void;

  // @ts-ignore duplicate-index-signature

  [headerMenusObjectName: string]: IMicroFrontendHeaderMenus;
}
