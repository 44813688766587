import React from "react";
import { InfrastructureDetails } from "../redux";
import Details from "../details";
import DetailsList from "../details-list";
import { TeamType } from "../../../create-team/redux";
import NeoLink from "../../../../common/components/neo-link";
import "./circle-ci-info.scss";

type Props = Pick<InfrastructureDetails, "ciCdProvider" | "pipelines" | "githubOrg"> & {
  teamType: TeamType;
};

const CircleCiInfo = (props: Props) => {
  const renderCiCdDetailsForExperimentsAndInnovation = () => (
    <DetailsList
      fieldName={props.ciCdProvider ?? "CircleCI"}
      detailsList={props.pipelines}
      renderUrlsAsHyperLink={true}
    />
  );

  const renderCircleCiDocLink = () => (
    <NeoLink
      className="circle-ci-link"
      link="https://docs.google.com/document/d/1xJFi9Vc47jlg9oFROjEPWlK01-HnjUh7qU1jI8irbZE/edit"
      label="here"
      external
    />
  );

  const renderCreateCircleCIAccountDocumentationLink = () => (
    <>
      <Details
        fieldName="CircleCI"
      >
        {
          props.githubOrg
          && (
            <>
              Click
              {" "}
              {renderCircleCiDocLink()}
              {" "}
              to know how to integrate the repositories with the pipeline or to upgrade the CircleCI plan.
            </>
          )
        }
      </Details>
    </>
  );

  function renderContent() {
    if (props.teamType === TeamType.EXPERIMENT_AND_INNOVATION) {
      return renderCiCdDetailsForExperimentsAndInnovation();
    }
    else {
      return renderCreateCircleCIAccountDocumentationLink();
    }
  }

  return (
    <div className="CircleCiInfo">
      {renderContent()}
    </div>
  );
};

export default CircleCiInfo;
