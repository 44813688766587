import React, { FunctionComponent } from "react";
import GITSInfo from "../../../common/components/project-classification-contents/gits-info";

const GITSTooltipInfo: FunctionComponent = () => (
  <div className="tooltip-content">
    <GITSInfo />
  </div>
);

export default GITSTooltipInfo;
