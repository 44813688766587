import { Hash } from "../../utils/hash";

export const teamTypeLabelMap: Hash<string> = {
  EXPERIMENT_AND_INNOVATION: "Experiment and Innovation",
  OTHERS: "Others",
  GITS: "Global IT Services",
  SOFTWARE_MANAGEMENT: "Software Management",
};

export const teamTypeDropDownOptions = [
  { label: teamTypeLabelMap.EXPERIMENT_AND_INNOVATION, value: "EXPERIMENT_AND_INNOVATION" },
  { label: teamTypeLabelMap.OTHERS, value: "OTHERS" },
  { label: teamTypeLabelMap.GITS, value: "GITS" },
  { label: teamTypeLabelMap.SOFTWARE_MANAGEMENT, value: "SOFTWARE_MANAGEMENT" },
];
