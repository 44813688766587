import React from "react";
import "./no-teams-message.scss";
import experimentAndInnovation from "./assets/experiment_innovation.svg";
import gits from "./assets/gits.svg";
import NeoLink from "../../../common/components/neo-link";

type Props = {
  helpDocumentLink: string;
};

const NoTeamsMessage = (props: Props) => (
  <div>
    <div className="NoTeamsMessage">
      You are not part of any team(s) yet.
      <br />
      NEO teams are broadly classified based on the type of application they build. Learn more about&nbsp;
      <NeoLink
        className="help-link"
        link={props.helpDocumentLink}
        external
        clearDefaultStyles
        ariaLabel="Help"
      >
        NEO teams
      </NeoLink>
    </div>
    <div className="teams-info">
      <div className="team-info">
        <div className="name">Experiment & Innovation Team</div>
        <img
          src={experimentAndInnovation}
          alt="experiment-and-innovation"
          height={180}
          width={190}
        />
        <div className="team-description">
          A group of people come together and do some experimentation for their capability development or learnings,
          or who want to innovate for a non-sensitive or non-private client POC, or to build shadow IT applications which
          can support their local office or function.
        </div>
      </div>
      <div className="team-info">
        <div className="name">GITS Team</div>
        <img
          src={gits}
          alt="gits"
          height={180}
          width={190}
        />
        <div className="team-description">
          GITS engineering teams build and deliver applications or products for global business functions.
          This is fully sponsored by Thoughtworks global IT.
        </div>
      </div>
    </div>
  </div>
);

export default NoTeamsMessage;
