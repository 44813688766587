import { useDispatch } from "react-redux";
import { GITSCategoryActions, IBasicInfo } from "../redux";
import getBasicInfoQuestions from "./data/questions";
import { extractFieldValues } from "../../../../utils/extractor";
import { ApplicationBasicInfo } from "../../../applications/application-details/redux";
import { convertCSVToList, convertListToCSV } from "../../../../utils/string";
import { useEffect } from "react";
import Analytics from "../../../../common/services/analytics";
import { stepLabels } from "../../utils/step-labels";
import { SurveyModel } from "survey-react";
import { onAfterQuestionRenderedOptions } from "../../../../common/components/forms";
import { PROJECT_NOT_ASSIGNED_ERROR_MESSAGE } from "../../utils/validator";
import { useSelectorTyped } from "../../../../common/redux/store";

export default function useBasicInformationController() {
  const projects = useSelectorTyped((state) => state.projectsPEV);
  const basicInfo = useSelectorTyped((state) => state.getStartedPage.gits.basicInfo);
  const countryRegionalItLeadsPEV = useSelectorTyped((state) => state.countryRegionalItLeadsPEV);
  const businessFunctionsLeadsPEV = useSelectorTyped((state) => state.businessFunctionLeadsPEV);
  const applicationsPEV = useSelectorTyped((state) => state.applicationsCataloguePage.applicationsPEV);
  const loggedInUserEmailPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const applicationPurposesPEV = useSelectorTyped((state) => state.applicationPurposesPEV);
  const applicationPurposes = applicationPurposesPEV.value;
  const countryFetchRequestProgress = countryRegionalItLeadsPEV.progress;
  const countryFetchRequestError = countryRegionalItLeadsPEV.error;
  const businessFunctionsFetchRequestError = businessFunctionsLeadsPEV.error;
  const dispatch = useDispatch();

  useEffect(() => {
    Analytics.trackPageGetStartedGITS();
  }, []);

  const shouldGoToNextStep = (formData: Partial<IBasicInfo>) => {
    const selectedCountry = countryRegionalItLeadsPEV.value.find((data) => data.name === formData.country);
    if (selectedCountry) {
      formData.countryCode = selectedCountry.businessUnit;
    }
    formData.projectOwner = convertCSVToList(formData.projectOwner as unknown as string);
    formData.projectName = (formData.projectName as string).trim();
    if (formData.billingProjectName) {
      formData.billingProjectId = projects.value.find((project) => project.name === formData.billingProjectName)?.id;
    }
    else {
      formData.billingProjectId = null;
      formData.billingProjectName = null;
    }
    dispatch(GITSCategoryActions.updateBasicInfo(formData));
    return true;
  };

  const shouldGoToPreviousStep = (formData: Partial<IBasicInfo>) => {
    formData.projectOwner = convertCSVToList(formData.projectOwner as unknown as string);
    dispatch(GITSCategoryActions.updateBasicInfo(formData));
    return true;
  };

  const shouldRenderForm = () => !shouldRenderError() && getProgressMsg() === null;

  const getProgressMsg = () => {
    const shouldDisplayProgress = countryFetchRequestProgress !== null || applicationsPEV.progress != null
      || applicationPurposesPEV.progress !== null
      || loggedInUserEmailPEV.progress !== null
      || projects.progress !== null;
    return shouldDisplayProgress ? "Fetching details" : null;
  };

  const getErrorMsg = () =>
    countryFetchRequestError
    || businessFunctionsFetchRequestError
    || applicationsPEV.error
    || applicationPurposesPEV.error
    || loggedInUserEmailPEV.error
    || projects.error;

  const shouldRenderError = () => !!getErrorMsg();

  const existingApplicationNames = extractFieldValues<ApplicationBasicInfo, string>("name", applicationsPEV.value);

  const getBasicInfoDataForForm = () => ({
    ...basicInfo,
    projectOwner: (basicInfo.projectOwner.length > 0)
      ? convertListToCSV(basicInfo.projectOwner)
      : loggedInUserEmailPEV.value,
  });

  return {
    shouldGoToNextStep,
    shouldGoToPreviousStep,
    getBasicInfoDataForForm,
    questions: getBasicInfoQuestions({
      countryNames: countryRegionalItLeadsPEV.value.map(({ name }) => name),
      businessFunctions: businessFunctionsLeadsPEV.value.map(({ businessFunction }) => businessFunction),
      applicationPurposes,
      existingApplicationNames,
      projectChoices: projects.value.map((project) => project.name),
    }),
    shouldRenderForm,
    getProgressMsg,
    shouldRenderError,
    getErrorMsg,
    teamDetails: {
      teamId: basicInfo.teamId ?? "",
      teamName: basicInfo.teamName ?? "",
    },
    getStepLabelsForPage: () => stepLabels,
    onAfterQuestionRendered: (
      form: SurveyModel,
      options: onAfterQuestionRenderedOptions,
    ) => {
      if (options.question.name === "billingProjectName" && projects.value.length === 0) {
        // For a non-filled required field, 'Required' error is shown instead of 'Project not assigned' error
        // As workaround we update the default required text with 'Project not assigned' error
        options.question.requiredErrorText = PROJECT_NOT_ASSIGNED_ERROR_MESSAGE;
        options.question.hasErrors(true);
      }
    },
  };
}
