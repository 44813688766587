import techStack from "../../../data/tech-stack";
import {
  getApplicationNameValidatorConfig,
  getApplicationOwnerNameValidatorConfig,
  getSponsoredClientValidatorConfig,
} from "../../../utils/validator";
import applicationCategories from "../../../data/application-category";
import getDataClassificationQuestions from "../../../data/data-classification";
import moment from "moment";
import { paginatedDropdownControl } from "../../../../../common/components/custom-form-controls/paginated-dropdown-control";

type BasicInfoQuestionOptions = {
  countryNames: string[];
  businessFunctions: string[];
  applicationPurposes: string[];
  existingApplicationNames: string[];
  projectChoices: string[];
};

const getBasicInfoQuestions = (options: BasicInfoQuestionOptions) => [
  {
    type: "panel",
    title: "General Details",
    elements: [
      {
        type: "text",
        name: "projectName",
        title: "Application name",
        description: "Accepts 4 - 40 characters (lower case, upper case, numbers, hyphen(-) and space)",
        descriptionLocation: "underTitle",
        isRequired: true,
        validators: getApplicationNameValidatorConfig(options.existingApplicationNames),
      },
      {
        name: "projectDescription",
        type: "comment",
        title: "Description",
        description: "A brief of the application's objective or problem it solves.",
        descriptionLocation: "underTitle",
        isRequired: true,
        rows: 3,
      },
      {
        type: "dropdown",
        name: "purpose",
        title: "Purpose",
        isRequired: true,
        choices: options.applicationPurposes,
        hasOther: true,
        otherText: "Other",
        otherPlaceHolder: "Specify purpose",
        storeOthersAsComment: false,
        validators: getSponsoredClientValidatorConfig(options.projectChoices),
      },
      options.projectChoices.length > 0 && {
        type: "dropdown",
        renderAs: "select2",
        select2Config: paginatedDropdownControl(options.projectChoices),
        name: "billingProjectName",
        title: "Billing project",
        isRequired: true,
        description: "The listed billing projects are the set of projects associated to you. "
          + "If you are looking for another billing project that is not listed here, then, it is likely that you are not associated to the project. "
          + "Contact the respective project owner for further action",
        descriptionLocation: "underTitle",
        visibleIf: "{purpose}='Sponsored client pre-sales / POC'",
      },
      {
        type: "dropdown",
        name: "endDate",
        description: "Approximate lifespan of the application",
        title: "Lifespan",
        isRequired: true,
        choices: [
          {
            text: "Upto 3 months",
            value: moment().add(3, "months").format("YYYY-MM-DD"),
          },
          {
            text: "Upto 6 months",
            value: moment().add(6, "months").format("YYYY-MM-DD"),
          },
        ],
      },
      {
        type: "text",
        name: "targetAudience",
        title: "Target audience",
        isRequired: true,
      },
      {
        type: "dropdown",
        name: "businessFunction",
        title: "Business function",
        placeHolder: "Select the business function",
        description: "Select the TW activity which your application will support",
        descriptionLocation: "underTitle",
        isRequired: false,
        choices: options.businessFunctions,
      },
      {
        type: "checkbox",
        name: "projectCategory",
        title: "Category",
        isRequired: true,
        choices: applicationCategories,
        hasOther: true,
        otherText: "Others",
        otherPlaceHolder: "Specify comma separated categories",
      },
      {
        type: "tagbox",
        name: "techStack",
        title: "Tech stack",
        isRequired: false,
        hasOther: true,
        otherText: "Others",
        choices: techStack,
      },
      {
        type: "text",
        name: "projectOwner",
        title: "Owner",
        description: "Comma separated Thoughtworks domain email addresses",
        descriptionLocation: "underTitle",
        isRequired: true,
        validators: getApplicationOwnerNameValidatorConfig(),
      },
      {
        type: "dropdown",
        name: "country",
        title: "Country",
        description: "The country is where the application will be used and which will fund the costs of it",
        descriptionLocation: "underTitle",
        isRequired: true,
        choices: options.countryNames,
      },
    ],
  },
  getDataClassificationQuestions(),
];

export default getBasicInfoQuestions;
