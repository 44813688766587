import { ComponentType } from "react";
import GetStarted from "../../../pages/get-started";
import InfrastructureOfferings from "../../../pages/infrastructure-offerings";
import Events from "../../../pages/events";
import { CreateTeam } from "../../../pages/create-team";
import { TeamDetails } from "../../../pages/team-details";
import { Teams } from "../../../pages/teams";
import HomePage from "../../../pages/home";
import Requests from "../../../pages/requests";
import Catalogue from "../../../pages/catalogue";

export interface IPage {
  title: string;
  path: string;
  component: ComponentType<any>;
}

export class Pages {
  // TODO rename to get started
  public static readonly PROJECT_CATEGORIES: IPage = {
    title: "Get Started",
    path: "/get-started",
    component: GetStarted,
  };

  public static readonly INFRA_OFFERINGS: IPage = {
    title: "Infrastructure Offerings",
    path: "/infra-offerings",
    component: InfrastructureOfferings,
  };

  public static readonly CATALOGUE: IPage = {
    title: "Catalogue",
    path: "/catalogue",
    component: Catalogue,
  };

  public static readonly EVENTS: IPage = {
    title: "Events",
    path: "/events",
    component: Events,
  };

  public static readonly TEAMS: IPage = {
    title: "Teams",
    path: "/teams",
    component: Teams,
  };

  public static readonly CREATE_TEAM: IPage = {
    title: "Create Team",
    path: "/create-team",
    component: CreateTeam,
  };

  public static readonly TEAM_DETAILS: IPage = {
    title: "Team Details",
    path: "/teams/:teamId",
    component: TeamDetails,
  };

  public static readonly HOME_PAGE: IPage = {
    title: "Home",
    path: "/",
    component: HomePage,
  };

  public static readonly REQUESTS_PAGE: IPage = {
    title: "Requests",
    path: "/requests",
    component: Requests,
  };
}
