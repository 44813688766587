import { ITeamMember, TeamDetailsPageActions } from "../../redux";
import { useDispatch } from "react-redux";
import Analytics from "../../../../common/services/analytics";
import { useEffect, useState } from "react";
import { useSelectorTyped } from "../../../../common/redux/store";
import { RoleTypeLabel } from "../../../../common/data/role-types";

export type Props = {
  userInfo: ITeamMember;
  teamId: string;
};

export function useUserActionsController(props: Props) {
  const [showRemoveMemberWarningModal, setShowRemoveMemberWarningModal] = useState(false);
  const [showRemoveLastManagerWarningModal, setShowRemoveLastManagerWarningModal] = useState(false);
  const teamDetails = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV.value);
  const removeMemberPES = useSelectorTyped((state) => state.teamDetailsPage.removeMemberPES);
  const loggedInUserEmailPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const dispatch = useDispatch();

  const isManager = () => props.userInfo.role === RoleTypeLabel.MANAGER;
  const isDeveloper = () => props.userInfo.role === RoleTypeLabel.DEVELOPER;
  const isNonDeveloper = () => props.userInfo.role === RoleTypeLabel.NON_DEVELOPER;

  const userFullName = `${props.userInfo.firstName} ${props.userInfo.lastName}`;

  useEffect(() => {
    if (removeMemberPES.success) {
      setShowRemoveMemberWarningModal(false);
      dispatch(TeamDetailsPageActions.resetRemoveMemberPES());
    }
  }, [removeMemberPES]);

  const removeMemberDropdownAction = () => {
    const isLastManager = isMemberLastManagerOfTeam();
    if (isLastManager) {
      displayLastManagerWarningModal();
      return;
    }
    setShowRemoveMemberWarningModal(true);
  };

  const makeManager = () => {
    Analytics.trackEventClickMakeManager();
    dispatch(TeamDetailsPageActions.updateTeamMemberRole({
      teamId: props.teamId,
      employeeId: props.userInfo.employeeId,
      role: RoleTypeLabel.MANAGER,
    }));
  };

  const makeDeveloper = () => {
    const isLastManager = isMemberLastManagerOfTeam();
    if (isLastManager) {
      displayLastManagerWarningModal();
      return;
    }
    Analytics.trackEventClickMakeDeveloper();
    dispatch(TeamDetailsPageActions.updateTeamMemberRole({
      teamId: props.teamId,
      employeeId: props.userInfo.employeeId,
      role: RoleTypeLabel.DEVELOPER,
    }));
  };

  const makeNonDeveloper = () => {
    const isLastManager = isMemberLastManagerOfTeam();
    if (isLastManager) {
      displayLastManagerWarningModal();
      return;
    }
    Analytics.trackEventClickMakeNonDeveloper();
    dispatch(TeamDetailsPageActions.updateTeamMemberRole({
      teamId: props.teamId,
      employeeId: props.userInfo.employeeId,
      role: RoleTypeLabel.NON_DEVELOPER,
    }));
  };

  interface Action {
    label: string;
    onClick: () => void;
  }

  function isSelectedUserIsTheLoggedInUser() {
    return props.userInfo.emailId === loggedInUserEmailPEV.value;
  }

  function isMemberLastManagerOfTeam() {
    const memberWithManagerRole = teamDetails?.members.filter((member) => member.role === "manager");
    return (memberWithManagerRole?.length === 1 && props.userInfo.role === "manager");
  }

  function displayLastManagerWarningModal() {
    setShowRemoveLastManagerWarningModal(true);
  }

  return ({
    showRemoveMemberWarningModal,
    showRemoveLastManagerWarningModal,
    getUserActions: () => {
      const makeDeveloperAction: Action = { label: "Make developer", onClick: makeDeveloper };
      const makeNonDeveloperAction: Action = { label: "Make non-developer", onClick: makeNonDeveloper };
      const makeManagerAction: Action = { label: "Make manager", onClick: makeManager };
      const removeMemberAction: Action = { label: "Remove member", onClick: removeMemberDropdownAction };

      if (isManager()) {
        return [makeDeveloperAction, makeNonDeveloperAction, removeMemberAction];
      }
      if (isDeveloper()) {
        return [makeNonDeveloperAction, makeManagerAction, removeMemberAction];
      }
      if (isNonDeveloper()) {
        return [makeDeveloperAction, makeManagerAction, removeMemberAction];
      }
      return [makeDeveloperAction, makeManagerAction, makeNonDeveloperAction, removeMemberAction];
    },
    onClickConfirmRemoveMember: () => {
      Analytics.trackEventClickRemoveTeamMember(teamDetails!);
      dispatch(TeamDetailsPageActions.removeMemberFromTeam({
        teamId: props.teamId,
        employeeId: props.userInfo.employeeId,
      }));
    },
    getRemoveButtonLabelInRemoveMemberWarningModal: () => removeMemberPES.progress ? "Removing" : "Remove",
    shouldDisableButtonsInRemoveMemberWarningModal: () => !!removeMemberPES.progress,
    onHideRemoveMemberModal: () => {
      setShowRemoveMemberWarningModal(false);
    },
    onHideRemoveLastManagerWarningModal: () => {
      setShowRemoveLastManagerWarningModal(false);
    },
    getRemoveMemberWarningModalConfirmationQuestion: () => isSelectedUserIsTheLoggedInUser()
      ? "Are you sure you want to remove yourself?"
      : `Are you sure you want to remove ${userFullName}?`,
    getRemoveMemberWarningModalAdditionalInfo: () => isSelectedUserIsTheLoggedInUser()
      ? "You will no longer be a member and will lose access to this team."
      : "They'll no longer be a member and will lose access to this team.",
  });
}
