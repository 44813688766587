import React from "react";
import "../../review-and-submit/review-and-submit.scss";
import { Col, Row } from "react-bootstrap";
import PageTitle from "../../../../common/components/page-title";
import BasicInfoReview from "./basic-info-review";
import InfrastructureConfigurationReview from "./infrastructure-configuration-review";
import useReviewAndSubmitController from "./controller";
import ImpactXButton from "../../../../common/components/impactx-button";
import "react-toastify/dist/ReactToastify.css";
import StepBar from "../../../../common/components/step-bar";
import TeamLink from "../../../../common/components/team-link";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import ConsentCheckBox from "../../../../common/components/consent-checkbox";

const ReviewAndSubmit = createFunctionalComponentFromView(useReviewAndSubmitController, (controller) => {
  function renderSubmitButton() {
    if (controller.isCreateApplicationRequestInProgress()) {
      return (
        <ImpactXButton
          label="Submitting"
          onClickHandler={controller.onSubmitBtnClick}
          disabled
        />
      );
    }
    return (
      <ImpactXButton
        label="Submit"
        onClickHandler={controller.onSubmitBtnClick}
      />
    );
  }

  function renderStepBar() {
    return (
      <StepBar
        stepLabels={controller.getStepLabelsForPage()}
        currentCompletedStep={4}
      />
    );
  }

  function renderPageTitle() {
    return (
      <PageTitle
        title="Others"
        subText={(
          <TeamLink
            id={controller.basicInfo.teamId}
            name={controller.basicInfo.teamName}
          />
        )}
        subtitle="Review and Submit"
        displayTitleLighter={true}
      />
    );
  }

  function renderConsentCheckBox() {
    return (
      <div className="consent-check-box">
        <ConsentCheckBox
          handleOnChanged={controller.onAcknowledgementChanged}
        />
        {
          controller.showError
          && <div className="consent-check-box-error-message">You must acknowledge before proceeding.</div>
        }
      </div>
    );
  }

  return (
    <div className="ReviewAndSubmit">
      {renderStepBar()}
      {renderPageTitle()}
      <Row className="review-and-submit-details">
        <Col>
          <BasicInfoReview />
        </Col>
        <Col>
          <InfrastructureConfigurationReview />
        </Col>
      </Row>
      {renderConsentCheckBox()}
      <div className="submit-btn">
        {renderSubmitButton()}
      </div>
    </div>
  );
});

export default ReviewAndSubmit;
