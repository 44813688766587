import { useDispatch } from "react-redux";
import { experimentsAndInnovationsCategoryActions, IBasicInfo } from "../redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { extractFieldValues } from "../../../../utils/extractor";
import { ApplicationBasicInfo } from "../../../applications/application-details/redux";
import { convertCSVToList, convertListToCSV } from "../../../../utils/string";
import { stepLabels } from "../../utils/step-labels";
import getBasicInfoQuestions from "./data/questions";
import { SurveyModel } from "survey-react";
import { OnValueChangedOptions } from "../../../../common/components/forms";
import { ApplicationPurpose } from "../../../../common/data/application-purpose";

export default function useBasicInformationController() {
  const projects = useSelectorTyped((state) => state.projectsPEV);
  const basicInfo = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.basicInfo);
  const countryRegionalItLeadsPEV = useSelectorTyped((state) => state.countryRegionalItLeadsPEV);
  const businessFunctionsLeadsPEV = useSelectorTyped((state) => state.businessFunctionLeadsPEV);
  const loggedInUserEmailPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const applicationsPEV = useSelectorTyped((state) => state.applicationsCataloguePage.applicationsPEV);
  const applicationPurposesPEV = useSelectorTyped((state) => state.applicationPurposesPEV);
  const applicationPurposes = applicationPurposesPEV.value;
  const dispatch = useDispatch();

  const shouldGoToNextStep = (formData: Partial<IBasicInfo>) => {
    const selectedCountry = countryRegionalItLeadsPEV.value.find((data) => data.name === formData.country);
    if (selectedCountry) {
      formData.countryCode = selectedCountry.businessUnit;
    }
    formData.projectOwner = convertCSVToList(formData.projectOwner as unknown as string);
    formData.projectName = (formData.projectName as string).trim();
    if (formData.billingProjectName && formData.purpose === ApplicationPurpose.SPONSORED_CLIENT_PRE_SALES_OR_POC) {
      formData.billingProjectId = projects.value.find((project) => project.name === formData.billingProjectName)?.id;
    }
    else {
      formData.billingProjectId = null;
      formData.billingProjectName = null;
    }
    dispatch(experimentsAndInnovationsCategoryActions.updateBasicInfo(formData));
    return true;
  };

  const shouldGoToPreviousStep = (formData: Partial<IBasicInfo>) => {
    formData.projectOwner = convertCSVToList(formData.projectOwner as unknown as string);
    dispatch(experimentsAndInnovationsCategoryActions.updateBasicInfo(formData));
    return true;
  };

  const shouldRenderForm = () => !shouldRenderError() && getProgressMsg() === null;

  const getProgressMsg = () => {
    const shouldDisplayProgress = countryRegionalItLeadsPEV.progress !== null
      || applicationsPEV.progress != null
      || applicationPurposesPEV.progress !== null
      || loggedInUserEmailPEV.progress !== null
      || projects.progress !== null;
    return shouldDisplayProgress ? "Fetching details" : null;
  };

  const getErrorMsg = () => countryRegionalItLeadsPEV.error
    || businessFunctionsLeadsPEV.error
    || applicationsPEV.error
    || applicationPurposesPEV.error
    || loggedInUserEmailPEV.error
    || projects.error;

  const shouldRenderError = () => !!getErrorMsg();

  const existingApplicationNames = extractFieldValues<ApplicationBasicInfo, string>("name", applicationsPEV.value);

  const getBasicInfoDataForForm = () => ({
    ...basicInfo,
    projectOwner: (basicInfo.projectOwner && basicInfo.projectOwner.length > 0)
      ? convertListToCSV(basicInfo.projectOwner)
      : loggedInUserEmailPEV.value,
  });

  return {
    shouldGoToNextStep,
    shouldGoToPreviousStep,
    teamDetails: {
      teamId: basicInfo.teamId ?? "",
      teamName: basicInfo.teamName ?? "",
    },
    questions: getBasicInfoQuestions({
      countryNames: countryRegionalItLeadsPEV.value.map(({ name }) => name),
      businessFunctions: businessFunctionsLeadsPEV.value.map(({ businessFunction }) => businessFunction),
      applicationPurposes,
      existingApplicationNames,
      projectChoices: projects.value.map((project) => project.name),
    }),
    getBasicInfoDataForForm,
    shouldRenderForm,
    getProgressMsg,
    shouldRenderError,
    getErrorMsg,
    getStepLabelsForPage: () => stepLabels,
    onFormValueChange: (
      form: SurveyModel,
      options: OnValueChangedOptions,
    ) => {
      if (options.name === "purpose") {
        options.question.hasErrors(true);
      }
    },
  };
}
