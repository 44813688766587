import React, { FunctionComponent } from "react";
import EventsOverviewBanner from "./assets/images/events_overview_banner.png";
import MoreIcon from "./assets/images/more_icon.svg";
import "./index.scss";
import useEventsOverviewController from "./controller";
import Progress from "../../../common/components/progress";
// @ts-ignore
import {
  publisherArticles,
  subscriberArticles,
  whatsNew,
} from "./data/articles";
import ImpactXButton from "../../../common/components/impactx-button";
import EventsAnalytics from "../common/services/events-analytics";
import { HashLink as Link } from "react-router-hash-link";
const CATEGORY_LIMIT = 9;

const EventsOverview: FunctionComponent = () => {
  const controller = useEventsOverviewController();
  const eventMetricsPEV = controller.eventMetricsPEV;
  const categoryPEV = controller.categoryMetadataPEV;
  const masterDataCategoryPEV = controller.masterDataCategoryPEV;

  const renderBanner = () => (
    <div className="banner-section">
      <div className="banner-left-block">
        <div className="banner-container">
          <div className="banner-content">
            <h1>Highly Available, Reliable, Scalable</h1>
            <p className="banner-content-text">
              Provides your system with near-real time updates of
              {" "}
              <br />
              {" "}
              business events you care about.
            </p>
          </div>
        </div>
      </div>
      <div className="banner-right-block">
        <img
          src={EventsOverviewBanner}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );

  function renderLoadingIcon(message: string) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loading-icon">
          <Progress text={message} />
        </div>
      </div>
    );
  }

  function renderEventMetrics() {
    if (
      !isFetchingOverviewItemsInProgress()
      && !isFetchingOverviewItemsFailed()
    ) {
      return (
        <div className="event-metrics">
          <h3 className="event-metrics-title">Events Metrics</h3>
          <div className="event-metrics-content">
            <div className="metric-item">
              <div className="metric-name">
                Events published in the last day
              </div>
              <div className="metric-value">
                {eventMetricsPEV.value?.totalPublishedEventsForLastDay.toLocaleString()}
              </div>
            </div>
            <div className="metric-divider" />
            <div className="metric-item">
              <div className="metric-name">Count of event registrations</div>
              <div className="metric-value">
                {eventMetricsPEV.value?.totalEventRegistrations}
              </div>
            </div>
            <div className="metric-divider" />
            <div className="metric-item">
              <div className="metric-name">Count of subscriptions</div>
              <div className="metric-value">
                {eventMetricsPEV.value?.totalSubscriptions}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderArticles(
    title: string,
    articles: Array<{ title: string; description: string; link: string }>,
  ) {
    return (
      <div className="article-list">
        <h3 className="article-list-title">{title}</h3>
        <div className="article-wrapper">
          {articles.map((article) => (
            <div
              className="article-item"
              key={article.title}
            >
              <div className="article-name">{article.title}</div>
              <div className="article-description">{article.description}</div>
              <div className="article-link">
                <Link
                  onClick={() =>
                    EventsAnalytics.trackEventEventsArticlesLinkClick(
                      title,
                      article.title,
                    )}
                  to={article.link}
                >
                  <ImpactXButton outline>Know more</ImpactXButton>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderCategories() {
    if (
      !isFetchingOverviewItemsInProgress()
      && !isFetchingOverviewItemsFailed()
    ) {
      return (
        <div className="category-list">
          {controller.getSortedCategories().map((category) => (
            // @ts-ignore
            <div
              className="category-item"
              key={category.name}
              onClick={() => controller.handleCategoryClick(category.name)}
            >
              <div className="category-icon">
                <img src={category.icon} />
              </div>
              <div className="category-title">{category.name}</div>
            </div>
          ))}
          {controller.categoryMetadataPEV?.value
          && controller.categoryMetadataPEV?.value.length > CATEGORY_LIMIT && (
            <div
              className="category-item"
              key="more"
              onClick={
                // ts-ignore
                () => controller.handleViewMoreClick()
              }
            >
              <div className="category-icon">
                <img src={MoreIcon} />
              </div>
              <div className="category-title">More</div>
            </div>
          )}
        </div>
      );
    }
  }

  function isFetchingOverviewItemsInProgress() {
    if (
      eventMetricsPEV.progress
      || categoryPEV.progress
      || masterDataCategoryPEV.progress
    ) {
      return true;
    }
    return false;
  }

  function isFetchingOverviewItemsFailed() {
    if (
      eventMetricsPEV.error
      || categoryPEV.error
      || masterDataCategoryPEV.error
    ) {
      return true;
    }
    return false;
  }

  function renderOverviewLoadingIcon() {
    if (
      isFetchingOverviewItemsInProgress()
      && !isFetchingOverviewItemsFailed()
    ) {
      return renderLoadingIcon("Fetching events metrics.");
    }
  }

  function renderFetchFailureMessage() {
    if (isFetchingOverviewItemsFailed()) {
      return (
        <div className="event-overview-error">
          Failed to fetch event metrics
        </div>
      );
    }
  }

  function renderWhatsNew(
    features: Array<{
      title: string;
      description: string;
      link: string;
      section: string;
      image: string;
      target: string;
    }>,
  ) {
    return (
      <div className="whats-new">
        <h3 className="whats-new-title">What&apos;s New</h3>
        <div className="feature-wrapper">
          {features.map((feature) => (
            <div
              className="feature-item"
              key={feature.title}
            >
              <div className="feature-image">
                <img src={feature.image} />
              </div>
              <div className="feature-content">
                <div className="feature-title">{feature.title}</div>
                <div className="feature-description">
                  {feature.description}
                </div>
                <Link
                  onClick={() =>
                    EventsAnalytics.trackEventWhatsNewInEvents(feature.title)}
                  target={feature.target}
                  rel="noreferrer"
                  to={{ pathname: feature.link, hash: feature.section }}
                >
                  <div className="feature-link">&#8594;</div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const renderOverview = () => (
    <div className="overview">
      <h3 className="overview-title">Discover Events</h3>
      <div className="search-box" />
      {renderOverviewLoadingIcon()}
      {renderFetchFailureMessage()}
      {renderCategories()}
      {renderEventMetrics()}
    </div>
  );
  return (
    <div className="EventsOverview">
      <div style={{ width: "100%" }}>
        {renderBanner()}
        <div className="overview-content">{renderOverview()}</div>
        <div className="article-content">
          {renderArticles("Publish Events", publisherArticles)}
          {renderArticles("Consume Events", subscriberArticles)}
        </div>
        {renderWhatsNew(whatsNew)}
      </div>
    </div>
  );
};
export default EventsOverview;
