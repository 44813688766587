import React, { FunctionComponent } from "react";
import "./index.scss";

const NotPartOfGITSLegend: FunctionComponent = () => (
  <div className="NotPartOfGITSLegend">
    <span className="legend-title">** </span>
    Not part of GITS
  </div>
);

export default NotPartOfGITSLegend;
