import { useSelectorTyped } from "../../../../../common/redux/store";
import YesNoChoices from "../../../data/yes-no-choice";
import { getGcpProjectNames } from "../../../data/common-functions";

export default function useInfraConfigReviewController() {
  const infraConfig = useSelectorTyped((state) => state.getStartedPage.gits.infraConfig);

  const isGcpProjectRequested = () => infraConfig.isHostingServiceNeeded === YesNoChoices.YES;

  const isGithubRepoRequested = () => infraConfig.isCodeRepositoryNeeded === YesNoChoices.YES;

  const isInfraRequested = () => infraConfig.isCodeRepositoryNeeded === YesNoChoices.YES
    || infraConfig.isHostingServiceNeeded === YesNoChoices.YES;

  const getGithubRepoRequestedStatus = () => isGithubRepoRequested() ? "Requested" : "Not requested";

  const getGcpProjectRequestedStatus = () => isGcpProjectRequested() ? "Requested" : "Not requested";

  const getCircleCiRequestedStatus = () => infraConfig.isCiCdPipelineNeeded === YesNoChoices.YES ? "Requested" : "Not requested";

  return {
    infraConfig,
    isInfraRequested,
    isGcpProjectRequested,
    isGithubRepoRequested,
    getCircleCiRequestedStatus,
    getGithubRepoRequestedStatus,
    getGcpProjectRequestedStatus,
    getGcpProjectNames: () => getGcpProjectNames(infraConfig.gcpProjects),
  };
}
