import { teamTypeLabelMap } from "../../data/team-types";
import { useHistory } from "react-router-dom";
import { IDataTableColumn } from "react-data-table-component";
import { ApplicationBasicInfo } from "../../../pages/applications/application-details/redux";
import { buildApplicationDetailsPageLink } from "../../../utils/navigation";
import { getEnvVars } from "../../config/env";

export type Props = {
  applications: ApplicationBasicInfo[];
};

export function useApplicationTableViewController(props: Props) {
  const history = useHistory();
  const { unificationConfig } = getEnvVars();

  const columns: IDataTableColumn[] = [
    {
      name: "Application Name",
      selector: "name",
      sortable: true,
      sortFunction: (row1: ApplicationBasicInfo, row2: ApplicationBasicInfo) =>
        row1.name.localeCompare(row2.name),
    },
    {
      name: "Type",
      selector: "teamType",
      cell: (row: ApplicationBasicInfo) => teamTypeLabelMap[row.team.teamType],
      sortable: true,
    },
    {
      name: "Country",
      selector: "countryName",
      sortable: true,
    },
    {
      name: "Business Function",
      selector: "businessFunction",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
  ];

  const onRowClicked = (applicationBasicInfo: ApplicationBasicInfo, event: MouseEvent) => {
    const applicationDetailsPageLink = buildApplicationDetailsPageLink(applicationBasicInfo);
    if (event.metaKey || event.ctrlKey) {
      window.open(applicationDetailsPageLink, "_blank");
    }
    else {
      history.push(applicationDetailsPageLink);
    }
  };

  return ({
    applications: props.applications,
    columns,
    onRowClicked,
    environmentConfig: { ...unificationConfig.environmentSpecificConfig },
  });
}
