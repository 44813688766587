import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../createAsyncThunkWithRejectValue";
import { businessFunctionsService } from "../../services/business-functions";

export interface IBusinessFunctionLead {
  businessFunction: string;
  leadNames: string;
  leadEmailAddresses: string;
  status: string;
  description: string;
  icon: string;
}

type IBusinessFunctionLeadsState = ProgressErrorValue<IBusinessFunctionLead[], Nullable<string>, Nullable<INeoErrorInfo>>;

const initialState: IBusinessFunctionLeadsState = PEV([]);

const fetchBusinessFunctionLeads = createAsyncThunkWithRejectValue<IBusinessFunctionLead[]>(
  "business-function-leads",
  businessFunctionsService.fetchBusinessFunctionLeads,
);

export const businessFunctionLeadsSlices = createSlice({
  name: "businessFunctionLeads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessFunctionLeads.pending, () =>
        PEV([], "Fetching business function leads"),
      )
      .addCase(fetchBusinessFunctionLeads.fulfilled, (state, action) =>
        PEV(action.payload, null, null),
      )
      .addCase(fetchBusinessFunctionLeads.rejected.type, (state, action: PayloadAction<NeoError>) =>
        PEV([], null, action.payload.getErrorInfo()),
      );
  },
});

export const dispatchFetchBusinessFunctionLeadsIfEmpty = (
  dispatch: Dispatch<ReturnType<typeof fetchBusinessFunctionLeads>>,
  businessFunctionLeadsPEV: IBusinessFunctionLeadsState,
) => {
  if (businessFunctionLeadsPEV.value.length === 0 && !businessFunctionLeadsPEV.progress && !businessFunctionLeadsPEV.error) {
    dispatch(fetchBusinessFunctionLeads());
  }
};

export const businessFunctionLeadsReducer = businessFunctionLeadsSlices.reducer;
