import { useRerenderComponentWhenMicroFrontendIsLoaded } from "../../../hooks/mff-loaded";
import { getEnvVars, getMicroFrontendDataFromId } from "../../../config/env";
import { ADR_MFF_ID, API_GW_MFF_ID, DATA_CATALOGUE_GW_MFF_ID, FOUR_KEY_METRICS_MFF_ID, MASTER_DATA_MFF_ID } from "../../../data/microfrontend-types";
import { useGetFeatureToggleState } from "../../../hooks/feature-toggles";

export default function useHeaderMenuController() {
  useRerenderComponentWhenMicroFrontendIsLoaded();
  const { unificationConfig } = getEnvVars();
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const isAdrMicroFrontendToggleEnabled = useGetFeatureToggleState("Neo_AdrMicroFrontend_Addition");

  return {
    isApiUnificationToggleEnabled,
    isAdrMicroFrontendToggleEnabled,
    apiGwMenus: getMicroFrontendDataFromId(API_GW_MFF_ID)?.getHeaderMenus() ?? null,
    adrMenus: getMicroFrontendDataFromId(ADR_MFF_ID)?.getHeaderMenus() ?? null,
    masterDataMenus: getMicroFrontendDataFromId(MASTER_DATA_MFF_ID)?.getHeaderMenus() ?? null,
    dataCatalogueMenus: getMicroFrontendDataFromId(DATA_CATALOGUE_GW_MFF_ID)?.getHeaderMenus() ?? null,
    fourKeyMetricsMenus: getMicroFrontendDataFromId(FOUR_KEY_METRICS_MFF_ID)?.getHeaderMenus() ?? null,
    environmentConfig: { ...unificationConfig.environmentSpecificConfig },
  };
}
