import React, { FunctionComponent } from "react";
import useTeamDetailsController from "./controller";
import "./index.scss";
import Details from "../../applications/application-details/details";
import { Hash } from "../../../utils/hash";
import Panel from "../../../common/components/panel";
import EditButton from "../../../common/components/edit-button";

export const TeamInformation: FunctionComponent = () => {
  const controller = useTeamDetailsController();
  const HeadersMap: Hash<string> = {
    description: "Description",
    contactEmail: "Contact email address",
    supportEmail: "Support email address",
  };

  const renderInternalOpenSourceField = () => {
    if (controller.shouldRenderInternalOpenSource()) {
      return (
        <Details
          key="isOpen"
          fieldName="Internal open source"
        >
          <span>
            <span className="collaboration-type-value">
              {`${controller.getInternalOpenSourceField().value}: `}
            </span>
            {controller.getInternalOpenSourceField().description}
          </span>
        </Details>
      );
    }
  };

  const renderTeamStatus = () => (
    <Details
      key="teamStatus"
      fieldName="Status"
    >
      <span>
        <span className="collaboration-type-value">
          {`${controller.getStatus() as string}: `}
        </span>
        {controller.getTeamStatusDescription()}
      </span>
    </Details>
  );

  const renderEditButton = () => (
    !(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox)
      ? (
        <EditButton
          ariaLabel="Edit team"
          id="edit-button-team-details"
          onClickHandler={controller.onClickEditButton}
          showButton={controller.isEditable()}
        />
      )
      : <></>
  );

  const renderTeamDetails = () => (
    <div>
      {renderEditButton()}
      {controller.getTeamDetailFields.map((field) =>
        (
          <Details
            key={field}
            fieldName={HeadersMap[field]}
            value={controller.getFieldValue(field) as string}
          />
        ),
      )}
      {renderInternalOpenSourceField()}
      {renderTeamStatus()}
    </div>
  );

  function renderContent() {
    return (
      <>
        {
          controller.projectAssociationToggleEnabled
            ? (
              <Panel
                content={renderTeamDetails()}
              />
            )
            : renderTeamDetails()
        }
      </>
    );
  }

  return (
    <div className="TeamInformation">
      {controller.shouldRenderTeamDetails() && renderContent()}
    </div>
  );
};
