
import ReactDOMServer from "react-dom/server";
import { ReactElement } from "react";

export type Choice = {
  value: string;
  text: string;
  component?: ReactElement;
};

export const paginatedDropdownControl = (data: string[] | Choice[], minimumInputLength: number = 0) => {
  const isDataTypeString = () => typeof data[0] === "string";

  return ({
    minimumInputLength,
    ajax: {
      delay: 0,
      transport(params: any, success: any) {
        const pageSize = 10;
        const term = (params.data.term || "").toLowerCase();
        const page = (params.data.page || 1);
        let timer;
        if (timer) {
          clearTimeout(timer);
        }

        let results: string | any[];
        timer = setTimeout(function() {
          timer = null;
          if (isDataTypeString()) {
            results = (data as string[])
              .filter(function(f) {
                return f.toLowerCase().includes(term);
              })
              .map(function(f) {
                return { id: f, text: f };
              });
          }
          else {
            results = (data as Choice[])
              .filter(function(f) {
                return f.text.toLowerCase().includes(term);
              })
              .map(function(f) {
                return { id: f.value, text: f.text, component: f.component };
              });
          }

          const paged = results.slice((page - 1) * pageSize, page * pageSize);

          const options = {
            results: paged,
            pagination: {
              more: results.length >= page * pageSize,
            },
          };
          success(options);
        }, params.delay);
      },
    },
    escapeMarkup(markup: any) {
      return markup;
    },
    templateResult(dataItem: any) {
      return dataItem.component ? ReactDOMServer.renderToString(dataItem.component) : dataItem.text;
    },
    templateSelection(dataItem: any) {
      return dataItem.component ? ReactDOMServer.renderToString(dataItem.component) : dataItem.text;
    },
  });
};
