import { TeamTypeReadableLabel } from "../../pages/create-team/redux";
import { collaborationType, ITeam, Role } from "../../pages/team-details/redux";
import { Nullable } from "../../utils/nullable";
import { getEnvVars } from "../config/env";
import { IUserAdditionalInfo } from "../components/header/redux";
import { DeveloperTool } from "../../pages/catalogue/developer-tools/redux";

let setMatomoAnalyticsTracker: (_matomoAnalyticsTracker: Nullable<IMatomoAnalyticsTracker>) => void;

const matomoAnalyticsTrackerPromise = new Promise<Nullable<IMatomoAnalyticsTracker>>((resolve) => {
  setMatomoAnalyticsTracker = resolve;
});

export default class Analytics {
  public static trackPageMyApplications() {
    void this.trackPage("My Applications");
  }

  public static trackPageMyAppsAndServices() {
    void this.trackPage("My Apps & Services");
  }

  public static trackPageApplicationCatalogue() {
    void this.trackPage("Application Catalogue");
  }

  public static trackPageDeveloperTools() {
    void this.trackPage("Developer Tools");
  }

  public static trackPageDeveloperToolDetails() {
    void this.trackPage("Developer Tool Details");
  }

  public static trackPageInfrastructureOfferings() {
    void this.trackPage("Infrastructure Offerings");
  }

  public static trackPageApplicationDetails() {
    void this.trackPage("Application Details > Basic Info tab");
  }

  public static trackPageAppManageInfrastructureDetails() {
    void this.trackPage("Application Details > Manage Infrastructure tab");
  }

  public static trackPageAppNeedAdditionalSupport() {
    void this.trackPage("Application Details > Need Additional Support tab");
  }

  public static trackEventLifespanExtend() {
    void this.trackEvent("Application Details", "Extend");
  }

  public static trackPageGetStarted() {
    void this.trackPage("Get Started");
  }

  public static trackPageGetStartedExperimentAndInnovation() {
    void this.trackPage("Get Started > Experiment & Innovation");
  }

  public static trackPageGetStartedOthers() {
    void this.trackPage("Get Started > Region IT");
  }

  public static trackPageGetStartedGITS() {
    void this.trackPage("Get Started > GITS");
  }

  public static trackPageTeamDetails() {
    void this.trackPage("Team Details");
  }

  public static trackPageMyTeams() {
    void this.trackPage("My Teams");
  }

  public static trackPageAllTeams() {
    void this.trackPage("All Teams");
  }

  public static trackPageTeams() {
    void this.trackPage("Teams");
  }

  public static trackPageCreateTeam() {
    void this.trackPage("Create Team");
  }

  public static trackPageMyRequests() {
    void this.trackPage("My Requests");
  }

  public static trackPageMyTasks() {
    void this.trackPage("My Tasks");
  }

  public static trackPageRequestDetails() {
    void this.trackPage("Request Details");
  }

  public static trackPageTaskDetails() {
    void this.trackPage("Task Details");
  }

  public static trackEventSearchApplicationsFromApplicationsPage() {
    void this.trackEvent("Application Catalogue", "Search Applications");
  }

  public static trackEventFilterApplicationsFromApplicationsPage() {
    void this.trackEvent("Application Catalogue", "Filter Applications");
  }

  public static trackEventResetFiltersFromApplicationsPage() {
    void this.trackEvent("Application Catalogue", "Reset Filters");
  }

  public static trackEventExportApplicationsToCSVFromApplicationsPage() {
    void this.trackEvent("Application Catalogue", "Export to CSV");
  }

  public static trackEventClickOnApplicationCardFromApplicationsPage(appName: string) {
    void this.trackEvent("Application Catalogue", "Application Card", appName);
  }

  public static trackEventClickOnCardFromDeveloperToolsPage(name: string, taxonomyCategory: string) {
    void this.trackEvent("Developer Tools", `${taxonomyCategory} Card`, name);
  }

  public static trackEventSearchFromMyAppsAndServicesPage() {
    void this.trackEvent("My Apps & Services", "Search");
  }

  public static trackEventExportMyApplicationsToCSVFromMyAppsAndServicesPage() {
    void this.trackEvent("My Apps & Services", "Export to CSV");
  }

  public static trackEventClickOnApplicationCardFromMyAppsAndServicesPage(appName: string) {
    void this.trackEvent("My Apps & Services", "Application Card", appName);
  }

  public static trackEventFilterMyApplicationsFromMyAppsAndServicesPage() {
    void this.trackEvent("My Apps & Services", "Filter Applications");
  }

  public static trackEventResetFiltersFromMyAppsAndServicesPage() {
    void this.trackEvent("My Apps & Services", "Reset Filters");
  }

  public static trackEventOtherTechStackInGetStarted(unavailableTechStack: string) {
    void this.trackEvent("Get Started", "Other Tech Stack", unavailableTechStack);
  }

  public static trackEventClickOnHomePageItems(sectionTitle: string, sectionText: string) {
    void this.trackEvent("Landing Page", `${sectionTitle} - ${sectionText}`);
  }

  public static trackEventClickHomePageBannerLinks(bannerTitle: string, linkText: string) {
    void this.trackEvent("Landing Page", `Click '${linkText}' in ${bannerTitle} banner`);
  }

  public static trackEventEditApplicationBasicDetails() {
    void this.trackEvent("Edit Application Details", "Update Application Basic Details");
  }

  public static trackEventEditApplicationName() {
    void this.trackEvent("Edit Application Details", "Update Application Name");
  }

  public static trackEventEditApplicationStatus() {
    void this.trackEvent("Edit Application Details", "Update Application Status");
  }

  public static trackEventSearchTeamsFromAllTeamsTab() {
    void this.trackEvent("All Teams", "Search Teams");
  }

  public static trackEventFilterTeamsFromAllTeamsTab() {
    void this.trackEvent("All Teams", "Filter Teams");
  }

  public static trackEventExportAllTeamsToCSVFromMyTeamsPage() {
    void this.trackEvent("All Teams", "Export to CSV");
  }

  public static trackEventSearchTeamsFromMyTeamsTab() {
    void this.trackEvent("My Teams", "Search Teams");
  }

  public static trackEventFilterTeamsFromMyTeamsTab() {
    void this.trackEvent("My Teams", "Filter Teams");
  }

  public static trackEventExportMyTeamsToCSVFromMyTeamsPage() {
    void this.trackEvent("My Teams", "Export to CSV");
  }

  public static trackEventClickEditTeamDetails() {
    void this.trackEvent("Team Details", "Click on Edit Details button");
  }

  public static trackEventEditAndSaveTeamDetails() {
    void this.trackEvent("Team Details", "Team details updated");
  }

  public static trackEventClickAddTeamMembers() {
    void this.trackEvent("Team Details", "Click on Add members button");
  }

  public static trackEventAddAndSaveTeamMembers(team: ITeam) {
    void this.trackEvent("Team Details", "# of times members added to the team", `id=${team.id}, name=${team.name}, type=${team.teamType}`);
  }

  public static trackEventClickMakeManager() {
    void this.trackEvent("Team Details", "Make Manager");
  }

  public static trackEventClickMakeDeveloper() {
    void this.trackEvent("Team Details", "Make Developer");
  }

  public static trackEventClickMakeNonDeveloper() {
    void this.trackEvent("Team Details", "Make Non-Developer");
  }

  public static trackEventExportCSVActivityHistoryOfTeamFromTeamDetailsPage() {
    void this.trackEvent("Team Details > Activity History", "Export to CSV");
  }

  public static trackEventExportCSVTeamMembersDetailsFromTeamDetailsPage() {
    void this.trackEvent("Team Details > Members", "Export to CSV");
  }

  public static trackEventSearchTeamMembers() {
    void this.trackEvent("Team Details > Members", "Search");
  }

  public static trackEventExportCSVActivityHistoryOfApplication() {
    void this.trackEvent("Application Details > Activity Tab", "Export to CSV");
  }

  public static trackEventClickSubHeaderTab(page: string, tab: string) {
    void this.trackEvent(page, "Sub Header", tab);
  }

  public static trackEventCreateTeam(teamType: TeamTypeReadableLabel, openOrClosed: collaborationType) {
    void this.trackEvent(
      "Create Team",
      "Teams created",
      teamType === TeamTypeReadableLabel.EXPERIMENT_AND_INNOVATION
        ? `${teamType} - ${openOrClosed}`
        : teamType,
    );
  }

  public static trackEventClickJoinTeamButton(pageTitle: string, role: Role) {
    void this.trackEvent(pageTitle, "Members joined open teams", role);
  }

  public static trackEventClickExitTeamButton(pageTitle: string) {
    void this.trackEvent(pageTitle, "Member exited team");
  }

  public static trackEventClickOnCreateTeamButton(fromPage: string) {
    void this.trackEvent("Create Team", "Click on Create Team button", fromPage);
  }

  public static trackEventCreateTeamTypeSelection(teamType: TeamTypeReadableLabel) {
    void this.trackEvent("Create Team", "Create Team category selection", teamType);
  }

  public static trackEventClickRemoveTeamMember(team: ITeam) {
    void this.trackEvent("Team Details", "Remove member", `id=${team.id},name=${team.name},type=${team.teamType}`);
  }

  public static trackEventClickRemoveMultipleTeamMembers(team: ITeam) {
    void this.trackEvent("Team Details", "Remove multiple members", `id=${team.id},name=${team.name},type=${team.teamType}`);
  }

  public static trackEventDeactivateTeam() {
    void this.trackEvent("Team Details", "Deactivate team");
  }

  public static trackEventUpdateTeamCollaborateType(type: collaborationType) {
    void this.trackEvent("Team Details", `Changed to ${type} team`);
  }

  public static trackEventCreateExperimentAndInnovationApplication() {
    void this.trackEvent("Create Application", "Experiment & Innovation");
  }

  public static trackEventCreateOthersApplication() {
    void this.trackEvent("Create Application", "Others");
  }

  public static trackEventCreateGITSApplication() {
    void this.trackEvent("Create Application", "GITS");
  }

  public static trackEventClickOnManageMenuItems(menuItem: string) {
    void this.trackEvent("Header", "Manage Menu", menuItem);
  }

  public static trackEventClickOnToggleButton() {
    void this.trackEvent("Header", "Switch Environment");
  }

  public static trackEventClickOnHeaderItems(item: string) {
    void this.trackEvent("Header", item);
  }

  public static trackEventClickOnFeedbackButton() {
    void this.trackEvent("Landing Page", "Feedback");
  }

  public static trackEventRequestAdditionalInfra() {
    void this.trackEvent("Application Infrastructure", "Request additional infra", "GitHub Repo & GCP");
  }

  public static trackEventDecommissionApplication() {
    void this.trackEvent("Application Infrastructure", "Need additional support", "Decommission Application");
  }

  public static trackEventClickOnDownloadDeveloperToolButton(name: DeveloperTool["name"]) {
    void this.trackEvent("Developer Tool Details", "Download Source Code", name);
  }

  public static trackEventAssociateProject() {
    void this.trackEvent("Team Details", "Associate Projects");
  }

  public static trackEventDissociateProject() {
    void this.trackEvent("Team Details", "Dissociate Project");
  }

  public static trackEventRetriggerTask() {
    void this.trackEvent("Task Details", "Retrigger Task");
  }

  public static initMatomoClient(user: IUserAdditionalInfo) {
    console.log("init Matomo client");
    // eslint-disable-next-line no-underscore-dangle
    const matomoClient = window._paq = window._paq || [];
    matomoClient.push(["setDomains", [window.location.hostname]], ["enableHeartBeatTimer", 5]);
    (function() {
      const { matomo } = getEnvVars();
      matomoClient.push(
        ["setTrackerUrl", `${matomo.baseUrl}matomo.php`],
        ["setSiteId", matomo.siteId],
        ["setUserId", user.emailId],
        ["setCustomDimension", 1, user.thoughtworksRole],
        ["setCustomDimension", 2, user.departmentCode],
        ["setCustomDimension", 3, user.country],
        ["setCustomDimension", 4, user.employeeId],
      );

      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.async = true;
      scriptElement.src = `${matomo.baseUrl}matomo.js`;

      const bodyElement = document.getElementsByTagName("body")[0];
      bodyElement.append(scriptElement);
    })();
  }

  public static enableTracking(user: IUserAdditionalInfo) {
    this.initMatomoClient(user);
    setTimeout(() => {
      // eslint-disable-next-line no-underscore-dangle
      setMatomoAnalyticsTracker(window._paq!);
    }, 8000); // need this for the script to load.
  }

  public static disableTracking() {
    setMatomoAnalyticsTracker(null);
  }

  public static getMatomoAnalyticsTracker() {
    return matomoAnalyticsTrackerPromise;
  }

  public static async trackPage(pageName: string) {
    const analyticsTracker = await this.getMatomoAnalyticsTracker();
    if (analyticsTracker) {
      analyticsTracker.push(["setDocumentTitle", pageName], ["setCustomUrl", window.location.href], ["trackPageView"]);
    }
  }

  public static async trackEvent(category: string, action: string, name?: string, value?: number) {
    const analyticsTracker = await this.getMatomoAnalyticsTracker();
    if (analyticsTracker) {
      const params: Array<string | number> = ["trackEvent", category, action];
      if (name) {
        params.push(name);
      }
      if (value) {
        params.push(value);
      }
      analyticsTracker.push(params);
    }
  }
}

window.getMatomoAnalyticsTracker = Analytics.getMatomoAnalyticsTracker;
window.trackPage = Analytics.trackPage;
window.trackEvent = Analytics.trackEvent;
