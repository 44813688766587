import React, { FunctionComponent } from "react";
import "./index.scss";

type Props = {
  value: string;
  shouldDifferentiateNonGITSMembers: boolean;
};

export const HighlightValueAsNonGITS: FunctionComponent<Props> = (props: Props) => {
  const { value, shouldDifferentiateNonGITSMembers } = props;

  const renderHighlightNonGITSMember = () => (
    shouldDifferentiateNonGITSMembers
    && <span className="non-gits-member-highlight">**</span>
  );

  return (
    <span className="PotentialMemberTagBoxOption">
      {value}
      {renderHighlightNonGITSMember()}
    </span>
  );
};
