import { getEmailIdValidator, getTeamNameValidatorConfigForEditTeamPage } from "../../../../utils/teams/validator";
import { TeamType } from "../../../create-team/redux";

type Options = {
  teamNames: string[];
  currentTeamName: string;
  teamType: TeamType;
};

const getInternalOpenSourceQuestion = ({ teamType }: Options) => {
  if (teamType === TeamType.EXPERIMENT_AND_INNOVATION) {
    return [{
      type: "radiogroup",
      name: "isOpen",
      title: "Internal open source",
      isRequired: true,
      choices: [
        {
          text: "<strong>Yes:</strong> All the applications created by this team will be internal open source and "
            + "any Thoughtworker can join this team and contribute to code.",
          value: true,
        },
        {
          text: "<strong>No:</strong> Only team members can work on the applications created by this team.",
          value: false,
        },
      ],
    }];
  }
  return [];
};

export const getEditTeamQuestions = (options: Options) => [
  {
    type: "text",
    name: "name",
    title: "Name",
    description: "Accepts 4 - 50 characters (lower/upper case alphabets, numbers, hyphen(-) and space)",
    descriptionLocation: "underTitle",
    isRequired: true,
    validators: getTeamNameValidatorConfigForEditTeamPage(options.teamNames, options.currentTeamName),
  },
  {
    name: "description",
    type: "comment",
    title: "Description",
    description: "A brief of the team's objective.",
    descriptionLocation: "underTitle",
    isRequired: true,
    rows: 3,
  },
  {
    title: "Contact email address",
    type: "text",
    name: "contactEmail",
    description: "pos--@thoughtworks.com",
    descriptionLocation: "none",
    isRequired: true,
    validators: getEmailIdValidator(),
  },
  {
    title: "Support email address",
    name: "supportEmail",
    type: "text",
    isRequired: true,
    description: "pos--@thoughtworks.com",
    descriptionLocation: "none",
    visibleIf: "{isSupportEmailSameAsContact} empty",
    validators: getEmailIdValidator(),
  },
  ...getInternalOpenSourceQuestion(options),
];
