import React from "react";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useRequestDetailsPageController, { RequestDetailsPageProps } from "./controller";
import BackButton from "../../../common/components/back-button";
import ContainerBox from "../../../common/components/container-box";
import RequestAndTasks from "../request-and-tasks";

const RequestDetails = createFunctionalComponentFromView<ReturnType<typeof useRequestDetailsPageController>, RequestDetailsPageProps>(
  useRequestDetailsPageController,
  (controller) => (
    <div className="RequestDetails">
      <ContainerBox
        componentToRenderInEmptySpace={(
          <BackButton />
        )}
        isDisplayingBackButton={true}
      >
        <RequestAndTasks
          requestId={controller.requestId}
          requestsPEV={controller.myRequestDetailsPEV}
          showActions={false}
          taskDetailTitle="Request details"
        />
      </ContainerBox>
    </div>
  ),
);

export default RequestDetails;
