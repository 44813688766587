import React, { FunctionComponent, PropsWithChildren } from "react";
import { CSVLink } from "react-csv";
import { CommonPropTypes } from "react-csv/components/CommonPropTypes";
import "./index.scss";
import ImpactXButton from "../impactx-button";

type Props = CommonPropTypes;

export const ExportCSVLink: FunctionComponent<Props> = ({ children, ...restOfProps }: PropsWithChildren<Props>) => (
  <CSVLink
    className="csv-link-neo"
    {...restOfProps}
  >
    {children
      ? children
      : (
        <ImpactXButton outline={true}>
          Export to CSV
        </ImpactXButton>
      )}
  </CSVLink>
);
