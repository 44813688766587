import Analytics from "../../../../common/services/analytics";

export default class EventsAnalytics {
  public static trackPageEventDiscovery() {
    void Analytics.trackPage("Events Discovery");
  }

  public static trackPageCategory(category: string) {
    void Analytics.trackEvent("Events Discovery > Category", category);
  }

  public static trackPageEventDetails(categoryName: string, eventFullName: string) {
    void Analytics.trackPage(`Events Discovery > Category > ${categoryName} > Event Details > ${eventFullName}`);
    void Analytics.trackEvent(`Events Discovery > Category > ${categoryName}`, `Event Details > ${eventFullName}`);
  }

  public static trackEventEventDetailsLinkClick(action: string) {
    void Analytics.trackEvent("Events Discovery > Category > Event Details", action);
  }

  public static trackEventsUserGuide() {
    void Analytics.trackPage("User Guide");
  }

  public static trackPageEventsOverview() {
    void Analytics.trackPage("Events Overview");
  }

  public static trackPageCategoriesClickFromOverview(category: string) {
    void Analytics.trackEvent("Events Overview > Discover Events > Category", category);
  }

  public static trackEventEventsArticlesLinkClick(title: string, action: string) {
    void Analytics.trackEvent(`Events Overview > ${title}`, action);
  }

  public static trackEventWhatsNewInEvents(action: string) {
    void Analytics.trackEvent("Events Overview > Whats New", action);
  }
}
