import React from "react";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useExportTeamsController, { Props } from "./controller";
import "./index.scss";
import { ExportCSVLink } from "../../../common/components/export-csv";

const ExportTeams = createFunctionalComponentFromView<ReturnType<typeof useExportTeamsController>, Props>(
  useExportTeamsController,
  (controller) => (
    <div className="export-link">
      <ExportCSVLink
        data={controller.getCSVDataForDownload()}
        filename={controller.getCSVFileName()}
        onClick={() => controller.trackAnalytics()}
      />
    </div>
  ),
);

export default ExportTeams;
