import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import ApplicationsListView from "../../../common/components/applications-list-view";
import React from "react";
import "./index.scss";
import useTeamApplicationsController from "./controller";
import Panel from "../../../common/components/panel";

export const TeamApplicationsTable = () => {
  const controller = useTeamApplicationsController();
  const renderContent = () => {
    if (controller.shouldRenderApplicationsProgressMessage()) {
      return (
        <Progress text={controller.applicationsFetchProgressText} />
      );
    }
    if (controller.shouldRenderApplicationsErrorMessage()) {
      return (
        <Error

          {...mapApplicationErrorInfoToErrorComponentProps(controller.applicationsFetchErrorText!)}
        />
      );
    }
    if (controller.shouldRenderNoApplicationMessage()) {
      return (
        <span>
          There is no application associated with this team.
        </span>
      );
    }
    return (
      <ApplicationsListView
        applications={controller.getApplications()}
      />
    );
  };

  return (
    <div className="TeamApplications">
      {
        controller.projectAssociationToggleEnabled
          ? (
            <Panel
              content={renderContent()}
            />
          )
          : (
            <>
              <div className="Heading">Applications</div>
              {renderContent()}
            </>
          )
      }
    </div>
  );
};
