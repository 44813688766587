import React from "react";
import logoImg from "./assets/tw-logo.png";
import "./index.scss";
import { getEnvVars } from "../../config/env";
import NeoLink from "../neo-link";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";

export function Footer() {
  const { feedbackEmailId, unificationConfig } = getEnvVars();
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");

  function renderPlatformHubLink() {
    return (
      <NeoLink
        link="https://central.thoughtworks.net/home/gits-digital-platforms"
        label="Check Platforms Central"
        external
      />
    );
  }

  function renderContactUs() {
    return (
      <NeoLink
        link={`mailto:${feedbackEmailId}`}
        label="Contact us"
        external
      />
    );
  }

  function renderPoweredBySection() {
    return (
      <div className="powered-by">
        <span>POWERED BY</span>
        &nbsp;
        <img
          alt="logo"
          src={logoImg}
        />
      </div>
    );
  }

  function renderContent() {
    return (
      <div className="contents">
        <div>
          Need more information ? |
          &nbsp;
          {renderPlatformHubLink()}
          &nbsp;
          or
          &nbsp;
          {renderContactUs()}
        </div>
        {renderPoweredBySection()}
      </div>
    );
  }

  return (
    <div className="bg-color-white">
      <div className="Footer">
        <div
          className="spacer"
          style={{ background: isApiUnificationToggleEnabled ? unificationConfig.environmentSpecificConfig.backgroundColor : "#FFFFFF" }}
        />
        <div className="FooterWrapper">
          <hr />
          {renderContent()}
        </div>
      </div>
    </div>
  );
}
