import { IPage } from "../../../common/components/app/pages";
import MyTasks from "../my-tasks";
import MyRequests from "../my-requests";
import TaskDetails from "../task-details";
import RequestDetails from "../request-details";

export class RequestsPages {
  public static readonly MY_REQUESTS: IPage = {
    title: "My Requests",
    path: "/requests/my-requests",
    component: MyRequests,
  };

  public static readonly MY_TASKS: IPage = {
    title: "My Tasks",
    path: "/requests/my-tasks",
    component: MyTasks,
  };

  public static readonly TASK_DETAILS: IPage = {
    title: "Task Details",
    path: "/requests/:requestId/tasks",
    component: TaskDetails,
  };

  public static readonly REQUEST_DETAILS: IPage = {
    title: "Request Details",
    path: "/requests/:requestId",
    component: RequestDetails,
  };
}
