import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { GetStartedPageActions } from "../../redux";
import Analytics from "../../../../common/services/analytics";
import { stepLabels } from "../../utils/step-labels";
import { TeamType } from "../../../create-team/redux";
import React, { useRef, useState } from "react";

export default function useReviewAndSubmitController() {
  const dispatch = useDispatch();
  const basicInfo = useSelectorTyped((state) => state.getStartedPage.others.basicInfo);
  const infraConfig = useSelectorTyped((state) => state.getStartedPage.others.infraConfig);
  const applicationCreateRequestProgress = useSelectorTyped((state) => state.getStartedPage.createApplication.progress);
  const isAcknowledged = useRef(false);
  const [showError, setShowError] = useState(false);

  const setAcknowledgementError = () => {
    if (isAcknowledged.current) {
      setShowError(false);
    }
    else {
      setShowError(true);
    }
  };

  return {
    basicInfo,
    infraConfig,
    onSubmitBtnClick: () => {
      setAcknowledgementError();
      if (isAcknowledged.current) {
        dispatch(GetStartedPageActions.createApplication({
          application: { basicInfo, infraConfig },
          teamType: TeamType.OTHERS,
        }));
        Analytics.trackEventCreateOthersApplication();
      }
    },
    getStepLabelsForPage: () => stepLabels,
    isCreateApplicationRequestInProgress: () => applicationCreateRequestProgress !== null,
    onAcknowledgementChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
      isAcknowledged.current = event.target.checked;
      setAcknowledgementError();
    },
    showError,
  };
}
