import { useSelectorTyped } from "../../../common/redux/store";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";

export default function useTeamApplicationsController() {
  const {
    value: applications,
    error: applicationsFetchErrorText,
    progress: applicationsFetchProgressText,
  } = useSelectorTyped((state) => state.teamDetailsPage.applicationsPEV);

  const shouldRenderApplicationsProgressMessage = () => applicationsFetchProgressText !== null;

  const shouldRenderApplicationsErrorMessage = () => !!applicationsFetchErrorText;

  const isApplicationListEmpty = () => applications?.length === 0;

  const shouldRenderNoApplicationMessage = () => isApplicationListEmpty();

  const getApplications = () => applications;

  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");

  return ({
    getApplications,
    shouldRenderApplicationsProgressMessage,
    shouldRenderApplicationsErrorMessage,
    projectAssociationToggleEnabled,
    applicationsFetchProgressText,
    applicationsFetchErrorText,
    shouldRenderNoApplicationMessage,
  });
}
