import { ReactNode } from "react";
import { Props } from ".";
import Analytics from "../../../../common/services/analytics";
import { TeamCreationPageActions, TeamType } from "../../../create-team/redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTeamTypeReadableLabel } from "../team-types-label";
import { TeamsPageActions } from "../../redux";
import { useSelectorTyped } from "../../../../common/redux/store";

type TeamTypesInfoController = {
  onTeamTypeInfoSelected: () => void;
  heading: string;
  content: ReactNode;
  id: string;
};

export default function useTeamTypesInfoController(props: Props): TeamTypesInfoController {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAdditionalInfoPEV = useSelectorTyped((state) => state.header.userInfo.additionalInfo);

  const trackSelectedTeamTypeForAnalytics = (teamType: TeamType) => {
    Analytics.trackEventClickOnCreateTeamButton("From My Teams page");
    Analytics.trackEventCreateTeamTypeSelection(getTeamTypeReadableLabel(teamType));
  };

  const isUserAuthorizedToCreateTeam = (teamType: TeamType) => (
    ((teamType === TeamType.GITS || teamType === TeamType.OTHERS) && userAdditionalInfoPEV.value?.isGITSAdmin)
    || teamType === TeamType.EXPERIMENT_AND_INNOVATION
  );

  const onTeamTypeInfoSelected = () => {
    const { teamType } = props;
    if (isUserAuthorizedToCreateTeam(teamType)) {
      trackSelectedTeamTypeForAnalytics(teamType);
      dispatch(TeamCreationPageActions.updateTeamSelection(teamType));
      history.push("/create-team");
      dispatch(TeamsPageActions.updateShowChooseTeamTypeForTeamCreationModal(false));
    }
    else {
      dispatch(TeamCreationPageActions.updateCreateTeamUnAuthorizedPageIsActive(true));
      dispatch(TeamCreationPageActions.updateCreateTeamUnAuthorizedPageTeamType(teamType));
    }
  };

  return {
    onTeamTypeInfoSelected,
    heading: props.heading,
    content: props.content,
    id: props.id,
  };
}
