import { useEffect } from "react";
import { eventsOverviewPageActions, MasterDataCategory } from "./redux";
import { useDispatch } from "react-redux";
import { eventsDiscoveryPageActions } from "../events-discovery/redux";
import { useHistory } from "react-router-dom";
import EventsAnalytics from "../common/services/events-analytics";
import { useSelectorTyped } from "../../../common/redux/store";

export default function useEventsOverviewController() {
  const dispatch = useDispatch();
  const history = useHistory();
  const eventMetricsPEV = useSelectorTyped((state) => state.events.eventsOverviewPage.eventMetricsPEV);
  const categoryMetadataPEV = useSelectorTyped((state) => state.events.eventsDiscoveryPage.categoryMetadata);
  const masterDataCategoryPEV = useSelectorTyped((state) => state.events.eventsOverviewPage.categoriesPEV);
  useEffect(() => {
    EventsAnalytics.trackPageEventsOverview();
    if (eventMetricsPEV.value?.totalEventRegistrations === 0) {
      dispatch(eventsOverviewPageActions.fetchEventMetrics());
    }
    if (!categoryMetadataPEV.value) {
      dispatch(eventsDiscoveryPageActions.fetchCategories());
    }
    if (masterDataCategoryPEV.value.length === 0) {
      dispatch(eventsOverviewPageActions.fetchCategories());
    }
    window.scrollTo(0, 0);
  }, []);

  const getSortedCategories = (): MasterDataCategory[] => {
    const sortedCategories = categoryMetadataPEV.value?.filter((agg) => agg.totalEventRegistrations > 0).sort(function(a, b) {
      const lowerCasedForSortingA = a.name.toLowerCase();
      const lowerCasedForSortingB = b.name.toLowerCase();
      if (lowerCasedForSortingA < lowerCasedForSortingB) {
        return -1;
      }
      if (lowerCasedForSortingA > lowerCasedForSortingB) {
        return 1;
      }
      return 0;
    });
    const final = sortedCategories && sortedCategories.length > 0
      ? [
        ...sortedCategories.slice(0, 10).filter((mdc) => mdc.name !== "Others"),
        ...sortedCategories.filter((mdc) => mdc.name === "Others"),
      ]
      : [];
    return final.map((category) => masterDataCategoryPEV.value?.filter((mdc) => mdc.name === category.name)[0]);
  };

  const handleCategoryClick = (categoryName: string) => {
    EventsAnalytics.trackPageCategoriesClickFromOverview(categoryName);
    dispatch(eventsDiscoveryPageActions.setSelectedCategory(categoryName));
    dispatch(eventsDiscoveryPageActions.setNamespacesActive(false));
    history.push("/events/discovery");
  };

  const handleViewMoreClick = () => {
    dispatch(eventsDiscoveryPageActions.setNamespacesActive(false));
    history.push("/events/discovery");
  };

  return ({
    eventMetricsPEV,
    masterDataCategoryPEV,
    categoryMetadataPEV,
    getSortedCategories,
    handleCategoryClick,
    handleViewMoreClick,
  });
}
