import React from "react";
import "./index.scss";
import { Props, useUserActionsController } from "./controller";
import NeoModalDialog from "../../../../common/components/neo-modal-dialog";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import DropdownMenu from "../../../../common/components/dropdown-menu";
import AlertIcon from "../../../../common/components/neo-modal-dialog/alert-icon";

const UserActions = createFunctionalComponentFromView<ReturnType<typeof useUserActionsController>, Props>(
  useUserActionsController,
  (controller) => {
    const renderRemoveMemberWarningModal = () => (
      <NeoModalDialog
        dialogClassName="remove-member-warning-modal"
        dialogTitle="Remove Members"
        subTitle={controller.getRemoveMemberWarningModalConfirmationQuestion()}
        bodyText={controller.getRemoveMemberWarningModalAdditionalInfo()}
        dontAutoHideOnButtonClick={true}
        primaryButton={{
          show: true,
          label: controller.getRemoveButtonLabelInRemoveMemberWarningModal(),
          onClick: controller.onClickConfirmRemoveMember,
          disabled: controller.shouldDisableButtonsInRemoveMemberWarningModal(),
        }}
        secondaryButton={{
          show: true,
          label: "Cancel",
          disabled: controller.shouldDisableButtonsInRemoveMemberWarningModal(),
          onClick: controller.onHideRemoveMemberModal,
        }}
        show={controller.showRemoveMemberWarningModal}
        onHide={controller.onHideRemoveMemberModal}
      />
    );

    const renderRemoveLastManagerWarningModal = () => (
      <NeoModalDialog
        dialogClassName="last-manager-warning-modal"
        dialogTitle="Remove Members"
        bodyIcon={<AlertIcon />}
        bodyText="Member couldn't be removed. At least one manager should be assigned to the team."
        show={controller.showRemoveLastManagerWarningModal}
        onHide={controller.onHideRemoveLastManagerWarningModal}
        primaryButton={{ show: true, label: "OK" }}
        secondaryButton={{ show: false }}
      />
    );

    return (
      <div className="UserActions">
        <DropdownMenu
          dropdownItems={controller.getUserActions()}
          ariaLabel="Actions"
        >
          ...
        </DropdownMenu>
        {renderRemoveMemberWarningModal()}
        {renderRemoveLastManagerWarningModal()}
      </div>
    );
  },
);

export default UserActions;
