import React, { FunctionComponent } from "react";
import useAddTeamMembersController from "./controller";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import FormComponent, { INavigationButtonsProps } from "../../../common/components/forms";
import { NavigationButtons } from "../../../common/components/forms/navigation-buttons";
import "./index.scss";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import NonGITSMembersAlert from "../../../common/components/non-gits-members-alert";
import NotPartOfGITSLegend from "../../../common/components/not-part-of-gits-legend";

// TODO change folder name to add-team-members-form/dialog
const AddTeamMembersForm: FunctionComponent = () => {
  const controller = useAddTeamMembersController();

  function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        nextButtonLabel={controller.getAddButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onAddButtonClick}
        onClickPrevious={controller.onCancel}
        isNextButtonDisabled={controller.isAddMembersInProgress}
        isPreviousButtonDisabled={controller.isAddMembersInProgress}
      />
    );
  }

  function renderNotPartOfGITSLegend() {
    if (controller.shouldDifferentiateNonGITSMembers) {
      return <NotPartOfGITSLegend />;
    }
  }

  function renderNonGITSMembersAlert() {
    if (controller.shouldRenderNonGITSMembersAlert()) {
      return (
        <NonGITSMembersAlert
          selectedMembers={controller.selectedNonGITSMembers}
          message={controller.NonGITSMembersAlertMessage}
        />
      );
    }
  }

  function renderAdditionalFormContent() {
    return (
      <>
        {renderNotPartOfGITSLegend()}
        {renderNonGITSMembersAlert()}
      </>
    );
  }

  const renderForm = () => (
    <FormComponent
      questions={controller.questions}
      data={controller.getFormData()}
      renderNavigationButtons={renderNavigationButtons}
      handleValueChanged={controller.handleFormValueChangedEvent}
      renderAdditionalContent={renderAdditionalFormContent}
    />
  );

  function renderModalContent() {
    return (
      <div className="AddMembers">
        {controller.shouldRenderProgress() && <Progress text={controller.progressText} />}
        {controller.shouldRenderError()
        && (
          <Error

            {...mapApplicationErrorInfoToErrorComponentProps(controller.errorText!)}
          />
        )}
        {controller.shouldRenderForm() && renderForm()}
      </div>
    );
  }

  const renderAddMembersModal = () => (
    <NeoModalDialog
      dialogTitle="Add Members"
      dialogClassName="add-members-modal"
      show={controller.addMembersModalShow}
      onHide={controller.onCancel}
    >
      {renderModalContent()}
    </NeoModalDialog>
  );

  return renderAddMembersModal();
};

export default AddTeamMembersForm;
