import { IPotentialMember } from "../../pages/teams/redux";
import { HighlightValueAsNonGITS } from "../../common/components/highlight-value-as-non-gits";
import { iif } from "../functions";
import castTo from "../cast-to";
import { ReactElement } from "react";
import { Choice } from "../../common/components/custom-form-controls/paginated-dropdown-control";

// TODO Can this function be refactored out of utils
export const getDropdownChoicesForMembers = (
  potentialMembers: IPotentialMember[],
  shouldDifferentiateNonGITSMembers?: boolean,
): Choice[] => potentialMembers.map((member) => {
  const memberFullNameWithEmailId = `${member.firstName} ${member.lastName} - ${member.emailId}`;
  return ({
    value: member.employeeId,
    text: memberFullNameWithEmailId,
    component: castTo<ReactElement>(HighlightValueAsNonGITS({
      value: memberFullNameWithEmailId,
      shouldDifferentiateNonGITSMembers: iif(function shouldHighlightNonGITSMember() {
        return !!(
          shouldDifferentiateNonGITSMembers
          && !(member.isGITSMember || member.isOthersMember)
        );
      }),
    })),
  });
});

export const getUserEmployeeIdFromUserEmail = (emailId: string, potentialMembers: IPotentialMember[]): string => {
  const user = potentialMembers.find((potentialMember) => potentialMember.emailId === emailId);
  return user ? user.employeeId : "";
};
