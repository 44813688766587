import React from "react";
import useMultiSelectFilterController, { FilterDropdownProps } from "./controller";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import { Select } from "antd";

const FilterDropDown = createFunctionalComponentFromView<ReturnType<typeof useMultiSelectFilterController>, FilterDropdownProps>(
  useMultiSelectFilterController,
  (controller) => (
    <div className={`FilterDropDown ${controller.className || ""}`}>
      <div className="title">
        {controller.title}
      </div>
      <Select
        mode="multiple"
        className="multi-select"
        style={{ minWidth: controller.width }}
        allowClear
        placeholder="Select"
        optionFilterProp="children"
        value={controller.getSelectedData()}
        onChange={controller.onChange}
        aria-labelledby={controller.title}
        aria-label={controller.title}
        clearIcon={() => (
          <i className="ri-close-fill clear-all" />
        )}
        removeIcon={() => {
          <i className="ri-close-fill" />;
        }}
      >
        {controller.options.map((option, index) => (
          <Select.Option
            key={index}
            value={option.value}
            aria-label={option.label}
            className="multi-select-option"
            disabled={option.disabled}
          >
            {option.label}
          </Select.Option>
        ),
        )}
      </Select>
    </div>
  ),
);

export default FilterDropDown;
